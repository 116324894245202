import { shallowPopulate } from 'feathers-shallow-populate';

import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client';

class Variant extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Variant';
  // Define default properties here
  static instanceDefaults() {
    return {
      Number: 0,
      Name: null,
      Input: null,
      Output: null,
    };
  }
  // static setupInstance(data, { models }) {
  //   if (data.ApplicationId) {
  //     data.Application = new models.api.Application(data.ApplicationId);
  //   }

  //   // if (data.posts) {
  //   //   // Turn posts into an array of Post instances
  //   //   data.posts = data.posts.map(post => new models.api.Post(post))
  //   // }

  //   console.log(data);

  //   if (data.ProcessStep) {
  //     console.log('koko');
  //     data.ProcessStep = data.ProcessSteps.map(
  //       (t) => new models.api.ProcessStep(t)
  //     );
  //   }
  //   return data;
  // }
}
const servicePath = 'variant';
const servicePlugin = makeServicePlugin({
  Model: Variant,
  service: feathersClient.service(servicePath),
  servicePath,
});

const options = {
  include: [
    {
      service: 'application',
      nameAs: 'application',
      keyHere: 'ApplicationId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
  ],
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [shallowPopulate(options)],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
