var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"my-5",attrs:{"flat":""}},[(!_vm.loading)?_c('div',[_c('v-row',{attrs:{"align":"stretch","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"#FFCDD2"}},[_c('b',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(this.user.vendor.ResponsePhase == 1 ? _vm.counterOpen : _vm.counterOpen2))]),_vm._v(" Open - "+_vm._s(this.user.vendor.ResponsePhase == 1 ? ((_vm.counterOpen / _vm.counterAll) * 100).toFixed(0) : ((_vm.counterOpen2 / _vm.counterAll2) * 100).toFixed(0))+"% ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"#FFFCBD"}},[_c('b',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(this.user.vendor.ResponsePhase == 1 ? _vm.counterProgress : _vm.counterProgress2))]),_vm._v(" In Progress - "+_vm._s(this.user.vendor.ResponsePhase == 1 ? ((_vm.counterProgress / _vm.counterAll) * 100).toFixed(0) : ((_vm.counterProgress2 / _vm.counterAll2) * 100).toFixed(0))+"% ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"#C7FFBF"}},[_c('b',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(this.user.vendor.ResponsePhase == 1 ? _vm.counterDone : _vm.counterDone2))]),_vm._v(" Done - "+_vm._s(this.user.vendor.ResponsePhase == 1 ? ((_vm.counterDone / _vm.counterAll) * 100).toFixed(0) : ((_vm.counterDone2 / _vm.counterAll2) * 100).toFixed(0))+"% ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"size":95,"width":12,"value":this.user.vendor.ResponsePhase == 1
                ? (
                    ((_vm.counterAll - _vm.counterOpen - _vm.counterProgress) /
                      _vm.counterAll) *
                    100
                  ).toFixed(2)
                : (
                    ((_vm.counterAll2 - _vm.counterOpen2 - _vm.counterProgress2) /
                      _vm.counterAll2) *
                    100
                  ).toFixed(2),"color":"black"}},[_vm._v(" "+_vm._s(this.user.vendor.ResponsePhase == 1 ? ( ((_vm.counterAll - _vm.counterOpen - _vm.counterProgress) / _vm.counterAll) * 100 ).toFixed(2) : ( ((_vm.counterAll2 - _vm.counterOpen2 - _vm.counterProgress2) / _vm.counterAll2) * 100 ).toFixed(2))+"% ")])],1)],1)],1):_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":1}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }