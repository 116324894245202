<template>
  <div>
    <v-tooltip v-if="Image" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          :class="
            applyDark(Color ? Color : '#000000')
              ? 'white--text caption mr-3'
              : 'black--text caption mr-3'
          "
          text--color="white"
          :color="Color ? Color : '#000000'"
          size="25"
        >
          <img :src="Image" :alt="Name" /> </v-avatar></template
      >{{ Name }}</v-tooltip
    >
    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          :class="
            applyDark(Color ? Color : '#000000')
              ? 'white--text caption mr-3'
              : 'black--text caption mr-3'
          "
          text--color="white"
          :color="Color ? Color : '#000000'"
          size="25"
        >
          <span v-bind="attrs" v-on="on">{{
            Name.match(/(\b\S)?/g)
              .join('')
              .match(/(^\S|\S$)?/g)
              .join('')
              .toUpperCase()
          }}</span>
        </v-avatar></template
      >{{ Name }}</v-tooltip
    >
  </div>
</template>

<script>
import { applyDark } from '@/utils/Utilities';
export default {
  props: {
    Name: {
      type: String,
      required: true,
    },
    Color: {
      type: String,
      required: false,
    },
    Image: {
      type: String,
      required: false,
    },
  },
  methods: { applyDark },
};
</script>

<style lang="scss" scoped></style>
