<template>
  <div class="mx-5">
    <h3>Progress</h3>
    <ExportProgress />
    <ProgressCards />
    <ProgressGraph />
  </div>
</template>

<script>
import ExportProgress from '@/components/ExportData/ExportProgress.vue';
import ProgressGraph from '@/components/Dashboard/Progress/ProgressGraph.vue';
import ProgressCards from './ProgressCards.vue';

export default {
  components: { ExportProgress, ProgressGraph, ProgressCards },
};
</script>

<style lang="scss" scoped></style>
