<template>
  <div v-if="CurrentVariant && toggle">
    <v-card class="ma-2 pa-2" autoheight>
      <b>{{ CurrentVariant.Name }} ({{ counter }}/{{ variants.length }})</b>
      <v-row class="subtitle-1">
        <v-col cols="1"></v-col>
        <v-col cols="4">Imported Text</v-col
        ><v-col cols="7">Link to Existing Process Step</v-col></v-row
      >
      <v-row class="mt-2" v-for="(step, index) in Steps" :key="step.ExternalId">
        <v-col cols="1"
          ><v-icon v-if="!step.Existing" @click="removeStep(index)"
            >mdi-trash-can</v-icon
          ></v-col
        >
        <v-col cols="4" v-bind:class="{ 'warning--text': step.Existing }"
          >{{ step.Number }}. {{ step.Name }} </v-col
        ><v-col cols="7">
          <v-autocomplete
            v-if="!step.Existing"
            :disabled="step.linkExisting"
            v-model="step.linkExistingStep"
            :items="
              fullProcessStepList.filter(
                (f) => f.VariantId == CurrentVariant.id
              )
            "
            item-text="Name"
            return-object
            dense
            hide-details
            outlined
            clearable
            label="Select Process Step"
            @change="
              linkStep(step);
              toggle = !toggle;
              toggle = !toggle;
            "
          >
            <template v-slot:append>
              <v-icon
                v-if="step.linkExisting && step.linkExistingStep"
                color="green"
                >mdi-check-bold</v-icon
              >
            </template>
            <template v-if="!step.Existing" v-slot:append-outer>
              <v-icon
                v-if="!step.linkExisting && step.linkExistingStep"
                color="green"
                :disabled="!step.linkExistingStep"
                @click="linkStep(step)"
                >mdi-link</v-icon
              >
              <v-icon
                v-if="step.linkExisting"
                color="red"
                :disabled="!step.linkExistingStep"
                @click="linkStep(step)"
                >mdi-link-off</v-icon
              >
              <v-icon v-if="!step.linkExistingStep || !step.linkExisting"
                >mdi-new-box</v-icon
              >
            </template>
          </v-autocomplete>
          <div v-if="step.Existing">
            <v-icon small color="warning" class="mr-3"
              >mdi-arrow-decision</v-icon
            ><span>Step exists</span>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-btn color="primary" @click="clickSaveSteps()">
      {{ this.ActiveVariants.length > 0 ? 'Save >>' : 'Finish' }}
    </v-btn>
    <v-btn text outlined @click="clickCancelSteps()"> Skip </v-btn>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  props: ['documentParsed', 'variants', 'process'],
  mixins: [
    makeFindMixin({
      service: 'process-step',
      name: 'fullProcessStepList',
      watch: ['toggle', 'this.toggle'],
    }),
  ],
  data() {
    return {
      ActiveVariants: null,
      CurrentVariant: null,
      Steps: [],
      counter: 0,
      toggle: true,
    };
  },
  computed: {
    fullProcessStepListParams() {
      return {
        query: { VariantId: this.CurrentVariant ? this.CurrentVariant.id : 0 },
        // query: {},
      };
    },
  },
  methods: {
    async clickCancelSteps() {
      if (this.ActiveVariants.length > 0) {
        this.CurrentVariant = this.ActiveVariants.shift();
        await this.GetSteps();
      } else {
        // exit and next step
        this.next();
      }
    },
    removeStep(index) {
      this.Steps.splice(index, 1);
    },
    linkStep(step) {
      this.Steps = this.Steps.map((s) =>
        s.ExternalId === step.ExternalId
          ? {
              ...s,
              linkExisting: !s.linkExisting,
              linkExistingStep: step.linkExisting ? null : s.linkExistingStep,
            }
          : s
      );
      // this.Steps.find((s, index) => {
      //   if (this.stepToLink[index]) {
      //     let ExternalId = this.Steps[index].ExternalId;
      //     this.Steps[index] = this.fullProcessStepList.find(
      //       (f) => f.id == this.stepToLink[index]
      //     );
      //     this.Steps[index].ExternalId = ExternalId;
      //   }
      // });

      // trick to refresh dialogs
      this.toggle = false;
      this.toggle = true;
    },
    async clickSaveSteps() {
      let saved = null;
      await Promise.all(
        this.Steps.map(async (p) => {
          if (!p.Existing) {
            if (p.linkExisting) {
              // patch
              try {
                saved = await feathersClient
                  .service('process-step')
                  .patch(p.linkExistingStep.id, {
                    ExternalId: p.ExternalId,
                  });
              } catch (error) {
                handleErrorResponse(error);
              }
              handleSaveResponse(saved.Name, 'Process Step', 'updated');
            } else {
              //create
              try {
                saved = await p.save();
              } catch (error) {
                handleErrorResponse(error);
              }
              handleSaveResponse(saved.Name, 'Process Step', 'added');
            }
          }
          p.id = saved.id;
          return p;
        })
      );
      if (this.ActiveVariants.length > 0) {
        this.CurrentVariant = this.ActiveVariants.shift();
        await this.GetSteps();
      } else {
        // exit and next step
        this.next();
      }
    },
    next() {
      this.$emit('next', { type: 'step', data: this.Steps });
    },
    async GetSteps() {
      let indexes = this.CurrentVariant.PageIndexes.split(',');
      let pages = this.documentParsed.filter(
        (f) => f.index == parseInt(indexes[0]) - 1 //TODO must work for multiple page
      );
      this.Steps = [];
      this.counter++;
      pages.forEach(async (v) => {
        const { ProcessStep } = this.$FeathersVuex.api;
        await v.blocks
          .filter((p) => p.type == 'ProcessStep' || p.type == 'ProcessManual')
          .forEach(async (step) => {
            let newStep = new ProcessStep();
            newStep.VariantId = this.CurrentVariant.id;
            newStep.VariantName = this.CurrentVariant.Name;
            newStep.Name = step.text;
            newStep.Number = 0;
            newStep.ExternalId = step.id;
            newStep.StatusId = 1;
            newStep.PriorityId = 1;
            newStep.Existing = false;
            let stepList = await ProcessStep.find({
              query: { VariantId: this.CurrentVariant.id },
            });
            newStep.linkExistingStep = stepList.data.find(
              (f) =>
                f.Name.toLowerCase().trim() == step.text.toLowerCase().trim()
            );

            if (newStep.linkExistingStep) {
              newStep.linkExisting = true;
            } else {
              newStep.linkExisting = false;
              newStep.linkExistingStep = null;
            }

            // Match steps on external id and insert existing step into variant object as subnode
            let stepsearch = await ProcessStep.find({
              query: {
                ExternalId: step.id,
                VariantId: this.CurrentVariant.id,
              },
            });

            if (stepsearch.total > 0) {
              newStep = stepsearch.data[0];
              newStep.Existing = true;
            }

            this.Steps.push(newStep);
            this.Steps = this.Steps.sort(
              (a, b) =>
                a.linkExisting - b.linkExisting ||
                a.Number - b.Number ||
                a.Name.localeCompare(b.Name)
            );
          });
      });
    },
  },

  async mounted() {
    this.ActiveVariants = [...this.variants];
    this.CurrentVariant = this.ActiveVariants.sort(
      (a, b) => a.Number - b.Number
    ).shift();
    await this.GetSteps();
  },
};
</script>

<style lang="scss" scoped></style>
