<template>
  <div>
    <v-progress-linear
      class="mt-5"
      v-if="isFindRequirementTypePending"
      color="success"
      height="1"
      indeterminate
    ></v-progress-linear>
    <v-list-item
      v-for="item in requirementType"
      :key="item.id"
      @click="$emit('clicked-reqs', item.id)"
    >
      <v-list-item-icon>
        <v-icon class="mr-2">
          {{ item.Icon }}
        </v-icon>
      </v-list-item-icon>

      <span>{{ item.Type }}</span> <v-spacer></v-spacer>
      <StatusListIndividual
        v-if="reqStatusSummary(item.id) && reqStatusSummary(item.id).length > 0"
        :statusList="filteredStatusList(item)"
        :status="status"
      />
    </v-list-item>
  </div>
</template>
<script>
import { makeFindMixin } from 'feathers-vuex';
import StatusListIndividual from '@/components/StatusListIndividual';

export default {
  props: ['variant', 'statusList'],
  mixins: [
    makeFindMixin({ service: 'requirement' }),
    makeFindMixin({ service: 'requirement-type' }),
    makeFindMixin({ service: 'status' }),
  ],
  components: { StatusListIndividual },
  methods: {
    filteredStatusList(item) {
      if (this.statusList) {
        return this.statusList.filter((s) => (s.Type = item.Code));
      } else return [];
    },
    reqStatusSummary(type) {
      var filtered = this.requirement.filter((item) => {
        return item.RequirementTypeId == type && !item.Obsolete;
      });
      var result = filtered.reduce(
        (acc, o) => ((acc[o.StatusId] = (acc[o.StatusId] || 0) + 1), acc),
        {}
      );
      return result;
    },
    // reqStatusSummary(type) {
    //   var filtered = this.requirement.filter((item) => {
    //     return item.RequirementTypeId == type && !item.Obsolete;
    //   });

    //   if (filtered) {
    //     let statusList = [];

    //     // Get sum of all statusses for the steps
    //     const stepsStatus = Array.from(
    //       filtered.reduce(
    //         (m, { StatusId, count }) =>
    //           m.set(StatusId, (m.get(StatusId) || 0) + 1),
    //         new Map()
    //       ),
    //       ([StatusId, count]) => ({ StatusId, count })
    //     );
    //     if (Object.keys(stepsStatus).length > 0) statusList.push(stepsStatus);

    //     // Make the sum per status
    //     const res = Array.from(
    //       statusList
    //         .flat()
    //         .reduce(
    //           (m, { StatusId, count }) =>
    //             m.set(StatusId, (m.get(StatusId) || 0) + count),
    //           new Map()
    //         ),
    //       ([StatusId, count]) => ({ StatusId, count })
    //     );
    //     // console.log('b', res);

    //     return res;
    //   } else return [];
    // },
  },
  computed: {
    requirementParams() {
      return { query: { VariantId: this.variant.id } };
    },
    requirementTypeParams() {
      return {};
    },
    statusParams() {
      return {};
    },
  },
};
</script>
