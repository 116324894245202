<template>
  <div>
    <v-form
      v-if="
        vendor[0] &&
        ((user.vendor.ResponsePhase == 1 && !vendor[0].SendInDate) ||
          (user.vendor.ResponsePhase == 2 && !vendor[0].SendInDate2))
      "
      ref="form"
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">Send Response</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="editItem.SendInComment"
                label="Comment"
                outlined
                dense
                rows="1"
                auto-grow
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <span
            v-if="counterOpen != 0 && user.vendor.ResponsePhase == 1"
            style="background-color: #ffcdd2"
            >Still {{ counterOpen }} responses required.</span
          >
          <span
            v-if="counterOpen2 != 0 && user.vendor.ResponsePhase == 2"
            style="background-color: #ffcdd2"
            >Still {{ counterOpen2 }} responses required.</span
          >
          <v-spacer></v-spacer>

          <v-btn :disabled="counterOpen != 0" color="primary" @click="save()">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      dense
      text
      type="success"
      class="mt-5"
      v-if="
        vendor[0] &&
        ((user.vendor.ResponsePhase == 1 && vendor[0].SendInDate) ||
          (user.vendor.ResponsePhase == 2 && vendor[0].SendInDate2))
      "
    >
      Response send successfully on
      {{
        vendor[0] && vendor[0].SendInDate && vendor[0].ResponsePhase == 1
          ? new Date(vendor[0].SendInDate).toLocaleString([], {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          : vendor[0] && vendor[0].SendInDate2 && vendor[0].ResponsePhase == 2
          ? new Date(vendor[0].SendInDate2).toLocaleString([], {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          : null
      }}
    </v-alert>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  mixins: [
    makeFindMixin({ service: 'variant' }),
    makeFindMixin({ service: 'specification' }),
    makeFindMixin({ service: 'process-step' }),
    makeFindMixin({ service: 'response' }),
    makeFindMixin({ service: 'vendor' }),
  ],
  data() {
    return {
      editItem: {
        SendInComment: null,
      },
    };
  },
  methods: {
    save() {
      let ResponsetoSend = {};
      if (this.user.vendor.ResponsePhase == 1) {
        ResponsetoSend = {
          SendInComment: this.editItem.SendInComment,
          SendInDate: new Date(),
        };
      } else if (this.user.vendor.ResponsePhase == 2) {
        ResponsetoSend = {
          SendInComment2: this.editItem.SendInComment,
          SendInDate2: new Date(),
        };
      }

      feathersClient
        .service('vendor')
        .patch(this.user.vendor.id, ResponsetoSend)
        .then(async () => {
          handleSaveResponse('', 'response', 'send succesfully');
          await feathersClient.service('vendor').find();
        })
        .catch(async (error) => {
          handleErrorResponse(error);
        });
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    variantParams() {
      return {};
    },
    specificationParams() {
      return { query: { Obsolete: false } };
    },
    processStepParams() {
      return { query: { Obsolete: false } };
    },
    vendorParams() {
      return { query: { id: this.user.vendor ? this.user.vendor.id : null } };
    },
    responseParams() {
      return {
        query: {
          VendorId: this.user.vendor ? this.user.vendor.id : null,
          Final: true,
        },
      };
    },
    counterOpen() {
      if (this.variant && this.specification && this.response) {
        let counterVariants = this.variant.filter(
          (v) => !this.response.some((r) => r.VariantId == v.id)
        );
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );
        let counterSpecifications = filteredSpecs.filter(
          (s) => !this.response.some((r) => r.SpecificationId == s.id)
        );
        return counterSpecifications.length + counterVariants.length;
      }
      return 9999999;
    },
    counterOpen2() {
      if (
        this.variant &&
        this.specification &&
        this.processStep &&
        this.response
      ) {
        let steps = [];
        this.variant.forEach((f) => {
          let filteredSteps = this.processStep.filter(
            (s) => !(s.status.Progress == 0 && s.status.Closed == true)
          );

          steps.push(
            filteredSteps.filter(
              (p) =>
                p.VariantId == f.id &&
                !this.response.some((r) => r.ProcessStepId == p.id)
            )
          );
        });

        let counterSteps = steps.flat(1).length;
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );
        let counterSpecifications = filteredSpecs.filter(
          (s) => !this.response.some((r) => r.SpecificationId == s.id)
        );

        return counterSteps + counterSpecifications.length;
      }
      return 9999999;
    },
  },
};
</script>

<style lang="scss" scoped></style>
