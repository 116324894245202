import axios from 'axios';
import vue from 'vue';
import init, { convert } from 'htmltoadf';
import { config } from '@/config';
import { getSeverity, getType } from './FieldMapper';
import { getHeader, useRefreshToken } from './JiraAuthentication';
import feathersClient from '@/feathers-client';
import { IssueFieldMapper } from './Globals';
import { createNestedObject } from '@/utils/Utilities';

async function CreateWorkItem(external_issue, process, type) {
  // type is post,patch
  let updateObject = {};

  //Convert HTML content to ADF
  await init();
  if (external_issue.Content1) {
    external_issue.Content1 = JSON.parse(convert(external_issue.Content1));
  }

  // await init();
  if (external_issue.Content2) {
    external_issue.Content2 = JSON.parse(convert(external_issue.Content2));
  }

  const queryCreate = {
    query: {
      IssueTypeId: external_issue.IssueTypeId,
      ExternalField: { $ne: null },
      Create: { $in: ['Both', 'Loxi'] },
    },
  };

  const queryUpdate = {
    query: {
      IssueTypeId: external_issue.IssueTypeId,
      ExternalField: { $ne: null },
      Update: { $in: ['Both', 'Loxi'] },
    },
  };

  const issueFields = await feathersClient
    .service('issue-type-fields')
    .find(type == 'post' ? queryCreate : queryUpdate);

  if (issueFields.total > 0) {
    for (const field of issueFields.data) {
      var keys = IssueFieldMapper[field.Field].split('.');

      var result = external_issue;
      for (var i = 0; i < keys.length; i++) {
        if (result == null) break;
        result = result[keys[i]];
      }

      // if no value use the default
      result = !result
        ? field.DefaultValue?.length > 0
          ? field.DefaultValue
          : result
        : result;

      //Mappers type, status, severity,
      if (field.Field == 'Severity') {
        result = await getSeverity(external_issue.IssueTypeId, result);
      }
      if (field.Field == 'Type') {
        result = await getType(external_issue.IssueTypeId, result);
      }

      // Get extra fields Process, Department
      if (process && process.department && field.Field == 'Department') {
        result = process.department.ERPModule.toString();
      }
      if (field.Field == 'Process') {
        if (process && process.IssueTrackingId)
          result = process.IssueTrackingId.toString();
        else {
          result = null;
        }
      }
      // Create UpdateObject
      if (result != null) updateObject[field.ExternalField] = result;
    }

    // console.log(createNestedObject(updateObject));
    return await JiraREST(createNestedObject(updateObject), type);
  }
}

// async function UpdateWorkItem(external_issue, process) {
//   let updateObject = {};

//   //Convert HTML content to ADF
//   await init();
//   if (external_issue.Content1) {
//     external_issue.Content1 = JSON.parse(convert(external_issue.Content1));
//   }

//   await init();
//   if (external_issue.Content2) {
//     external_issue.Content2 = JSON.parse(convert(external_issue.Content2));
//   }

//   const issueFields = await feathersClient.service('issue-type-fields').find({
//     query: {
//       IssueTypeId: external_issue.IssueTypeId,
//       ExternalField: { $ne: null },
//       Update: { $in: ['Both', 'Loxi'] },
//     },
//   });

//   if (issueFields.total > 0) {
//     for (const field of issueFields.data) {
//       var keys = IssueFieldMapper[field.Field].split('.');

//       var result = external_issue;
//       for (var i = 0; i < keys.length; i++) {
//         if (result == null) break;
//         result = result[keys[i]];
//       }

//       // if no value use the default
//       result = !result ? field.DefaultValue : result;

//       //Mappers type, status, severity,
//       if (field.Field == 'Severity') {
//         result = await getSeverity(external_issue.IssueTypeId, result);
//       }
//       if (field.Field == 'Type') {
//         result = await getType(external_issue.IssueTypeId, result);
//       }

//       // Get extra fields Process, Department
//       if (process && process.department && field.Field == 'Department') {
//         result = process.department.ERPModule.toString();
//       }
//       if (process && process.IssueTrackingId && field.Field == 'Process') {
//         result = process.IssueTrackingId.toString();
//       }
//       // Create UpdateObject
//       if (result) updateObject[field.ExternalField] = result;
//     }
//     console.log(createNestedObject(updateObject));
//     return await JiraREST(createNestedObject(updateObject), 'patch');
//   }
// }

async function JiraREST(body, type) {
  let url = `https://api.atlassian.com/ex/jira/${config.Jira.cloudId}/rest/api/3/issue`;
  let result = null;
  let response = null;
  try {
    switch (type) {
      case 'post':
        result = await axios.post(url, body, await getHeader());
        break;
      case 'patch':
        result = await axios.put(`${url}/${body.key}`, body, await getHeader());
        break;
      default:
        break;
    }

    if (200 <= result?.status <= 299) {
      const link = `https://${config.Jira.organisation}.atlassian.net/browse/${result.data.key}`;
      response = {
        id: result.data.key,
        link: link,
      };
    } else vue.toasted.global.error('Error creating Task');
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      console.log('Login failed?');
      //userefresh and retry
      await useRefreshToken();
      try {
        switch (type) {
          case 'post':
            result = await axios.post(url, body, await getHeader());
            break;
          case 'patch':
            result = await axios.put(
              `${url}/${body.key}`,
              body,
              await getHeader()
            );
            break;
          default:
            break;
        }
        if (200 <= result?.status <= 299) {
          const link = `https://${config.Jira.organisation}.atlassian.net/browse/${result.data.key}`;
          response = {
            id: result.data.key,
            link: link,
          };
        } else {
          console.log('redirect');
          // window.location.replace('https://www.foocus.be');
          response = { error: JSON.stringify(error.response.data.errors) };
        }
      } catch (error) {
        console.log('redirect');
        // window.location.replace('https://www.foocus.be');
        response = { error: JSON.stringify(error.response.data.errors) };
      }
    } else response = { error: JSON.stringify(error.response.data.errors) };
  }
  return response;
}

export { CreateWorkItem };
