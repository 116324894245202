import axios from 'axios';
import feathersClient from '@/feathers-client';
import vue from 'vue';
import { config } from '@/config';
import { IssueFieldMapper } from './Globals';
import { getSeverity, getType, getStatus } from './FieldMapper';

async function getToken() {
  let appInfo = null;
  try {
    appInfo = await feathersClient
      .service('tokens')
      .find({ query: { Application: 'DevOps' } });
  } catch (error) {
    console.log('Error getting appInfo');
  }

  return appInfo;
}

async function getUserToken() {
  let appInfo = await getToken();
  if (appInfo && appInfo.total == 1) {
    return appInfo.data[0];
  }
}

async function getHeader(type = 1) {
  let appInfo = await getUserToken();
  let auth = btoa(':' + appInfo.AccessToken); // btoa(appInfo.AccessToken.toString('base64');
  let config = {
    headers: {
      Authorization: `Basic ${auth}`,
      Accept: 'Application/json; api-version=7.1',
      'Content-Type':
        type == 1 ? 'application/json-patch+json' : 'Application/json',
    },
  };
  return config;
}

async function CreateWorkItem(external_issue, process, type) {
  // type is post,patch
  let updateObject = [];
  let issueType = null;
  issueType = await getType(external_issue.IssueTypeId, result);

  const queryCreate = {
    query: {
      IssueTypeId: external_issue.IssueTypeId,
      ExternalField: { $ne: null },
      Create: { $in: ['Both', 'Loxi'] },
    },
  };

  const queryUpdate = {
    query: {
      IssueTypeId: external_issue.IssueTypeId,
      ExternalField: { $ne: null },
      Update: { $in: ['Both', 'Loxi'] },
    },
  };

  const issueFields = await feathersClient
    .service('issue-type-fields')
    .find(type == 'post' ? queryCreate : queryUpdate);

  if (issueFields.total > 0) {
    for (const field of issueFields.data) {
      var keys = IssueFieldMapper[field.Field].split('.');

      var result = external_issue;
      for (var i = 0; i < keys.length; i++) {
        if (result == null) break;
        result = result[keys[i]];
      }

      // if no value use the default
      result = !result
        ? field.DefaultValue?.length > 0
          ? field.DefaultValue
          : result
        : result;

      //Mappers type, status, severity,
      if (field.Field == 'Severity') {
        result = await getSeverity(external_issue.IssueTypeId, result);
      }
      if (field.Field == 'Status') {
        result = await getStatus(
          external_issue.IssueTypeId,
          external_issue.StatusId
        );
      }
      if (field.Field == 'Type') {
        //dot not add to result but set as separate variable
        issueType = await getType(external_issue.IssueTypeId, result);
        result = null;
      }

      // Get extra fields Process, Department
      if (process && process.department && field.Field == 'Department') {
        result = process.department.ERPModule.toString();
      }

      // if (field.Field == 'Assignee' && external_issue.Assigned) {
      //   console.log('user', result);
      //   let user = await feathersClient.service('users').find({
      //     query: { ExternalId: result},
      //   });
      //   if (user.total > 0) {
      //     result = user.data[0].email;
      //   } else result = null;
      // }
      // if (field.Field == 'CreatedUser' && external_issue.CreatedBy) {
      //   console.log('user', result);
      //   let user = await feathersClient.service('users').find({
      //     query: { ExternalId: external_issue.CreatedBy.email },
      //   });
      //   if (user.total > 0) {
      //     result = user.data[0].email;
      //   } else result = null;
      // }
      // if (field.Field == 'ModifiedUser' && external_issue.ModifiedBy) {
      //   console.log('user', result);
      //   let user = await feathersClient.service('users').find({
      //     query: { ExternalId: external_issue.ModifiedBy.email },
      //   });
      //   if (user.total > 0) {
      //     result = user.data[0].email;
      //   } else result = null;
      // }

      let parentIssueList = [];
      if (field.Field == 'Process') {
        field.ExternalField = field.ExternalField + '/-';
        if (process && process.IssueTrackingId) {
          let issues = process.IssueTrackingId.toString().split(',');
          for (let issue of issues) {
            issue = issue.trim();
            //Check if issue exists
            let parentIssue = await GetWorkItem(issue);
            if (parentIssue) {
              let value = {
                rel: 'System.LinkTypes.Related',
                url:
                  'https://dev.azure.com/foocus/_apis/wit/workItems/' + issue,
              };
              parentIssueList.push(value);
            }
          }
          // Special way to add multiple relatons set result to null to disble normal behaviour
          result = null;
        } else result = null;
      }
      // Create UpdateObject
      if (result != null) {
        let operation = {
          op: 'add',
          path: field.ExternalField,
          value: result,
        };
        updateObject.push(operation);
      } else if (parentIssueList?.length > 0) {
        for (const issue of parentIssueList) {
          let operation = {
            op: 'add',
            path: field.ExternalField,
            value: issue,
          };
          updateObject.push(operation);
        }
      }
    }

    console.log(updateObject);
    return await DevOpsRest(
      updateObject,
      type,
      issueType,
      external_issue.ExternalId
    );
  }
}

async function DevOpsRest(body, type, issueType, ExternalId) {
  console.log(body);
  let url = `https://dev.azure.com/${config.devOps.organisation}/${config.devOps.project}/_apis/wit/workitems/`;
  let result = null;
  let response = null;
  try {
    switch (type) {
      case 'post':
        result = await axios.post(
          `${url}$${issueType}`,
          body,
          await getHeader()
        );
        break;
      case 'patch':
        result = await axios.patch(
          `${url}/${ExternalId}`,
          body,
          await getHeader()
        );
        break;
      default:
        break;
    }

    if (200 <= result?.status <= 299) {
      response = {
        id: result.data.id,
        link: result.data._links.html.href,
      };
    } else vue.toasted.global.error('Error creating Task');
  } catch (error) {
    console.log(error);
    response = { error: JSON.stringify(error.response.data.errors) };
  }
  return response;
}

// async function CreateWorkItem2(
//   title,
//   type,
//   severity,
//   content1,
//   content2,
//   process,
//   status
// ) {
//   let url = `https://dev.azure.com/${config.devOps.organisation}/${config.devOps.project}/_apis/wit/workitems/$${type}?api-version=7.1-preview.3`;
//   let body = [
//     {
//       op: 'add',
//       path: '/fields/System.Title',
//       from: null,
//       value: title,
//     },
//     {
//       op: 'add',
//       path:
//         type == 'Bug'
//           ? '/fields/Microsoft.VSTS.TCM.ReproSteps'
//           : '/fields/System.Description',
//       from: null,
//       value: content1 ? content1 : '',
//     },
//     {
//       op: 'add',
//       path: '/fields/Microsoft.VSTS.TCM.SystemInfo',
//       from: null,
//       value: content2 ? content2 : '',
//     },
//   ];

//   // Blocking
//   if (severity) {
//     body.push({
//       op: 'add',
//       path: '/fields/Microsoft.VSTS.Common.Severity',
//       from: null,
//       value: severity,
//     });
//   }

//   // Parent Issue
//   if (process) {
//     let processNumber = `${
//       config.customer.prefix
//     }${process.Number.toString().padStart(4, '0')}`;
//     let parent = await calculatedParent(processNumber, 'DevOps');
//     if (parent.parents.length > 0) {
//       body.push({
//         op: 'add',
//         path: '/fields/System.AssignedTo',
//         value: parent.assigned,
//       });
//       parent.parents.forEach((p) => {
//         body.push({
//           op: 'add',
//           path: '/relations/-',
//           value: {
//             rel: 'System.LinkTypes.Related',
//             url: 'https://dev.azure.com/foocus/_apis/wit/workItems/' + p,
//             attributes: {
//               comment: '',
//             },
//           },
//         });
//       });
//     }
//   }
//   // Department
//   let DepartmentField = await getField('DevOps', 'Department');
//   if (process && process.department && DepartmentField) {
//     body.push({
//       op: 'add',
//       path: '/fields/' + DepartmentField,
//       from: null,
//       value: process.department.ERPModule,
//     });
//   }

//   // Area
//   let Area = await getField('DevOps', 'Area');
//   if (Area) {
//     body.push({
//       op: 'add',
//       path: '/fields/System.AreaPath',
//       from: null,
//       value: Area,
//     });
//   }

//   //Iteration
//   let Iteration = await getField('DevOps', 'Iteration');
//   if (Iteration) {
//     body.push({
//       op: 'add',
//       path: '/fields/System.IterationPath',
//       from: null,
//       value: Iteration,
//     });
//   }

//   // Status

//   if (status) {
//     body.push({
//       op: 'add',
//       path: '/fields/System.State',
//       from: null,
//       value: status,
//     });
//   }

//   // console.log(body);
//   let result = null;
//   let response = null;
//   try {
//     result = await axios.post(url, body, await getHeader());
//     if (result.status == 200) {
//       response = {
//         id: result.data.id,
//         link: result.data._links.html.href,
//       };
//     } else if (result.status == 203) {
//       console.log('Login failed?');
//       //userefresh and retry
//       await useRefresToken();
//       try {
//         result = await axios.post(url, body, await getHeader());
//         if (result.status == 200) {
//           response = {
//             id: result.data.id,
//             link: result.data._links.html.href,
//           };
//         } else {
//           console.log('redirect');
//           // window.location.replace('https://www.foocus.be');
//         }
//       } catch (error) {
//         console.log('redirect');
//         // window.location.replace('https://www.foocus.be');
//       }

//       // if still failing redirect to link account page
//     } else vue.toasted.global.error('Error creating ' + type);
//   } catch (error) {
//     console.log(error);
//   }

//   return response;
// }

async function GetWorkItem(item) {
  let url = `https://dev.azure.com/${config.devOps.organisation}/${config.devOps.project}/_apis/wit/workitems/${item}`;

  let result = null;
  let response = null;
  try {
    result = await axios.get(url, await getHeader());
    if (result.status == 200) {
      response = {
        status: result.data.fields['System.State'],
        bugReport: result.data.fields['Microsoft.VSTS.TCM.ReproSteps'],
        Assigned: result.data.fields['System.AssignedTo']
          ? result.data.fields['System.AssignedTo'].displayName
          : '',
      };
    } else vue.toasted.global.error('Error getting Bug');
  } catch (error) {
    console.log(error);
  }

  return response;
}

// async function FindWorkItem(item) {
//   let url = `https://dev.azure.com/${config.devOps.organisation}/${config.devOps.project}/_apis/wit/wiql?api-version=7.0`;
//   let mappings = await feathersClient.service('mappings').find({
//     query: {
//       Application: 'DevOps',
//       Field: 'LoxiID',
//       Order: '0',
//       $sort: { Order: 1 },
//       $limit: 1,
//     },
//   });
//   // console.log(mappings);
//   let LoxiField = null;
//   let ParentType = null;
//   if (mappings.total > 0) {
//     LoxiField = mappings.data[0].ApplicationValue;
//   } else return [];

//   mappings = await feathersClient.service('mappings').find({
//     query: {
//       Application: 'DevOps',
//       Field: 'ParentType',
//       Order: '0',
//       $sort: { Order: 1 },
//       $limit: 1,
//     },
//   });
//   if (mappings.total > 0) {
//     ParentType = mappings.data[0].ApplicationValue;
//   } else return [];

//   let body = {
//     query:
//       'Select [System.Id], [System.Title], [System.State] From WorkItems Where ' +
//       LoxiField +
//       " Contains  '" +
//       item +
//       "' AND System.WorkItemType = '" +
//       ParentType +
//       "'",
//   };
//   body = JSON.stringify(body);
//   let result = null;
//   let response = null;
//   let parents = [];

//   try {
//     result = await axios.post(url, body, await getHeader(2));
//     // console.log('f', result);
//     if (result.status == 200) {
//       response = result.data.workItems;
//       if (response.length > 0) {
//         response.forEach((r) => {
//           // console.log(r);
//           parents.push(r);
//         });
//         // response = response[0].id;
//       } else parents = [];
//     } else if (result.status == 203) {
//       await useRefresToken();
//       result = await axios.post(url, body, await getHeader(2));
//       //console.log(result);
//       response = result.data.workItems;
//       if (response.length > 0) {
//         response.forEach((r) => {
//           // console.log(r);
//           parents.push(r);
//         });
//         // response = response[0].id;
//       } else parents = [];
//     } else vue.toasted.global.error('Error getting Parent WorkItem');
//   } catch (error) {
//     console.log(error);
//   }
//   return parents;
// }

export { CreateWorkItem };
