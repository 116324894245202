<template>
  <v-container>
    <ProcessSteps v-model="show" :variant="variant" />
  </v-container>
</template>

<script>
import ProcessSteps from '@/components/ProcessStep/ProcessSteps';
import { makeGetMixin } from 'feathers-vuex';

export default {
  components: { ProcessSteps },
  data() {
    return {
      show: true,
    };
  },
  watch: {
    '$route.params.id': {
      handler: function() {},
      deep: true,
      immediate: true,
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  mixins: [
    makeGetMixin({
      service: 'variant',
      id: 'id',
      watch: 'id',
    }),
  ],
};
</script>

<style lang="scss" scoped></style>
