<template>
  <v-dialog v-if="showDialog" v-model="showDialog" max-width="500px">
    <v-form ref="form">
      <v-card :loading="loading" flat>
        <v-card-title>
          <span class="headline">Update Status</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                v-model="SelectedStatus"
                :items="status"
                item-text="Status"
                item-value="id"
                label="Status"
                outlined
                hide-details
                dense
              >
                <template v-slot:item="{ item }">
                  <v-icon small class="mr-2" :color="item.Color">
                    mdi-circle
                  </v-icon>
                  {{ item.Status }} </template
                ><template #selection="{ item }">
                  <v-icon small class="mr-2" :color="item.Color">
                    mdi-circle
                  </v-icon>
                  {{ item.Status }}
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row
            ><v-col>
              <v-checkbox
                v-model="includeSpecifications"
                dense
                hide-details
                label="Include Specifications?" /></v-col
          ></v-row>
          <v-row v-if="checkStatus.size > 1"
            ><v-col>
              <v-checkbox
                v-model="confirmMultiple"
                dense
                hide-details
                label="Multiple statusses exist. Confirm update." /></v-col
          ></v-row>
          <v-row
            ><v-col
              >{{ stepsToUpdate.length }} process steps will be updated</v-col
            ></v-row
          >
          <v-row v-if="includeSpecifications"
            ><v-col
              >{{ specificationsToUpdate.length }} specifications will be
              updated</v-col
            ></v-row
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="cancelStatus()">Cancel</v-btn>
          <v-btn
            :loading="saving"
            prepend-icon="mdi-cloud-upload"
            color="primary"
            :disabled="!allowSave || loading || stepsToUpdate.length == 0"
            @click="saveStatus()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import { handleErrorResponse } from '@/utils/MessageHandler';
export default {
  props: {
    value: { type: Boolean },
    id: null,
    type: null,
  },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'process-step', watch: 'id' }),
  ],
  data() {
    return {
      SelectedStatus: null,
      confirmMultiple: false,
      includeSpecifications: false,
      stepsToUpdate: [],
      specificationsToUpdate: [],
      loading: false,
      saving: false,
    };
  },
  methods: {
    handleErrorResponse,
    async saveStatus() {
      this.saving = true;
      if (this.stepsToUpdate) {
        this.stepsToUpdate.forEach((f) => {
          feathersClient
            .service('process-step')
            .patch(f.id, {
              StatusId: this.SelectedStatus,
            })
            .catch(async (error) => {
              handleErrorResponse(error);
            });
        });
      }

      if (this.includeSpecifications && this.specificationsToUpdate) {
        this,
          this.specificationsToUpdate.forEach((f) => {
            feathersClient
              .service('specification')
              .patch(f.id, {
                StatusId: this.SelectedStatus,
              })
              .catch(async (error) => {
                handleErrorResponse(error);
              });
          });
      }
      const { Variant, ProcessStep } = this.$FeathersVuex.api;
      await ProcessStep.find({
        query: {},
      });
      await Variant.find({
        query: {},
      });
      this.saving = false;
      this.showDialog = false;
    },
    async saveStatusProcessStep(VariantId) {
      const { Variant, ProcessStep } = this.$FeathersVuex.api;

      if (this.stepsToUpdate) {
        this.stepsToUpdate.forEach((f) => {
          feathersClient
            .service('process-step')
            .patch(f.id, {
              StatusId: this.SelectedStatus,
            })
            .catch(async (error) => {
              handleErrorResponse(error);
            });
        });

        await ProcessStep.find({
          query: { VariantId: VariantId },
        });
        await Variant.find({
          query: { id: VariantId },
        });
      }
      this.showDialog = false;
    },
    cancelStatus() {
      this.showDialog = false;
    },
  },
  computed: {
    statusParams() {
      return { query: { Active: true, $sort: { Number: 1 } } };
    },
    processStepParams() {
      return { query: { VariantId: this.id } };
    },
    checkStatus() {
      const status = new Set();
      this.stepsToUpdate.forEach((p) => status.add(p.status.Status));
      if (this.includeSpecifications)
        this.specificationsToUpdate.forEach((p) => status.add(p.status.Status));
      return status;
    },
    allowSave() {
      return (
        this.SelectedStatus &&
        (this.checkStatus.size == 1 ||
          (this.checkStatus.size > 1 && this.confirmMultiple))
      );
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    this.loading = true;
    const { Variant, ProcessStep, Specification } = this.$FeathersVuex.api;
    let specifications = await Specification.find();
    specifications =
      specifications && specifications.data ? specifications.data : [];

    if (this.type == 'variant') {
      let steps = await ProcessStep.find({
        query: { VariantId: this.id },
      });
      this.stepsToUpdate = steps && steps.data ? steps.data : [];

      this.specificationsToUpdate = specifications.filter((item) =>
        item.variants
          ? item.variants.some((variant) => variant.id == this.id)
          : null
      );
    } else if (this.type == 'process' || this.type == 'department') {
      let variants = [];
      if (this.type == 'process') {
        variants = await Variant.find({ query: { ProcessId: this.id } });
        variants = variants && variants.data ? variants.data : [];
      } else if (this.type == 'department') {
        variants = await Variant.find({
          query: {},
        });
        variants =
          variants && variants.data
            ? variants.data.filter((f) => f.process.DepartmentId == this.id)
            : [];
      }
      if (variants) {
        await Promise.all(
          variants.map(async (v) => {
            let steps = await ProcessStep.find({
              query: { VariantId: v.id },
            });
            steps && steps.data ? this.stepsToUpdate.push(...steps.data) : [];
            let specs = specifications.filter((item) =>
              item.variants
                ? item.variants.some((variant) => variant.id == v.id)
                : null
            );
            specifications ? this.specificationsToUpdate.push(...specs) : [];
          })
        );
      }
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped></style>
