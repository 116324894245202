<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="retrievedVariant"
      :loading="isFindRetrievedVariantPending"
      :sort-by="['Order', 'Number']"
      show-group-by
      disable-pagination
      hide-default-footer
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.Name`]="{ item }"
        ><a
          @click="
            $router.push({
              name: 'processStep',
              params: { id: item.id },
            })
          "
          >{{ item.Name }}</a
        >
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          v-if="item.StatusSummarySteps && item.StatusSummarySteps.length > 0"
        >
          <v-icon x-small>mdi-format-list-text</v-icon>
          <StatusListIndividual :statusList="item.StatusSummarySteps" />
        </div>
        <div
          v-if="item.StatusSummarySpecs && item.StatusSummarySpecs.length > 0"
        >
          <v-icon x-small>mdi-pin</v-icon>
          <StatusListIndividual :statusList="item.StatusSummarySpecs" />
        </div>

        <div
          v-if="item.StatusSummaryIssues && item.StatusSummaryIssues.length > 0"
        >
          <v-icon x-small>mdi-login-variant</v-icon>
          <StatusListIndividual :statusList="item.StatusSummaryIssues" />
        </div>
      </template>
      <template v-slot:[`item.steps`]="{ item }">
        <v-icon
          small
          @click="
            $router.push({
              name: 'processStep',
              params: { id: item.id },
            })
          "
        >
          mdi-arrow-decision
        </v-icon>
      </template>

      <template v-slot:[`item.specifications`]="{ item }">
        <span v-for="spec in specificationType" :key="spec.id">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="
                  item.specifications.filter(
                    (f) => f.specification_type.Type == spec.Type
                  ).length > 0
                    ? 'green'
                    : '#CBCBCB'
                "
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="
                  $router.push({
                    name: 'specification',
                    params: { id: item.id, type: spec.id },
                  })
                "
              >
                {{ spec.Icon }}
              </v-icon>
            </template>
            <span
              >{{ spec.Type }} ({{
                item.specifications.filter(
                  (f) => f.specification_type.Type == spec.Type
                ).length
              }})</span
            >
          </v-tooltip>
        </span>
      </template>
    </v-data-table>
    <Response v-if="showResponse" v-model="showResponse" :item="currentItem" />
    <Companies
      v-if="showCompanies"
      v-model="showCompanies"
      :item="currentItem"
    />
    <Roles v-if="showRoles" v-model="showRoles" :item="currentItem" />
    <ShowBudget
      v-if="showBudget"
      v-model="showBudget"
      :variantId="retrievedVariant.id"
    />
    <VariantAddApp
      v-if="retrievedVariant"
      v-model="addAppDialog"
      :variantId="retrievedVariant.id"
    />
    <UpdateStatus
      v-if="currentItem && updateDialog"
      v-model="updateDialog"
      :id="currentItem.id"
      :type="'variant'"
    />
    <v-dialog v-model="copyDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Copy Sub Process</v-card-title>
        <v-card-text
          >Sub process ' {{ copyItem.Name }} ' wil be copied.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="copyDialog = false"> Cancel </v-btn>
          <v-btn color="primary" :loading="loading" @click="executeCopy()">
            Copy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CopySubProcess } from '@/utils/CopySubProcess';
// import VariantEdit from '@/components/Variant/VariantEdit';
import VariantAddApp from '@/components/Variant/VariantAddApp';
import UpdateStatus from '@/components/Variant/UpdateStatus';
import Response from '@/components/Variant/VariantResponses';
import Companies from '@/components/Variant/VariantCompanies';
import Roles from '@/components/Variant/VariantRoles';
import ShowBudget from '@/components/Budget/ShowBudget';
import StatusListIndividual from '@/components/StatusListIndividual';
import { makeFindMixin } from 'feathers-vuex';
import { subject } from '@casl/ability';
export default {
  name: 'VariantList',
  props: ['variant', 'process'],
  components: {
    StatusListIndividual,
    // VariantEdit,
    VariantAddApp,
    Response,
    Companies,
    Roles,
    ShowBudget,
    UpdateStatus,
  },
  mixins: [
    makeFindMixin({
      service: 'variant',
      id: 'id',
      name: 'retrievedVariant',
    }),
    makeFindMixin({ service: 'specification-type' }),
    makeFindMixin({ service: 'test-scenario' }),
  ],
  data() {
    return {
      detailDialog: false,
      updateDialog: false,
      showResponse: false,
      showCompanies: false,
      showRoles: false,
      showBudget: false,
      currentItem: null,
      validInput: true,
      addAppDialog: false,
      show: false,
      copyDialog: false,
      copyItem: { Name: null },
      loading: false,
      headers: [
        {
          text: 'Actions',
          value: 'edit',
          sortable: false,
          groupable: false,
        },

        { text: 'Nr', value: 'Number', groupable: false },

        { text: 'Variant', value: 'Name', groupable: false },
        {
          text: 'Status',
          value: 'status',
          align: 'start',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Steps',
          value: 'steps',
          align: 'center',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Specifications',
          value: 'specifications',
          align: 'center',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },

  methods: {
    StartCopySubProcess(item) {
      this.copyDialog = true;
      this.copyItem = item;

      console.log(item);
    },
    async executeCopy() {
      this.loading = true;
      await CopySubProcess(this.copyItem.id, {
        includeSpecifications: true,
        includeFields: true,
      });
      this.loading = false;
      this.copyDialog = false;
      const { Variant, Process } = this.$FeathersVuex.api;
      Variant.find({ query: { ProcessId: this.process.id } });
      Process.find({ query: { id: this.process.id } });
    },
    checkEditVariant(item) {
      return this.$can('update', subject('variant', item));
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickResponses(item) {
      this.currentItem = item;
      this.showResponse = true;
    },
    clickBudget(item) {
      this.currentItem = item;
      this.showBudget = true;
    },
    clickCompanies(item) {
      this.currentItem = item;
      this.showCompanies = true;
    },
    clickRoles(item) {
      this.currentItem = item;
      this.showRoles = true;
    },
    updateStatus(item) {
      this.currentItem = item;
      this.updateDialog = true;
    },
    clickAddApp() {
      this.addAppDialog = true;
    },

    async removeApp(variant, app) {
      // console.log(variant.id, app.id);
      const { erpapplication_variant, Variant } = this.$FeathersVuex.api;
      let result = await erpapplication_variant.find({
        query: {
          VariantId: variant.id,
          ErpApplicationId: app.id,
        },
      });
      if (result.data) {
        await this.$store.dispatch(
          'erpapplication-variant/remove',
          result.data[0].id
        );
        Variant.get(variant.id);
      }
    },
  },

  computed: {
    id() {
      return this.variant.id;
    },
    testScenarioParams() {
      return { query: { VariantId: this.variant.id } };
    },
    retrievedVariantParams() {
      return { query: { ProcessId: this.process.id } };
    },
    specificationTypeParams() {
      return { query: { Active: true } };
    },
  },
};
</script>

<style lang="scss" scoped></style>
