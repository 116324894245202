<template>
  <v-dialog :value="true" persistent max-width="1100px">
    <div v-if="item">
      <v-form ref="form" v-model="validInput">
        <v-card flat>
          <v-card-title>
            <span class="headline">New/Edit Field</span>
          </v-card-title>
          <v-alert icon="$warning" color="#FFC698" v-if="linkedProcessStep > 1"
            >Field is linked to multiple processsteps
            {{ ProcessSteps }}</v-alert
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" md="1">
                <v-text-field
                  v-model="item['Number']"
                  label="Nr"
                  outlined
                  dense
                  :rules="[rules.number]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="item['Name']"
                  label="Field Name*"
                  :rules="[rules.required]"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="item['Description']"
                  label="Description"
                  outlined
                  dense
                  rows="1"
                  auto-grow
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="$can('delete', 'field')"
              outlined
              @click="showRemoveMessage = true"
              >Remove</v-btn
            >
            <div v-if="showRemoveMessage" class="ml-5">
              Are you sure? Linked to
              {{ linkedProcessStep }} process steps.
              <v-btn class="ml-2" @click="$emit('remove')" color="error"
                >Yes</v-btn
              >
            </div>
            <v-spacer></v-spacer>
            <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
            <v-btn
              :disabled="!validInput"
              color="primary"
              :loading="saving"
              @click="$emit('save')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'FieldEditDialog',
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      linkedProcessStep: 0,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
  async mounted() {
    if (this.item && this.item.id) {
      const { FieldStep } = this.$FeathersVuex.api;
      let count = await FieldStep.count({
        query: { FieldId: this.item.id },
      });

      this.linkedProcessStep = count ? count : 0;
    }
  },
};
</script>
