<template>
  <div>
    <v-card
      :color="
        user.vendor.ResponsePhase == 1
          ? editItem.Final
            ? '#C7FFBF'
            : editItem.ImplementationId && editItem.ComplexityId
            ? '#FFFCBD'
            : '#FFCDD2'
          : '#F5F5F5'
      "
    >
      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
      <v-card-text
        v-if="variant.Input"
        v-html="variant.Input"
        class="ql-editor"
      ></v-card-text>
      <v-card-text
        v-if="variant.Output"
        v-html="variant.Output"
        class="ql-editor"
      ></v-card-text>
      <v-card-title>Response</v-card-title>
      <v-card-text>
        <v-form ref="form" :disabled="user.vendor.ResponsePhase != 1">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                v-model="editItem.ImplementationId"
                :items="implementation"
                item-text="Name"
                item-value="id"
                hide-details
                label="Implementation"
                :disabled="
                  !$can('update', 'response') ||
                  editItem.Final ||
                  (vendor[0] ? vendor[0].SendInDate != null : true)
                "
                outlined
                dense
                clearable
                @blur="updateResponse()"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="editItem.ComplexityId"
                :items="filteredComplexity(editItem.ImplementationId)"
                item-text="Name"
                item-value="id"
                hide-details
                label="Complexity"
                :disabled="
                  !$can('update', 'response') ||
                  editItem.Final ||
                  (vendor[0] ? vendor[0].SendInDate != null : true)
                "
                outlined
                dense
                clearable
                @change="updateResponse()"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="editItem.Remark"
                label="Remark"
                hide-details
                :disabled="
                  !$can('update', 'response') ||
                  editItem.Final ||
                  (vendor[0] ? vendor[0].SendInDate != null : true)
                "
                outlined
                dense
                rows="1"
                auto-grow
                clearable
                @blur="updateResponse()"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                v-model="editItem.Final"
                hide-details
                :disabled="
                  !$can('update', 'response') ||
                  (vendor[0] ? vendor[0].SendInDate != null : true)
                "
                :label="`Final`"
                dense
                @click="updateResponse(true)"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <ProcessSteps :variant="variant" />
    <v-overlay opacity="0" :value="saving">
      <v-progress-circular
        indeterminate
        size="64"
        color="#000000"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import ProcessSteps from '@/components/VendorResponse/ProcessSteps.vue';
import { mapGetters } from 'vuex';
import feathersClient from '@/feathers-client';
import {
  // handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  props: ['variant'],
  mixins: [
    makeFindMixin({ service: 'response' }),
    makeFindMixin({ service: 'complexity' }),
    makeFindMixin({ service: 'implementation' }),
    makeFindMixin({ service: 'vendor' }),
  ],
  components: { ProcessSteps },
  watch: {
    response: {
      handler: async function () {
        this.editItem =
          this.response && this.response[0]
            ? this.response[0]
            : this.defaultItem;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      editItem: {},
      defaultItem: {
        ComplexityId: null,
        ImplementationId: null,
        Remark: '',
        final: false,
      },
      saving: false,
      debounce: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    responseParams() {
      return {
        query: { VariantId: this.variant.id, VendorId: this.user.vendor.id },
      };
    },
    vendorParams() {
      return { query: { id: this.user.vendor ? this.user.vendor.id : null } };
    },
    complexityParams() {
      return { query: { $sort: { Weight: -1 } } };
    },
    implementationParams() {
      return { query: { $sort: { Weight: -1 } } };
    },
  },
  methods: {
    saveRemark() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.updateResponse();
      }, 600);
    },
    filteredComplexity(item) {
      if (this.complexity && this.implementation) {
        let imp = this.implementation.find((f) => f.id == item);
        if (imp && imp.AllowedComplexity && imp.AllowedComplexity.length > 0) {
          return this.complexity.filter((f) =>
            imp.AllowedComplexity.includes(f.id)
          );
        } else return this.complexity;
      }
      return null;
    },

    updateResponse(final) {
      if (
        this.response &&
        this.response[0] &&
        this.editItem.ComplexityId &&
        this.editItem.ImplementationId
      ) {
        // update response
        this.saving = true;
        feathersClient
          .service('response')
          .update(this.response[0].id, this.editItem)
          .then(async () => {
            // handleSaveResponse(this.variant.Name, 'response', 'updated');
            this.saving = false;
          })
          .catch(async (error) => {
            handleErrorResponse(error);
            this.saving = false;
          });
      } else {
        //create response
        if (this.editItem.ComplexityId && this.editItem.ImplementationId) {
          this.saving = true;
          this.editItem.VendorId = this.user.vendor.id;
          this.editItem.DateReceived = new Date();
          this.editItem.VariantId = this.variant.id;
          feathersClient
            .service('response')
            .create(this.editItem)
            .then(async () => {
              // handleSaveResponse(this.variant.Name, 'response', 'added');
              this.saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              this.saving = false;
            });
        } else {
          let missing = [];
          if (!this.editItem.ComplexityId) missing.push('Complexity');
          if (!this.editItem.ImplementationId) missing.push('Implementation');
          this.editItem.Final = false;
          if (final) {
            this.$toasted.global.info(
              `Please fill in ${missing.join(
                ' and '
              )} before your response is registered.`
            );
          }
        }
      }
    },
  },
  mounted() {
    this.editItem =
      this.response && this.response[0] ? this.response[0] : this.defaultItem;
  },
};
</script>

<style lang="scss" scoped></style>
