<template>
  <div>
    <div class="fullheight-container" v-if="TokenEmbed">
      <iframe
        class="full-width"
        frameBorder="0"
        :src="`https://lucid.app/documents/picker?token=${TokenEmbed}`"
      ></iframe>
    </div>
    <v-progress-linear
      v-else
      indeterminate
      color="secondary"
    ></v-progress-linear>
  </div>
</template>

<script>
import {
  getDocumentPickerToken,
  getDocumentInfo,
  parseDocumentInfo,
} from '@/utils/LucidChartFunctions';
import { makeFindMixin } from 'feathers-vuex';

export default {
  data() {
    return { TokenEmbed: null };
  },
  mixins: [makeFindMixin({ service: 'department' })],
  computed: {
    departmentParams() {
      return {};
    },
    processNumber() {
      let number = parseInt(this.event.title.substring(0, 4));
      return number;
    },
    departmentImported() {
      if (this.processNumber && this.department) {
        let numberRounder = Math.floor(this.processNumber / 100) * 100;
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        numberRounder = zeroPad(numberRounder, 4);
        let result = this.department.filter(
          (item) => item.CustomerDepartment == numberRounder
        );
        return result;
      } else return null;
    },
  },
  methods: {
    async receiveMessage(event) {
      if (event && event.data.status == 'success') {
        let result = await getDocumentInfo(null, event.data.documentId);
        let parsed = parseDocumentInfo(result);
        this.$emit('retrievedDocument', {
          documentInfo: result,
          documentParsed: parsed,
        });
      }
    },
  },
  async mounted() {
    this.TokenEmbed = await getDocumentPickerToken(location.origin);
    window.addEventListener('message', this.receiveMessage);
  },
};
</script>

<style lang="scss" scoped>
.fullheight-container {
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
  height: 75vh;
  overflow: hidden;
  align-items: center;
}
.full-width {
  min-width: 100%;
  min-height: 100%;
}
</style>
