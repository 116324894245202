<template>
  <div v-if="item">
    <v-form ref="form" v-model="validInput">
      <v-card flat>
        <v-card-title>
          <span class="headline">New/Edit Variant</span>
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item['Number']"
                label="Nr"
                outlined
                dense
                :rules="[rules.required, rules.number]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="item['Name']"
                label="Variant Name"
                :rules="[rules.required]"
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              Variant Input<RichEditor
                v-model="item.Input"
                :disabled="false"
                placeholder="Variant Input"
              ></RichEditor>
            </v-col>
            <v-col cols="12" md="12">
              Variant Output<RichEditor
                v-model="item.Output"
                :disabled="false"
                placeholder="Variant Output"
              ></RichEditor>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="item['ApplicationId']"
                :items="applicationCalculated"
                item-text="fullName"
                item-value="id"
                label="Application"
                outlined
                :rules="[rules.required]"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="!importVariant">
              <v-text-field
                v-model="item['PageIndexes']"
                label="Page Index"
                outlined
                dense
              >
              </v-text-field
            ></v-col>
            <v-col cols="12" md="6" v-if="!importVariant">
              <v-text-field
                v-model="item['ExternalId']"
                label="External Id"
                outlined
                dense
              >
              </v-text-field
            ></v-col>
          </v-row>
          <v-row v-if="importVariant">
            <v-col cols="12" md="6">
              <v-select
                chips
                small-chips
                v-model="item['SelectedPageIndexes']"
                :items="pages"
                item-text="title"
                item-value="index"
                label="Pages"
                outlined
                dense
                multiple
              ></v-select> </v-col
          ></v-row>
        </v-card-text>
        <v-card-actions v-if="!importVariant">
          <v-btn
            v-if="$can('delete', item)"
            outlined
            @click="showRemoveMessage = true"
            >Remove</v-btn
          >
          <div v-if="showRemoveMessage" class="ml-5">
            Are you sure?
            <v-btn class="ml-2" @click="$emit('remove')" color="error"
              >Yes</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
          <v-btn
            :disabled="!validInput"
            :loading="saving"
            color="primary"
            @click="$emit('save')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import RichEditor from '@/components/general/RichEditor.vue';
export default {
  name: 'VariantEditDialog',
  components: {
    RichEditor,
  },
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  mixins: [makeFindMixin({ service: 'application' })],
  props: {
    importVariant: { required: false },
    pages: { required: false },
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
  computed: {
    applicationParams() {
      return {};
    },
    applicationCalculated() {
      return this.application.map((obj) => {
        return {
          ...obj,
          fullName: `${obj.Name} (${obj.Code}) - ${obj.Implemented}`,
        };
      });
    },
  },
};
</script>
