<template>
  <v-dialog v-model="showDialog" width="1000" height="500">
    <v-card flat>
      <v-card-title>
        <span class="headline">Add Statusses for {{ item.Type }}</span
        ><v-spacer></v-spacer>
      </v-card-title>
      <section>
        <v-data-table
          :headers="headers"
          :items="item.issue_type_statuses"
          :items-per-page="20"
          :sort-by="['Order']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          disable-pagination
          :hide-default-footer="true"
          :loading="isFindStatusPending"
          loading-text="Loading... Please wait"
        >
          <template
            v-if="$can('update', 'issue-type-status')"
            v-slot:[`item.status.Status`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.status.Status"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.status.Status }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.StatusId"
                  :items="status"
                  item-text="Status"
                  item-value="id"
                  label="Status"
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
          <template v-if="$can('create', 'issue-type-status')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-select
                v-model="selectedType"
                v-if="item.issue_type_statuses.length == 0"
                :items="issueType"
                item-text="Type"
                item-value="id"
                label="Issue Type"
                outlined
                dense
                hide-details="true"
                ><template v-slot:item="{ item }">
                  <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                  {{ item.Type
                  }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                    >mdi-connection</v-icon
                  >
                </template>
                <template #selection="{ item }">
                  <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                  {{ item.Type
                  }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                    >mdi-connection</v-icon
                  >
                </template></v-select
              ><v-btn
                v-if="item.issue_type_statuses.length == 0"
                color="primary"
                @click="copyFields"
                :disabled="!selectedType || item.issue_type_statuses.length > 0"
                >Copy From</v-btn
              >
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-if="dialog" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Status</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-select
                              v-model="editedItem['StatusId']"
                              :items="status"
                              item-text="Status"
                              item-value="id"
                              label="Status"
                              outlined
                              :rules="[rules.required]"
                              dense
                            >
                              <template v-slot:item="{ item }">
                                <v-icon small class="mr-2" :color="item.Color">
                                  mdi-circle
                                </v-icon>
                                {{ item.Status }} </template
                              ><template #selection="{ item }">
                                <v-icon small class="mr-2" :color="item.Color">
                                  mdi-circle
                                </v-icon>
                                {{ item.Status }}
                              </template></v-select
                            >
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="editedItem.Order"
                              label="Order"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.ExternalStatus"
                              label="External Status"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Add</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'issue-type-status')"
            v-slot:[`item.Order`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Order"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Order }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Order"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type-status')"
            v-slot:[`item.ExternalStatus`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.ExternalStatus"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.ExternalStatus }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.ExternalStatus"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('delete', 'issue-type-status')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    value: { type: Boolean },
    item: {},
  },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'issue-type' }),
  ],
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      dialog: false,
      validInput: false,
      editedItem: {},
      selectedType: null,
    };
  },
  methods: {
    async save(item) {
      try {
        await feathersClient.service('issue-type-status').patch(item.id, {
          ExternalStatus: item.ExternalStatus,
          StatusId: item.StatusId,
          Order: item.Order,
        });
        handleSaveResponse(item.id, 'External Issue Type', 'updated');
      } catch (error) {
        handleErrorResponse(error);
      }
      await this.Refresh();
    },
    open() {},
    cancel() {},
    close() {},
    Close() {
      this.dialog = false;
      this.validInput = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    async Refresh() {
      const { IssueType } = this.$FeathersVuex.api;
      await IssueType.find({
        query: {},
      });
    },
    async copyFields() {
      const result = await feathersClient
        .service('issue-type-status')
        .find({ query: { IssueTypeId: this.selectedType } });

      if (result.total > 0) {
        for (const status of result.data) {
          await feathersClient.service('issue-type-status').create({
            ExternalStatus: status.ExternalStatus,
            Order: status.Order,
            StatusId: status.StatusId,
            IssueTypeId: this.item.id,
          });
        }
      }
      await this.Refresh();
      this.selectedType = null;
    },
    async Add() {
      this.editedItem.IssueTypeId = this.item.id;
      try {
        await feathersClient
          .service('issue-type-status')
          .create(this.editedItem);
        handleSaveResponse('', 'Issue Type Status', 'added');
      } catch (error) {
        handleErrorResponse(error);
      }
      await this.Refresh();

      this.Close();
    },
    async deleteItem(item) {
      await feathersClient
        .service('issue-type-status')
        .remove(item.id)
        .then(() => handleSaveResponse(item.id, 'Issue Type Status', 'removed'))
        .catch((error) => handleErrorResponse(error));
      await this.Refresh();
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    statusParams() {
      return {};
    },
    issueTypeParams() {
      return { query: { id: { $ne: this.item.id } } };
    },
    headers() {
      return [
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status.Status',
        },
        {
          text: 'Order',
          align: 'start',
          sortable: true,
          value: 'Order',
        },
        {
          text: 'External Status',
          align: 'start',
          sortable: false,
          value: 'ExternalStatus',
        },

        { text: 'Delete', value: 'actions', sortable: false },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
