<template>
  <div>
    <v-dialog :value="true" persistent max-width="600px">
      <v-progress-linear
        v-if="isFindProcessPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindProcessPending">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">Add Process to End 2 End</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="item['processId']"
                    :items="filteredProcesses"
                    item-text="Name"
                    item-value="id"
                    label="Process"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                @click="$emit('save')"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';

export default {
  name: 'E2EAddProcessDialog',
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,

      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mixins: [makeFindMixin({ service: 'process' })],
  computed: {
    processParams() {
      return {};
    },
    filteredProcesses() {
      return this.process
        .filter(
          item =>
            !item.end_2_ends.some(e => e.id == this.item.end2EndId) ||
            this.end_2_ends == []
        )
        .sort((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1));
    },
  },
};
</script>
