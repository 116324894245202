<template>
  <div>
    <v-progress-linear
      class="mt-5"
      v-if="isFindSpecificationTypePending"
      color="success"
      height="1"
      indeterminate
    ></v-progress-linear>
    <v-list-item
      v-for="item in specificationType"
      :key="item.id"
      @click="$emit('clicked-specs', item.id)"
    >
      <v-list-item-icon>
        <v-icon class="mr-2">
          {{ item.Icon }}
        </v-icon>
      </v-list-item-icon>

      <span>{{ item.Type }}</span> <v-spacer></v-spacer>

      <StatusListIndividual
        v-if="filteredStatusList(item) && filteredStatusList(item).length > 0"
        :statusList="filteredStatusList(item)"
      />
    </v-list-item>
  </div>
</template>
<script>
import { makeFindMixin } from 'feathers-vuex';
import StatusListIndividual from '@/components/StatusListIndividual';

export default {
  props: ['variant', 'statusList'],
  mixins: [
    makeFindMixin({ service: 'specification' }),
    makeFindMixin({ service: 'specification-type' }),
  ],
  components: { StatusListIndividual },
  methods: {
    filteredStatusList(item) {
      if (this.statusList) {
        return this.statusList.filter((s) => s.Type == item.Type);
      } else return [];
    },
  },

  computed: {
    specificationParams() {
      return {};
    },
    filteredSpecifications() {
      if (this.variant) {
        return this.specification.filter((item) =>
          item.variants.some((variant) => variant.id == this.variant.id)
        );
      } else {
        return this.specification;
      }
    },
    specificationTypeParams() {
      return { query: { Active: true } };
    },
  },
};
</script>
