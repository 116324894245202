var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.id && _vm.variant && _vm.variant.process && _vm.variant.process.department)?_c('div',{staticClass:"caption"},[(_vm.$store.state.activeEnd2End.Name)?_c('router-link',{attrs:{"to":{
        name: 'Process',
        params: { id: _vm.$store.state.activeEnd2End.id },
      }}},[_vm._v("E2E"+_vm._s(_vm.$store.state.activeEnd2End.Number)+" - "+_vm._s(_vm.$store.state.activeEnd2End.Name))]):_vm._e(),(_vm.$store.state.activeEnd2End.Name)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]):_vm._e(),_vm._v(_vm._s(_vm.variant.process.department.Name)),_c('v-menu',{attrs:{"open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-double-right")])]}}],null,false,3856361493)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.fullProcessList),function(process){return _c('v-list-item',{key:process.id},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{
                name: 'processDetail',
                params: { id: process.id, number: process.Number },
              }}},[_vm._v(_vm._s(process.Number)+". "+_vm._s(process.Name))])],1)],1)}),1)],1),_c('router-link',{attrs:{"to":{
        name: 'processDetail',
        params: { id: _vm.variant.process.id, number: _vm.variant.process.Number },
      }}},[_vm._v(_vm._s(_vm.$appConfig.customer.prefix)+_vm._s(_vm.variant.process.Number.toString().padStart(4, '0'))+" - "+_vm._s(_vm.variant.process.Name))]),_c('v-menu',{attrs:{"open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-double-right")])]}}],null,false,3856361493)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.processVariants),function(variant){return _c('v-list-item',{key:variant.id},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{ name: 'processStep', params: { id: variant.id } }}},[_vm._v(_vm._s(variant.Number)+". "+_vm._s(variant.Name))])],1)],1)}),1)],1),_c('router-link',{attrs:{"to":{ name: 'processStep', params: { id: _vm.variant.id } }}},[_vm._v(_vm._s(_vm.variant.Number)+" - "+_vm._s(_vm.variant.Name))]),_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"small":""}},[_vm._v("mdi-forward")]),(_vm.$feature('specification'))?_c('router-link',{attrs:{"to":{
        name: 'specification',
        params: { id: _vm.variant.id, type: 1 },
      }}},[_c('b',[_vm._v("Specifications ("+_vm._s(_vm.variant.specifications ? _vm.variant.specifications.filter( (s) => s.specification_type.Active == true && s.Obsolete == false ).length : 0))]),_vm._v(")")]):_c('router-link',{attrs:{"to":{
        name: 'processRequirement',
        params: { id: _vm.variant.id, type: 1 },
      }}},[_vm._v("Requirements ("+_vm._s(_vm.variant.requirements ? _vm.variant.requirements.length : 0)+")")]),(_vm.sharePointUrl)?_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"small":""}},[_vm._v("mdi-forward")]):_vm._e(),(_vm.sharePointUrl)?_c('a',{attrs:{"target":"_blank","href":_vm.sharePointUrl}},[_vm._v("Documents")]):_vm._e()],1):_vm._e(),(_vm.id && _vm.variant)?_c('h1',[_vm._v("Specifications for "+_vm._s(_vm.variant.Name))]):_c('h1',[_vm._v("All Specifications")]),_c('v-spacer'),_c('v-tabs',{attrs:{"fixed-tabs":"","active-class":"activeTab"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.specificationType),function(item){return _c('v-tab',{key:item.id},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(item.Icon))]),_vm._v(" "+_vm._s(item.Type)+" ")],1)}),1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.specificationType),function(item){return _c('v-tab-item',{key:item.id},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('specification-list',{attrs:{"specificationType":item,"variant":_vm.variant}})],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }