<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <SpecificationAddVariantDialog
        :item="clone"
        @save="
          showDialog = false;
          save().then(handleSaveResponse).catch(errorHandler);
        "
        @reset="reset"
        @remove="
          remove();
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></SpecificationAddVariantDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import SpecificationAddVariantDialog from '@/components/Specification/SpecificationAddVariantDialog';

export default {
  name: 'SpecificationVariantAdd',
  components: {
    FeathersVuexFormWrapper,
    SpecificationAddVariantDialog,
  },
  data() {
    return {
      //item: null,
    };
  },
  props: {
    value: { type: Boolean },
    specificationId: null,
  },
  computed: {
    item() {
      const { SpecificationVariant } = this.$FeathersVuex.api;

      let newRecord = new SpecificationVariant();
      newRecord.SpecificationId = this.specificationId;
      return newRecord;
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    async handleSaveResponse() {
      this.$toasted.global.success(`Saved successfully`);
      const { Specification } = this.$FeathersVuex.api;
      await Specification.find({
        query: {},
      });
    },
    errorHandler(error) {
      //console.log(error);
      let errorMessage = '';
      if (
        Object.keys(error.errors).length === 0 &&
        error.errors.constructor === Object
      ) {
        errorMessage = error.message;
      } else {
        error.errors.map((item) => {
          errorMessage += item.message + '\n';
        });
        this.errorMessage = errorMessage;
      }
      this.$toasted.global.error(errorMessage);
    },
  },
  async mounted() {
    const { SpecificationVariant } = this.$FeathersVuex.api;
    let newRecord = new SpecificationVariant();
    newRecord.SpecificationId = this.specificationId;
    this.item = newRecord;
  },
};
</script>
