<template>
  <div>
    <v-row
      class="mr-5 mb-4"
      v-for="reply in item.external_issue_replies
        .slice()
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))"
      :key="reply.id"
    >
      <v-col cols="12" md="1" class="ma-0 pa-0" align="right">
        <UserAvatar
          class="mb-2 mr-0 pr-0"
          :Name="reply.CreatedBy.fullname"
          :Image="reply.CreatedBy.gravatarUrl"
          :Color="reply.CreatedBy.color"
      /></v-col>
      <v-col cols="12" md="11" class="ma-0 pa-0">
        <v-card
          width="100%"
          class="rounded-card ma-0 pa-0"
          outlined
          color="#e6e6e6"
          ><v-card-title class="body-2 ma-2 pa-0"
            ><span
              ><b class="ml-2">{{ reply.CreatedBy.fullname }}</b> -
              {{ new Date(reply.createdAt).toLocaleString() }}</span
            ><v-spacer></v-spacer
            ><v-icon
              v-if="checkEditReply(reply)"
              :disabled="item?.IssueType?.ExternalType?.length > 0"
              small
              class="ml-5"
              @click="clickAddReply(reply)"
              color="primary"
            >
              mdi-pencil
            </v-icon></v-card-title
          ><v-card-text class="ml-4 mt-0 pa-0 pr-8 mb-3">
            <span
              v-html="reply.Reply"
              class="text-body-2 ma-0 pa-0 ql-editor medium"
            ></span>
          </v-card-text> </v-card
        ><span class="ml-4" v-if="reply.Likes && reply.Likes > 0"
          ><v-icon class="ml-1" @click="addLike(reply)" color="primary" small>
            mdi-thumb-up
          </v-icon>
          ({{ reply.Likes }})</span
        >
        <v-icon
          v-else
          class="ml-4"
          @click="addLike(reply)"
          color="primary"
          small
        >
          mdi-thumb-up-outline
        </v-icon></v-col
      >
    </v-row>

    <v-row class="mr-5 mb-4"
      ><v-col cols="12" md="1" class="ma-0 pa-0" align="right"
        ><v-icon
          class="mt-2 mr-2"
          v-if="$can('create', 'external-issue-reply')"
          :disabled="item?.IssueType?.ExternalType?.length > 0"
          @click="clickAddReply(null)"
          color="primary"
          large
        >
          mdi-reply
        </v-icon>
      </v-col>
      <v-col cols="12" md="11" class="ma-0 pa-0"
        ><v-card
          v-if="false"
          width="100%"
          height="50px"
          class="rounded-card ma-0 pa-0"
          outlined
          color="#e6e6e6"
          ><v-card-text>Write a reply</v-card-text></v-card
        ></v-col
      >
    </v-row>
    <IssueReplyEdit
      v-model="showDialog"
      v-if="showDialog"
      :currentItem="currentItem"
      :externalIssueId="item.id"
    />
  </div>
</template>

<script>
import feathersClient from '@/feathers-client';
import { applyDark } from '@/utils/Utilities';
import { subject } from '@casl/ability';
import IssueReplyEdit from '@/components/Issues/IssueReplyEdit.vue';
import UserAvatar from '@/components/general/UserAvatar.vue';
export default {
  props: {
    item: { type: Object, required: true },
  },
  components: {
    IssueReplyEdit,
    UserAvatar,
  },
  data() {
    return {
      showDialog: false,
      currentItem: null,
    };
  },
  methods: {
    applyDark,
    checkEditReply(item) {
      return this.$can('update', subject('external-issue-reply', item));
    },
    async addLike(item) {
      ++item.Likes;
      try {
        await feathersClient
          .service('external-issue-reply')
          .patch(item.id, { Likes: item.Likes });
      } catch (error) {
        console.log(error);
      }
    },
    async clickAddReply(item) {
      this.showDialog = true;
      this.currentItem = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.rounded-card {
  border-radius: 15px;
}
</style>
