<template>
  <v-container
    ><FeathersVuexFind
      v-slot="{ items: vendor }"
      service="vendor"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="vendor"
          :items-per-page="100"
          :sort-by="['Name']"
          :sort-desc="[true]"
          item-key="id"
          class="elevation-1"
          hide-default-footer
          ><template v-if="$can('create', 'vendor')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Vendor</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Name"
                              label="Name"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Package"
                              label="Package"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'vendor')"
            v-slot:[`item.Name`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Name"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Name }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Name"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'vendor')"
            v-slot:[`item.Package`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Package"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Package }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Package"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'vendor')"
            v-slot:[`item.ResponsePhase`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.ResponsePhase"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.ResponsePhase }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.ResponsePhase"
                  :items="[1, 2]"
                  label="Edit"
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'vendor')"
            v-slot:[`item.Deadline`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Deadline"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{
                props.item.Deadline
                  ? new Date(props.item.Deadline).toLocaleString([], {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })
                  : null
              }}
              <template v-slot:input>
                <v-date-picker v-model="props.item.Deadline"></v-date-picker>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'vendor')"
            v-slot:[`item.Deadline2`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Deadline2"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{
                props.item.Deadline2
                  ? new Date(props.item.Deadline2).toLocaleString([], {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })
                  : null
              }}
              <template v-slot:input>
                <v-date-picker v-model="props.item.Deadline2"></v-date-picker>
              </template>
            </v-edit-dialog>
          </template>

          <template
            v-if="$can('delete', 'vendor')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section></FeathersVuexFind
    ></v-container
  >
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'Name',
        },
        {
          text: 'Package',
          align: 'start',
          sortable: true,
          value: 'Package',
        },
        {
          text: 'Response Phase',
          align: 'start',
          sortable: true,
          value: 'ResponsePhase',
        },
        {
          text: 'Deadline Phase 1',
          align: 'start',
          sortable: true,
          value: 'Deadline',
        },
        {
          text: 'Deadline Phase 2',
          align: 'start',
          sortable: true,
          value: 'Deadline2',
        },

        { text: 'Delete', value: 'actions', sortable: false },
      ],
      dialog: false,
      editedItem: {
        Name: '',
        Package: '',
        ResponsePhase: 1,
        Deadline: null,
        Deadline2: null,
      },
      defaultItem: {
        Name: '',
        Package: '',
        ResponsePhase: 1,
        Deadline: null,
        Deadline2: null,
      },
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'vendor') ? true : item.text != 'Delete';
      });
    },
  },
  methods: {
    save(item) {
      item
        .save()
        .then((saved) => handleSaveResponse(saved.Name, 'Vendor', 'updated'))
        .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('vendor')
        .create(this.editedItem)
        .then((saved) => handleSaveResponse(saved.Name, 'Vendor', 'added'))
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) => handleSaveResponse(saved.Name, 'Vendor', 'removed'))
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
