import vue from 'vue';
import { htmlToText } from 'html-to-text';

function handleSaveResponse(field, entity, type) {
  entity = entity[0].toUpperCase() + entity.slice(1);
  vue.toasted.global.success(
    `${htmlToText(entity)} ${htmlToText(field)} ${htmlToText(
      type
    )} successfully`
  );
}

function handleErrorResponse(error) {
  const getErrorObject = (error) =>
    Object.getOwnPropertyNames(error).reduce((acc, curr) => {
      acc[curr] = error[curr];
      return acc;
    }, {});
  vue.toasted.global.error(htmlToText(getErrorObject(error).message));
  // if (typeof error === 'string' || error instanceof String) {
  //   console.log('string');
  //   errorMessage = error;
  // } else if (
  //   Object.keys(error.errors).length === 0 &&
  //   error.errors.constructor === Object
  // ) {
  //   console.log('or here');
  //   errorMessage = error.message;
  // } else {
  //   console.log('here');
  //   error.errors.map((item) => {
  //     errorMessage += item.message + '\n';
  //   });
  // }
  // vue.toasted.global.error(htmlToText(errorMessage));
}

export { handleSaveResponse, handleErrorResponse };
