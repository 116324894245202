<template>
  <v-container
    ><FeathersVuexFind
      v-slot="{ items: specificationtypes }"
      service="specification-type"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="specificationtypes"
          :items-per-page="100"
          :sort-by="['Active', 'Code']"
          :sort-desc="[true, false]"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-if="$can('create', 'specification-type')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Specification Type</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Code"
                              label="Code"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Type"
                              label="Type"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Description1"
                              label="Description 1"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Description2"
                              label="Description 2"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Description3"
                              label="Description 3"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Description4"
                              label="Description 4"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Icon"
                              label="Icon"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-checkbox
                              v-model="editedItem.Active"
                              label="Active"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'specification-type')"
            v-slot:[`item.Code`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Code"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Code }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Code"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'specification-type')"
            v-slot:[`item.Type`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Type"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Type }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Type"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'specification-type')"
            v-slot:[`item.Description1`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Description1"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Description1 }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Description1"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'specification-type')"
            v-slot:[`item.Description2`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Description2"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Description2 }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Description2"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'specification-type')"
            v-slot:[`item.Description3`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Description3"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Description3 }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Description3"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'specification-type')"
            v-slot:[`item.Description4`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Description4"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Description4 }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Description4"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.Icon`]="props">
            <v-edit-dialog
              v-if="$can('update', 'specification-type')"
              :return-value.sync="props.item.Icon"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
              ><v-icon color="primary" small> {{ props.item.Icon }}</v-icon>

              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Icon"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
            <v-icon
              v-if="!$can('update', 'specification-type')"
              color="primary"
              small
            >
              {{ props.item.Icon }}</v-icon
            >
          </template>
          <template v-slot:[`item.Active`]="props">
            <v-edit-dialog
              v-if="$can('update', 'specification-type')"
              :return-value.sync="props.item.Active"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Active">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.Active"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span
              v-if="props.item.Active && !$can('update', 'specification-type')"
            >
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>
          <template
            v-if="$can('delete', 'specification-type')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section></FeathersVuexFind
    ></v-container
  >
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      headers: [
        {
          text: 'Code',
          align: 'start',
          sortable: true,
          value: 'Code',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'Type',
        },
        {
          text: 'Description 1',
          align: 'start',
          sortable: true,
          value: 'Description1',
        },
        {
          text: 'Description 2',
          align: 'start',
          sortable: true,
          value: 'Description2',
        },
        {
          text: 'Description 3',
          align: 'start',
          sortable: true,
          value: 'Description3',
        },
        {
          text: 'Description 4',
          align: 'start',
          sortable: true,
          value: 'Description4',
        },
        {
          text: 'Icon',
          align: 'start',
          sortable: true,
          value: 'Icon',
        },
        {
          text: 'Active',
          align: 'start',
          sortable: true,
          value: 'Active',
        },
        { text: 'Delete', value: 'actions', sortable: false },
      ],
      dialog: false,
      editedItem: {
        Code: '',
        Type: '',
        Description1: '',
        Description2: '',
        Description3: '',
        Description4: '',
        Icon: '',
        Active: false,
      },
      defaultItem: {
        Code: '',
        Type: '',
        Description1: '',
        Description2: '',
        Description3: '',
        Description4: '',
        Icon: '',
        Active: false,
      },
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'specification-type')
          ? true
          : item.text != 'Delete';
      });
    },
  },
  methods: {
    save(item) {
      item
        .save()
        .then((saved) =>
          handleSaveResponse(saved.Type, 'Specification Type', 'updated')
        )
        .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('specification-type')
        .create(this.editedItem)
        .then((saved) =>
          handleSaveResponse(saved.Type, 'Specification Type', 'added')
        )
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) =>
          handleSaveResponse(saved.Type, 'Specification Type', 'removed')
        )
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
