<template>
  <div>
    <v-dialog :value="true" persistent max-width="600px">
      <v-progress-linear
        v-if="isFindSpecificationPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindSpecificationPending">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">Add Variant to Specification</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="item['SpecificationId']"
                    :items="filteredSpecifications"
                    item-text="Description1"
                    item-value="id"
                    label="Specification"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                @click="$emit('save')"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  name: 'VariantAddSpecificationDialog',
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mixins: [makeFindMixin({ service: 'specification' })],
  computed: {
    specificationParams() {
      return {};
    },
    filteredSpecifications() {
      let list = [...this.specification];
      list = list.filter(
        (item) =>
          !item.variants.some((variant) => variant.id == this.item.VariantId)
      );
      return list.sort((a, b) => {
        if (a.Description1 > b.Description1) {
          return 1;
        }
        if (b.Description1 > a.Description1) {
          return -1;
        }
        return 0;
      });
    },
  },
};
</script>
