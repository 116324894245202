<template>
  <div>
    <v-icon class="mr-10 mt-7" @click="exportToExcel()" color="primary">
      mdi-microsoft-excel
    </v-icon>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { generateExcel } from '@/utils/ExportExcel';
export default {
  mixins: [
    makeFindMixin({ service: 'export-queries' }),
    makeFindMixin({ service: 'export-queries', name: 'jira' }),
    makeFindMixin({ service: 'export-queries', name: 'progressHistory' }),
  ],
  methods: {
    exportToExcel() {
      this.exportQueriesLatestQuery.response.forEach((i) => {
        i.Progress = parseFloat(i.Progress);
      });
      // console.log(new Date('2023-03-22').toLocaleDateString('nl-be'));
      this.progressHistoryLatestQuery.response.forEach((i) => {
        i.Progress = parseFloat(i.Progress);
        i.refreshdate = new Date(i.refreshdate).toLocaleDateString();
      });
      generateExcel(
        [
          {
            Progress: this.exportQueriesLatestQuery.response,
          },
          {
            ProgressHistory: this.progressHistoryLatestQuery.response,
          },

          {
            Tasks: this.jiraLatestQuery.response,
          },
        ],
        `Loxi Progress.xlsx`
      );
    },
  },
  computed: {
    exportQueriesParams() {
      return {
        query: {
          type: 'progress',
        },
      };
    },
    jiraParams() {
      return {
        query: {
          type: 'jira',
        },
      };
    },
    progressHistoryParams() {
      return {
        query: {
          type: 'progressHistory',
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
