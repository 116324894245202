<template>
  <div class="mt-10 mb-5">
    <v-row
      ><v-radio-group v-model="GraphType">
        <v-radio label="Global" value="Global"></v-radio>
        <v-radio label="Per Department" value="Department"></v-radio
        ><v-select
          :disabled="GraphType != 'Department'"
          :items="
            departmentLatestQuery?.response.data.filter(
              (d) => d.processes?.length > 0
            )
          "
          item-text="Name"
          item-value="id"
          outlined
          dense
          hide-details=""
          v-model="selectedDepartment"
        ></v-select>
      </v-radio-group>
    </v-row>
    <v-row><LineChart :options="chartOptions" :data="chartData" /></v-row>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { Line as LineChart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);
export default {
  mixins: [
    makeFindMixin({ service: 'department' }),
    makeFindMixin({
      service: 'export-queries',
      name: 'progressHistory',
      watch: 'selectedPhase',
    }),
    makeFindMixin({
      service: 'export-queries',
      name: 'progressHistoryDepartment',
      watch: ['selectedDepartment', 'selectedPhase'],
    }),
  ],
  data() {
    return {
      GraphType: 'Global',
      selectedPhase: null,
      selectedDepartment: null,
      chartOptions: {
        responsive: true,
      },
    };
  },

  // datasets: [
  //         {
  //           label: 'Progress',
  //           backgroundColor: '#f87979',
  //           data: this.progressData.progress,
  //         },
  //       ],
  //     };
  computed: {
    phaseQuery() {
      if (this.selectedPhase && this.selectedPhase != 'All') {
        return {
          query: {
            phase: this.selectedPhase,
          },
        };
      } else {
        return {};
      }
    },
    chartData() {
      let chartData = {
        labels: this.progressData.dates,
        datasets:
          this.GraphType == 'Global'
            ? this.progressData.dataset
            : this.progressDataDepartment.dataset,
        // [
        //   {
        //     label: 'Progress',
        //     backgroundColor: '#f87979',
        //     data: this.progressData.progress,
        //   },
        //   {
        //     label: 'Progress Department',
        //     backgroundColor: '#000000',
        //     data: this.progressDataDepartment.progress,
        //   },
        // ],
      };
      return chartData;
    },

    progressData() {
      const dates = [];
      let data = [];
      if (
        this.progressHistoryDepartmentLatestQuery &&
        this.progressHistoryDepartmentLatestQuery.response
      ) {
        for (const obj of this.progressHistoryLatestQuery.response) {
          dates.push(new Date(obj.refreshdate).toLocaleDateString());
          data.push(obj.Progress);
        }
      }
      let dataset = [
        { label: 'Progress Global', backgroundColor: '#f87979', data: data },
      ];

      return { dates, dataset };
    },

    progressDataDepartment() {
      const dates = [];
      let data = [];
      let result = [];
      if (
        this.progressHistoryDepartmentLatestQuery &&
        this.progressHistoryDepartmentLatestQuery.response
      ) {
        if (this.selectedDepartment)
          result = this.progressHistoryDepartmentLatestQuery.response.filter(
            (f) => f.DepartmentId == this.selectedDepartment
          );
        else result = [];

        for (const obj of result) {
          dates.push(new Date(obj.refreshdate).toLocaleDateString());
          data.push(obj.Progress);
        }
      }

      let dataset = [
        {
          label: this.departmentLatestQuery.response.data.find(
            (f) => f.id == this.selectedDepartment
          )?.Name,
          backgroundColor: '#f81979',
          data: data,
        },
      ];
      return { dates, dataset };
    },

    departmentParams() {
      return { query: { detail: true } };
    },
    progressHistoryParams() {
      if (this.phaseQuery?.query) {
        let query = Object.assign({}, this.phaseQuery);

        query.query.type = 'progressHistory';
        return query;
      } else return { query: { type: 'progressHistory' } };
    },
    progressHistoryDepartmentParams() {
      if (this.phaseQuery?.query) {
        let query = Object.assign({}, this.phaseQuery);

        query.query.type = 'progressHistoryDepartment';
        if (this.selectedDepartment)
          query.query.selectedDepartment = this.selectedDepartment;

        return query;
      } else return { query: { type: 'progressHistoryDepartment' } };
    },
  },
  components: { LineChart },
  mounted() {
    this.selectedPhase = window.localStorage.getItem('phase')
      ? parseInt(window.localStorage.getItem('phase'))
      : 'All';
  },
};
</script>

<style lang="scss" scoped></style>
