<template>
  <v-container>
    <h1>Field List</h1>
    <FieldList />
  </v-container>
</template>

<script>
import FieldList from '@/components/Fields/FieldList.vue';

export default {
  components: { FieldList },
};
</script>

<style lang="scss" scoped></style>
