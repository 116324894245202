<template>
  <span>
    <span v-if="statusList && statusList.length > 0">
      <span v-for="(stat, index) in statusList" :key="index">
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="ma-2"
                :color="stat.Color"
                x-small
                v-bind="attrs"
                v-on="on"
                :text-color="applyDark(stat.Color) ? 'white' : 'black'"
              >
                {{ stat.Count }}
              </v-chip>
            </template>
            <span>{{ stat.Status }}</span>
          </v-tooltip>
        </span>
      </span>
    </span>
    <span v-else
      ><v-progress-circular
        indeterminate
        :size="15"
        :width="1"
      ></v-progress-circular
    ></span>
  </span>
</template>

<script>
// import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
export default {
  data() {
    return {};
  },
  props: ['statusList'],
  methods: {
    applyDark,
  },
};
</script>

<style></style>
