<template>
  <v-container>
    <div id="file-drag-drop">
      <form ref="fileform">
        <span class="drop-files">Drop the files here!</span>
      </form>

      <v-progress-linear
        class="mt-10"
        width="500"
        color="secondary"
        height="25"
        max="100"
        :value="uploadPercentage"
        ><template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template></v-progress-linear
      >
      <div v-for="(file, key) in files" class="file-listing" :key="key">
        <img class="preview" v-bind:ref="'preview' + parseInt(key)" />

        {{ file.name }}
        <div class="“remove-container”">
          <v-icon v-on:click="removeFile(key)" color="primary" small
            >mdi-trash-can</v-icon
          >
        </div>
        <div v-if="message[key]">{{ message[key] }}</div>
      </div>
      <v-btn color="primary" v-on:click="submitFiles()" v-if="files.length > 0"
        >Submit</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dragAndDropCapable: false,
      uploadPercentage: 0,
      files: [],
      message: [],
    };
  },
  methods: {
    determineDragAndDropCapable() {
      var div = document.createElement('div');

      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (/.(jpe?g|png|gif|svg)$/i.test(this.files[i].name)) {
          let reader = new FileReader();

          reader.addEventListener(
            'load',
            function() {
              this.$refs['preview' + parseInt(i)][0].src = reader.result;
            }.bind(this),
            false
          );

          reader.readAsDataURL(this.files[i]);
        } else {
          this.$nextTick(function() {
            this.$refs['preview' + parseInt(i)][0].src = '/images/file.png';
          });
        }
      }
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    async submitFiles() {
      let bodyFormData = new FormData();

      const api_key = '994347183549514';
      const api_secret = 'tWAnfK3Jylq1TuzlZThi6aZm0C0';

      const toBase64 = file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        let fileencoded = await toBase64(file);
        let timestamp = Math.floor(Date.now() / 1000);
        let filename = `${
          this.$appConfig.customer.prefix
        }/${file.name.substring(0, file.name.lastIndexOf('.')) || file.name}`;

        let signature = `public_id=${filename}&timestamp=${timestamp}${api_secret}`;
        const buffer = new TextEncoder('utf-8').encode(signature);
        let sha1 = await crypto.subtle.digest('SHA-1', buffer);
        let result = Array.from(new Uint8Array(sha1))
          .map(x => x.toString(16).padStart(2, '0'))
          .join('');

        bodyFormData.append('public_id', filename);
        bodyFormData.append('timestamp', timestamp);
        bodyFormData.append('api_key', api_key);
        bodyFormData.append('signature', result);
        bodyFormData.append('file', fileencoded);

        let url = `https://api.cloudinary.com/v1_1/foocus/image/upload`;
        try {
          await axios.post(
            url,
            bodyFormData,

            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          this.message.push('Succes');
        } catch (error) {
          this.message.push('Failed');
        }

        this.uploadPercentage = Math.round(((i + 1) / this.files.length) * 100);
      }
      this.files = [];
      this.message = [];
    },
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if (this.dragAndDropCapable) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach(
        function(evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();

              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      this.$refs.fileform.addEventListener(
        'drop',
        function(e) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.files.push(e.dataTransfer.files[i]);
          }
          this.getImagePreviews();
        }.bind(this)
      );
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  display: block;

  height: 400px;

  width: 400px;

  background: #ccc;

  margin: auto;

  margin-top: 40px;

  text-align: center;

  line-height: 400px;

  border-radius: 4px;
}

div.file-listing {
  width: 400px;

  margin: auto;

  padding: 10px;

  border-bottom: 1px solid #ddd;
}

div.file-listing img {
  height: 100px;
}

div.remove-container {
  text-align: center;
}

div.remove-container a {
  color: red;

  cursor: pointer;
}

a.submit-button {
  display: block;

  margin: auto;

  text-align: center;

  width: 200px;

  padding: 10px;

  text-transform: uppercase;

  background-color: #ccc;

  color: white;

  font-weight: bold;

  margin-top: 20px;
}
</style>
