var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog && this.item)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function({ clone, save, reset, remove }){return [(_vm.showDialog)?_c('TestScenarioEditDialog',{attrs:{"item":clone,"stepsToAdd":_vm.stepsToAdd,"saving":_vm.saving,"addModus":_vm.currentItem ? false : true},on:{"save":function($event){_vm.saving = true;
        _vm.saveItem(save);},"reset":reset,"remove":function($event){remove()
          .then(async (saved) => {
            _vm.handleSaveResponse(saved.Name, 'test scenario', 'removed');
            _vm.showDialog = false;
          })
          .catch(async (error) => {
            _vm.handleErrorResponse(error);
          })},"cancel":function($event){_vm.showDialog = false},"updateSteps":_vm.updateSteps,"close":_vm.close}}):_vm._e()]}}],null,false,2460925957)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }