<template>
  <v-container>
    <h1>Loxi a tool by Foocus bv</h1>
    &copy; {{ currentYear }}
    <v-card class="mx-auto mt-10" max-width="400" tile>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Version</v-list-item-title>
          <v-list-item-subtitle>
            {{ $store.getters.appVersion }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Backend</v-list-item-title>
          <v-list-item-subtitle>{{
            $appConfig.apiBaseUrl
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div v-if="$feature('lucidChartIntegration')" align="center" class="mt-5">
        <v-btn color="primary" :href="LucidChartUrl"
          >Link Global Lucid Chart Account</v-btn
        >
      </div>
      <v-card-actions>
        <v-spacer />
        <v-img class="mt-10" width="200" src="/logos/LoxiAnimated.gif"></v-img
        ><v-spacer
      /></v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';

export default {
  mixins: [makeFindMixin({ service: 'tokens' })],
  data() {
    return {};
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    tokensParams() {
      return {};
    },
    LucidChartUrl() {
      if (this.tokens) {
        let lucidToken = this.tokens.find(
          (f) => f.Application == 'LucidChartAccount'
        );
        if (lucidToken) {
          //return `https://lucid.app/oauth2/authorizeUser?client_id=${lucidToken.ClientId}&redirect_uri=${lucidToken.RedirectUri}&scope=${lucidToken.Scope}&state=${this.user.email}`;
          // return `https://lucid.app/oauth2/authorizeAccount?client_id=${lucidToken.ClientId}&redirect_uri=${lucidToken.RedirectUri}&scope=${lucidToken.Scope}&state=account`;
          return `https://lucid.app/oauth2/authorizeUser?client_id=${lucidToken.ClientId}&redirect_uri=${lucidToken.RedirectUri}&scope=${lucidToken.Scope}&state=account`;
        } else {
          return null;
        }
      } else return null;
    },
  },
};
</script>

<style></style>
