<template>
  <v-dialog v-model="showDialog" width="1000" height="500">
    <v-card flat>
      <v-card-title>
        <span class="headline">Add Field Mappings for {{ item.Type }}</span
        ><v-spacer></v-spacer>
      </v-card-title>
      <section>
        <v-data-table
          :headers="headers"
          :items="item.issue_type_fields"
          :items-per-page="20"
          :sort-by="['Field']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          disable-pagination
          :hide-default-footer="true"
          :loading="isFindIssueTypePending"
          loading-text="Loading... Please wait"
          ><template v-if="$can('create', 'issue-type-fields')" v-slot:footer>
            <v-card
              ><v-card-actions
                ><v-select
                  v-model="selectedType"
                  :items="issueType"
                  item-text="Type"
                  item-value="id"
                  label="Issue Type"
                  outlined
                  dense
                  hide-details="true"
                  ><template v-slot:item="{ item }">
                    <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                    {{ item.Type
                    }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                      >mdi-connection</v-icon
                    >
                  </template>
                  <template #selection="{ item }">
                    <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                    {{ item.Type
                    }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                      >mdi-connection</v-icon
                    >
                  </template></v-select
                ><v-btn
                  color="primary"
                  @click="copyFields"
                  :disabled="!selectedType"
                  >Copy From</v-btn
                ><v-spacer></v-spacer
                ><v-btn color="primary" @click="syncFields"
                  >Sync fields</v-btn
                ></v-card-actions
              ></v-card
            >
          </template>
          <template
            v-if="$can('update', 'issue-type-fields')"
            v-slot:[`item.FollowUrl`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.FollowUrl"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.FollowUrl }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.FollowUrl"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type-fields')"
            v-slot:[`item.ExternalField`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.ExternalField"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.ExternalField }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.ExternalField"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type-fields')"
            v-slot:[`item.DefaultValue`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.DefaultValue"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.DefaultValue }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.DefaultValue"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type-fields')"
            v-slot:[`item.Create`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Create"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Create }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.Create"
                  :items="SyncTypes"
                  label="Edit"
                  clearable
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type-fields')"
            v-slot:[`item.Update`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Update"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Update }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.Update"
                  :items="SyncTypes"
                  label="Edit"
                  clearable
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';
import { IssueFieldsToMap, SyncTypes } from '@/utils/issues/Globals';
export default {
  props: {
    value: { type: Boolean },
    item: {},
  },
  mixins: [makeFindMixin({ service: 'issue-type' })],
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      dialog: false,
      validInput: false,
      editedItem: {},
      selectedType: null,
      SyncTypes,
    };
  },
  methods: {
    async save(item) {
      console.log(item);
      try {
        await feathersClient.service('issue-type-fields').patch(item.id, {
          ExternalField: item.ExternalField,
          FollowUrl: item.FollowUrl,
          DefaultValue: item.DefaultValue,
          Create: item.Create,
          Update: item.Update,
        });
        handleSaveResponse(item.id, 'External Issue Field', 'updated');
      } catch (error) {
        handleErrorResponse(error);
      }
      await this.Refresh();
    },
    open() {},
    cancel() {},
    close() {},
    Close() {
      this.dialog = false;
      this.validInput = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    async Refresh() {
      const { IssueType } = this.$FeathersVuex.api;
      await IssueType.find({
        query: {},
      });
    },
    async copyFields() {
      const result = await feathersClient
        .service('issue-type-fields')
        .find({ query: { IssueTypeId: this.selectedType } });

      if (result.total > 0) {
        for (const field of this.item.issue_type_fields) {
          let fieldValue = result.data.find((f) => f.Field == field.Field);

          await feathersClient.service('issue-type-fields').patch(field.id, {
            ExternalField: fieldValue.ExternalField,
            FollowUrl: fieldValue.FollowUrl,
            DefaultValue: fieldValue.DefaultValue,
            Create: fieldValue.Create,
            Update: fieldValue.Update,
          });
        }
      }
      await this.Refresh();
      this.selectedType = null;
    },
    async syncFields() {
      // auto create remove all the fields defined in the global IssueFieldsToMap

      const filteredFieldsToMap = IssueFieldsToMap.filter(
        (field) =>
          !this.item.issue_type_fields.some((item) => item.Field === field)
      );

      for (const field of filteredFieldsToMap) {
        await feathersClient
          .service('issue-type-fields')
          .create({ Field: field, IssueTypeId: this.item.id });
      }

      const filteredFieldsToRemove = this.item.issue_type_fields.filter(
        (item) =>
          !IssueFieldsToMap.includes(item.Field) &&
          item.IssueTypeId == this.item.id
      );

      for (const field of filteredFieldsToRemove) {
        let result = await feathersClient
          .service('issue-type-fields')
          .find({ query: { Field: field.Field, IssueTypeId: this.item.id } });

        await feathersClient
          .service('issue-type-fields')
          .remove(result.data[0].id);
      }
      await this.Refresh();
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    issueTypeParams() {
      return { query: { id: { $ne: this.item.id } } };
    },
    headers() {
      return [
        {
          text: 'Field',
          align: 'start',
          sortable: true,
          value: 'Field',
        },
        {
          text: 'Follow Url',
          align: 'start',
          sortable: true,
          value: 'FollowUrl',
        },
        {
          text: 'External Field',
          align: 'start',
          sortable: true,
          value: 'ExternalField',
        },
        {
          text: 'Default Value',
          align: 'start',
          sortable: true,
          value: 'DefaultValue',
        },
        {
          text: 'Create',
          align: 'start',
          sortable: true,
          value: 'Create',
        },
        {
          text: 'Update',
          align: 'start',
          sortable: true,
          value: 'Update',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
