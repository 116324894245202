<template>
  <v-container>
    <h3>
      Import Process
      <span v-if="embedToken">
        <v-icon @click="openWindow(LucidUrl)" class="mb-1 ml-2">
          mdi-image-search-outline
        </v-icon></span
      >
    </h3>

    <ShowPicker v-if="!documentInfo" @retrievedDocument="GetDocument" />
    <v-stepper v-if="documentInfo" v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1"> Process </v-stepper-step>

      <v-stepper-content step="1">
        <ImportProcess
          v-if="e6 == 1"
          :documentInfo="documentInfo"
          :documentParsed="documentParsed"
          @next="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2"> Variants </v-stepper-step>

      <v-stepper-content step="2">
        <ImportVariant
          v-if="e6 == 2"
          :documentParsed="documentParsed"
          :process="process"
          @next="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Process Steps
      </v-stepper-step>

      <v-stepper-content step="3">
        <ImportStep
          v-if="e6 == 3"
          :documentParsed="documentParsed"
          :process="process"
          :variants="variants"
          @next="nextStep"
        />
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import ShowPicker from '@/components/DocumentPicker/ShowPicker';
import ImportProcess from '@/components/DocumentPicker/ImportProcess';
import ImportVariant from '@/components/DocumentPicker/ImportVariant';
import ImportStep from '@/components/DocumentPicker/ImportStep';

import {
  getDocumentEmbed,
  getDocumentEmbedToken,
} from '@/utils/LucidChartFunctions';

export default {
  components: { ShowPicker, ImportProcess, ImportVariant, ImportStep },
  data() {
    return {
      e6: 1,
      documentInfo: null,
      documentParsed: null,
      process: null,
      variants: null,
      embedToken: null,
    };
  },
  computed: {
    LucidUrl() {
      return this.process
        ? `https://lucid.app/documents/${this.process.ExternalDocumentId}/viewer?token=${this.embedToken}`
        : null;
    },
  },
  methods: {
    GetDocument({ documentInfo, documentParsed }) {
      this.documentInfo = documentInfo;
      this.documentParsed = documentParsed;
    },
    async LucidEmbed(data) {
      if (data && data.ExternalDocumentId) {
        if (!this.process.EmbedId) {
          await getDocumentEmbed(data);
        }
        this.embedToken = await getDocumentEmbedToken(data);
      }
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    async nextStep({ type, data }) {
      this.e6 = this.e6 + 1;
      // let pages = null;
      switch (type) {
        case 'process':
          this.process = data;
          await this.LucidEmbed(data);
          // Remobve selected pages from array
          // pages = this.process.PageIndexes.split('');
          // pages.forEach((p) => {
          //   this.documentParsed.splice(parseInt(p) - 1, 1);
          // });
          break;
        case 'variant':
          this.variants = data;
          break;
        case 'step':
          setTimeout(() => {
            this.documentInfo = null;
            this.documentParsed = null;
            this.process = null;
            this.variants = null;
            this.e6 = 1;
          }, 1000);
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped></style>
