<template>
  <v-container>
    <h1>
      {{ user.vendor.Name }} ({{ user.vendor.Package }}) - Responses to RFP
      <span>(Phase {{ user.vendor.ResponsePhase }})</span>
    </h1>
    <h3
      v-if="user.vendor.Deadline && user.vendor.ResponsePhase == 1"
      :style="
        checkDate(user.vendor.Deadline) ? { 'background-color': '#FFCDD2' } : {}
      "
    >
      Response expected before
      {{
        user.vendor.Deadline
          ? new Date(user.vendor.Deadline).toLocaleString([], {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          : null
      }}
    </h3>
    <h3
      v-if="user.vendor.Deadline2 && user.vendor.ResponsePhase == 2"
      :style="
        checkDate(user.vendor.Deadline2)
          ? { 'background-color': '#FFCDD2' }
          : {}
      "
    >
      Response expected before
      {{
        user.vendor.Deadline2
          ? new Date(user.vendor.Deadline2).toLocaleString([], {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          : null
      }}
    </h3>
    <ResponseOverview />
    <v-tabs v-model="tab" active-class="activeTab">
      <v-tab>1. ProcessList</v-tab>
      <v-tab>2. Specifications</v-tab>
      <!-- <v-tab>3. Budget</v-tab> -->
      <v-tab>3. Send Response</v-tab>
      <v-tab>Explanation</v-tab>
    </v-tabs>
    <v-tabs-items class="mb-5" v-model="tab">
      <v-tab-item>
        <ProcessList />
      </v-tab-item>
      <v-tab-item> <SpecificationList /> </v-tab-item>
      <!-- <v-tab-item> Not yet available </v-tab-item> -->
      <v-tab-item> <SendResponse /></v-tab-item>
      <v-tab-item>
        <!-- <video controls width="960" height="540" class="mt-5">
          <source
            src="https://res.cloudinary.com/foocus/video/upload/v1663878664/General/LoxiUitlegFase2_720p_drmsnr.mp4"
            type="video/mp4"
          />
        </video> -->
        <div v-if="user.vendor.ResponsePhase == 1" class="mt-5">
          <p>
            <LazyYoutube
              maxWidth="800"
              thumbnailQuality="maxres"
              src="https://youtu.be/pXjoBRDiOsg"
            />
          </p>
          <v-icon>mdi-file-pdf-box</v-icon
          ><a href="/downloads/LoxiExplanationPhase1.pdf" target="_blank"
            >Download Explanation Phase 1</a
          >
        </div>
        <div v-if="user.vendor.ResponsePhase == 2" class="mt-5">
          <p>
            <LazyYoutube
              maxWidth="800"
              thumbnailQuality="maxres"
              src="https://youtu.be/l12sr_3X8t4"
            />
          </p>
          <v-icon>mdi-file-pdf-box</v-icon
          ><a href="/downloads/LoxiExplanationPhase2.pdf" target="_blank"
            >Download Explanation Phase 2</a
          >
        </div>

        <div v-if="SelectieUrl">
          <v-icon>mdi-file-pdf-box</v-icon
          ><a :href="SelectieUrl" target="_blank">Procedure Selection</a>
        </div>
        <div v-if="BusinessCaseUrl">
          <v-icon>mdi-file-pdf-box</v-icon
          ><a :href="BusinessCaseUrl" target="_blank">Business Case</a>
        </div>
        <div v-if="processListUrl">
          <v-icon>mdi-file-pdf-box</v-icon
          ><a :href="processListUrl" target="_blank">Full Process List</a>
        </div>
        <div v-if="e2eUrl">
          <v-icon>mdi-file-pdf-box</v-icon
          ><a :href="e2eUrl" target="_blank">End 2 End Flows</a>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { LazyYoutube } from 'vue-lazytube';
import ProcessList from '@/components/VendorResponse/ProcessList.vue';
import SpecificationList from '@/components/VendorResponse/SpecificationList.vue';
import SendResponse from '@/components/VendorResponse/SendResponse.vue';
import ResponseOverview from '@/components/VendorResponse/ResponseOverview.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  components: {
    LazyYoutube,
    ProcessList,
    SpecificationList,
    SendResponse,
    ResponseOverview,
  },
  data() {
    return {
      tab: 0,
      processListUrl: '',
      e2eUrl: '',
      BusinessCaseUrl: '',
      SelectieUrl:
        'https://loxi.eu-central-1.linodeobjects.com/SelectieSoftwarepakketFoocus.pdf',
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    checkDate(date) {
      if (this.user) {
        let deadline = new Date(date);
        let today = new Date();
        return today > deadline;
      }
      return false;
    },
    async fileExists(url) {
      var result = false;
      result = await axios
        .head(url)
        .then((res) => res.status == 200)
        .catch(() => false);
      // console.log(url, result);
      return result;
    },
  },
  async mounted() {
    let url = `https://loxi.eu-central-1.linodeobjects.com/${this.$appConfig.customer.prefix}.FullProcessList.pdf`;
    // let url = `https://loxi.eu-central-1.linodeobjects.com/ARP.FullProcessList.pdf`;
    this.processListUrl = (await this.fileExists(url)) ? url : '';
    url = `https://loxi.eu-central-1.linodeobjects.com/${this.$appConfig.customer.prefix}.E2EFlows.pdf`;
    // url = `https://loxi.eu-central-1.linodeobjects.com/ARP.E2EFlows.pdf`;
    this.e2eUrl = (await this.fileExists(url)) ? url : '';
    url = `https://loxi.eu-central-1.linodeobjects.com/${this.$appConfig.customer.prefix}.BusinessCase.pdf`;
    this.BusinessCaseUrl = (await this.fileExists(url)) ? url : '';
  },
};
</script>

<style lang="scss" scoped></style>
