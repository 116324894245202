import axios from 'axios';
import feathersClient from '@/feathers-client';
import vue from 'vue';
import init, { convert } from 'htmltoadf';

import { config } from '@/config';
import store from '@/store';
import { calculatedSeverity, getField, getFieldValue } from './GetTaskFields';

/*
Fields to map:
 - Blocking:
    - Blocking
    - Non Blocking


 


*/

async function getToken() {
  let appInfo = null;
  try {
    appInfo = await feathersClient
      .service('tokens')
      .find({ query: { Application: 'JiraAPI' } });
  } catch (error) {
    console.log('Error getting appInfo');
  }

  return appInfo;
}

async function getUserToken() {
  let userInfo = null;
  let appInfo = await getToken();

  if (appInfo && appInfo.total == 1) {
    try {
      userInfo = await feathersClient.service('tokens-user').find({
        query: {
          TokenUserId: store.getters['auth/user'].id,
          tokenId: appInfo.data[0].id,
        },
      });
    } catch (error) {
      console.log('Error getting userInfo');
    }
  }

  if (userInfo && userInfo.total == 1) {
    return userInfo.data[0];
  } else {
    return '';
  }
}

async function getHeader() {
  let userInfo = await getUserToken();

  let config = {
    headers: {
      Authorization: `Bearer  ${userInfo.AccessToken}`,
      'Content-Type': 'Application/json',
    },
  };
  return config;
}

async function CreateWorkItem(
  title,
  type,
  blocking,
  content1,
  content2,
  variant
) {
  //Convert to ADF
  await init();
  const convertedContent = JSON.parse(convert(content1));

  let url = `https://api.atlassian.com/ex/jira/${config.Jira.cloudId}/rest/api/3/issue`;

  let body = {
    fields: {
      summary: title,
      description: convertedContent,
      project: { key: `${config.Jira.project}` },
      issuetype: { name: await getFieldValue('Jira', 'Type', type) },
    },
  };

  // BlockingField
  let BlockingField = await getField('Jira', 'BlockingField');
  if (BlockingField) {
    // Blocking
    let Severity = await calculatedSeverity(blocking, 'Jira');
    if (Severity) {
      body.fields[BlockingField] = { name: Severity.toString() };
    }
  }

  // Department
  let DepartmentField = await getField('Jira', 'DepartmentField');
  if (
    variant &&
    variant.process &&
    variant.process.department &&
    DepartmentField
  ) {
    body.fields[DepartmentField] = {
      value: variant.process.department.ERPModule,
    };
  }

  // pqrent issue
  if (variant.process.IssueTrackingId) {
    body.fields['parent'] = { key: variant.process.IssueTrackingId };
  }

  // Area
  // let Area = await getField('DevOps', 'Area');
  // if (Area) {
  //   body.push({
  //     op: 'add',
  //     path: '/fields/System.AreaPath',
  //     from: null,
  //     value: Area,
  //   });
  // }

  //Iteration
  // let Iteration = await getField('DevOps', 'Iteration');
  // if (Iteration) {
  //   body.push({
  //     op: 'add',
  //     path: '/fields/System.IterationPath',
  //     from: null,
  //     value: Iteration,
  //   });
  // }

  // console.log(body);
  let result = null;
  let response = null;
  try {
    result = await axios.post(url, body, await getHeader());
    if (result.status == 201) {
      const link = `https://${config.Jira.organisation}.atlassian.net/browse/${result.data.key}`;
      response = {
        id: result.data.key,
        link: link,
      };
    } else vue.toasted.global.error('Error creating Task');
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      console.log('Login failed?');
      //userefresh and retry
      await useRefresToken();
      try {
        result = await axios.post(url, body, await getHeader());
        if (result.status == 201) {
          const link = `https://${config.Jira.organisation}.atlassian.net/browse/${result.data.key}`;
          response = {
            id: result.data.key,
            link: link,
          };
        } else {
          console.log('redirect');
          // window.location.replace('https://www.foocus.be');
        }
      } catch (error) {
        console.log('redirect');
        // window.location.replace('https://www.foocus.be');
      }
    }
  }

  return response;
}

async function useRefresToken() {
  console.log('Reauthenticate');

  let appInfo = await getToken();
  let userInfo = await getUserToken();

  let url = 'https://auth.atlassian.com/oauth/token';
  const body = {
    client_id: appInfo.data[0].ClientId,
    client_secret: appInfo.data[0].ClientSecret,
    grant_type: 'refresh_token',
    refresh_token: userInfo.RefreshToken,
  };

  const options = {
    method: 'POST',
    data: body,
    url,
  };

  let accessToken = await axios(options);

  try {
    await feathersClient.service('tokens-user').patch(userInfo.id, {
      RefreshToken: accessToken.data.refresh_token,
      AccessToken: accessToken.data.access_token,
    });
  } catch (error) {
    console.log('Error saving new acces token: ', error);
  }
}

export { CreateWorkItem };
