<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog && item">
    <template v-slot="{ clone, save, reset, remove }">
      <IssueEditDialog
        :item="clone"
        :saving="saving"
        @save="saveIssue(save)"
        @reset="reset"
        @remove="
          saving = true;
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Title, 'issue', 'removed');
              await refresh();
              showDialog = false;
              saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
              saving = false;
            });
        "
        @cancel="showDialog = false"
      ></IssueEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import { CreateIssue } from '@/utils/issues/CreateExternalIssue';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import IssueEditDialog from './IssueEditDialog.vue';

export default {
  name: 'IssueEdit',
  components: {
    FeathersVuexFormWrapper,
    IssueEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  watch: {
    currentItem() {
      const { ExternalIssues } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = this.currentItem;
      } else {
        let newIssue = new ExternalIssues();
        newIssue.TestScenarioRunStepId = this.testScenarioRunStep?.id
          ? this.testScenarioRunStep?.id
          : null;
        newIssue.ProcessStepId = this.processStep?.id
          ? this.processStep.id
          : this.testScenarioRunStep?.id
          ? this.testScenarioRunStep.process_step.id
          : null;
        newIssue.VariantId = this.processStep?.variant?.id
          ? this.processStep.variant.id
          : this.variant?.id
          ? this.variant.id
          : this.testScenarioRunStep?.process_step?.VariantId
          ? this.testScenarioRunStep.process_step.VariantId
          : null;
        newIssue.ProcessId = this.processStep?.variant?.process?.id
          ? this.processStep.variant.process.id
          : this.variant?.process?.id
          ? this.variant.process.id
          : this.process?.id
          ? this.process.id
          : this.testScenarioRunStep?.process_step?.variant?.ProcessId
          ? this.testScenarioRunStep.process_step.variant.ProcessId
          : null;
        this.item = newIssue;
      }
    },
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    process: { type: Object, required: false },
    variant: { type: Object, required: false },
    processStep: { type: Object, required: false },
    testScenarioRunStep: { type: Object, required: false },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    CreateIssue,
    async refresh() {
      const {
        ExternalIssues,
        Status,
        ProcessStep,
        TestScenarioRunSteps,
        Variant,
        Process,
      } = this.$FeathersVuex.api;
      await ProcessStep.find({ query: {} });
      await ExternalIssues.find({
        query: { ProcessStepId: this.processStep?.id },
        include: {
          model: Status,
        },
      });
      if (this.variant) {
        await Variant.get(this.variant.id);
      }
      if (this.process) {
        await Process.get(this.process.id);
      }
      if (this.testScenarioRunStep) {
        await TestScenarioRunSteps.get(this.testScenarioRunStep.id);
      }
    },
    async saveIssue(save) {
      this.saving = true;
      try {
        let savedIssue = await save();
        this.handleSaveResponse(
          savedIssue.Title,
          'issue',
          this.currentItem ? 'updated' : 'created'
        );

        if (this.$appConfig.externalIssues?.length > 0) {
          savedIssue.ExternalId != null
            ? await CreateIssue(savedIssue, 'patch')
            : await CreateIssue(savedIssue, 'post');
        }
        await this.refresh();
        this.showDialog = false;
        this.saving = false;
      } catch (error) {
        this.handleErrorResponse(error);
        await this.refresh();
        this.saving = false;
      }
    },
  },
  mounted() {
    const { ExternalIssues } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = this.currentItem;
    } else {
      let newIssue = new ExternalIssues();
      newIssue.TestScenarioRunStepId = this.testScenarioRunStep?.id
        ? this.testScenarioRunStep?.id
        : null;
      newIssue.ProcessStepId = this.processStep?.id
        ? this.processStep.id
        : this.testScenarioRunStep?.id
        ? this.testScenarioRunStep.process_step.id
        : null;
      newIssue.VariantId = this.processStep?.variant?.id
        ? this.processStep.variant.id
        : this.variant?.id
        ? this.variant.id
        : this.testScenarioRunStep?.process_step?.VariantId
        ? this.testScenarioRunStep.process_step.VariantId
        : null;
      newIssue.ProcessId = this.processStep?.variant?.process?.id
        ? this.processStep.variant.process.id
        : this.variant?.process?.id
        ? this.variant.process.id
        : this.process?.id
        ? this.process.id
        : this.testScenarioRunStep?.process_step?.variant?.ProcessId
        ? this.testScenarioRunStep.process_step.variant.ProcessId
        : null;
      this.item = newIssue;
    }
  },
};
</script>
