<template>
  <v-container>
    <v-row
      ><v-col cols="12" md="4">
        <v-select
          v-model="selectedProjectRole"
          :items="projectRoles"
          item-text="Role"
          item-value="id"
          label="Project Role"
          outlined
          dense
          hide-details
          @change="getTreeData"
        ></v-select> </v-col
      ><v-col cols="12" md="4">
        <v-select
          v-model="selectedProjectRoleUser"
          :items="users"
          item-text="fullname"
          item-value="id"
          label="Project Role User"
          outlined
          dense
          hide-details
          @change="changeProjectRoleUser"
        ></v-select></v-col
    ></v-row>
    <v-row
      ><v-col>
        <ejs-treegrid
          ref="ProjectRoleTree"
          v-if="data && users"
          :editSettings="editSettings"
          :enableCollapseAll="true"
          :dataSource="data"
          childMapping="children"
          :treeColumnIndex="0"
          :actionComplete="actionComplete"
          :enablePersistence="false"
        >
          <e-columns>
            <e-column
              field="Name"
              headerText="Process"
              width="90"
              textAlign="Left"
              :isPrimaryKey="true"
            ></e-column>
            <e-column
              field="InheritedUser"
              headerText="Actual User"
              width="90"
              textAlign="Left"
            ></e-column>
            <e-column
              field="User"
              headerText="User"
              width="90"
              textAlign="Left"
              editType="stringedit"
              :edit="userParams"
            ></e-column>
            <e-column
              headerText="Commands"
              :commands="commands"
              width="50"
            ></e-column>
          </e-columns> </ejs-treegrid></v-col
    ></v-row>
  </v-container>
</template>

<script>
import feathersClient from '../../feathers-client';
import Vue from 'vue';
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  CommandColumn,
} from '@syncfusion/ej2-vue-treegrid';
import { closest } from '@syncfusion/ej2-base';
import {
  MultiSelectPlugin,
  MultiSelect,
  CheckBoxSelection,
} from '@syncfusion/ej2-vue-dropdowns';
// import { getValue } from '@syncfusion/ej2-base';

let elem;
let multiSelectObj;
Vue.use(MultiSelectPlugin);
// import { Query } from '@syncfusion/ej2-data';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
MultiSelect.Inject(CheckBoxSelection);
export default {
  provide: {
    treegrid: [Edit, CommandColumn],
  },
  data() {
    const vm = this;
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      data: null,
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
      },
      commands: [
        {
          buttonOption: {
            content: 'Details',
            cssClass: 'e-flat',
            click: this.addUsers,
          },
        },
      ],
      projectRoles: null,
      selectedProjectRole: 2,
      users: null,
      selectedUser: null,
      selectedProjectRoleUser: null,
      projectUsers: null,
      userParams: {
        create: function () {
          elem = document.createElement('input');
          return elem;
        },
        read: function () {
          return multiSelectObj.value;
        },
        destroy: function () {
          multiSelectObj.destroy();
        },
        write: function (args) {
          console.log(args);
          multiSelectObj = new MultiSelect({
            dataSource: vm.users,
            fields: { text: 'fullname', value: 'id' },
            //fields: { text: 'taskName', value: 'taskName'},

            // value: Array.isArray(args.rowData['User'])
            //   ? args.rowData['User']
            //   : [args.rowData['User']],
          });
          multiSelectObj.appendTo(elem);
        },
      },
    };
  },
  components: {
    'ejs-treegrid': TreeGridComponent,
    'e-column': ColumnDirective,
    'e-columns': ColumnsDirective,
  },
  computed: {},
  methods: {
    addUsers: function (args) {
      let rowIndex = closest(args.target, '.e-row').rowIndex;
      let data =
        this.$refs.ProjectRoleTree.ej2Instances.getCurrentViewRecords();
      alert(JSON.stringify(data[rowIndex]));
    },
    async actionComplete(args) {
      if (args.type == 'save' && args.data) {
        console.log(args);

        let roleMapping = await feathersClient
          .service('project-role-mapping')
          .find({
            query: {
              DepartmentId: args.data?.DepartmentId,
              ProcessId: args.data?.ProcessId,
              VariantId: args.data?.VariantId,
              ProjectRoleId: this.selectedProjectRole,
            },
          });

        console.log(roleMapping);

        if (roleMapping.total == 1) {
          // Update user if mapping exists
          console.log(roleMapping.data[0].id, {
            RoleUserId: args.data.User,
          });
          try {
            await feathersClient
              .service('project-role-mapping')
              .patch(roleMapping.data[0].id, {
                RoleUserId: args.data.User[0],
              });
            handleSaveResponse('', 'Project Role', 'updated');
          } catch (error) {
            handleSaveResponse(error);
          }
          console.log('Exists', roleMapping.data);
        } else if (roleMapping.total == 0) {
          // Create if not exist
          await feathersClient.service('project-role-mapping').create({
            DepartmentId: args.data?.DepartmentId,
            ProcessId: args.data?.ProcessId,
            VariantId: args.data?.VariantId,
            ProjectRoleId: this.selectedProjectRole,
            RoleUserId: args.data.User[0],
          });
          handleSaveResponse('', 'Project Role', 'created');
          console.log('New Mapping');
        }
        await this.getTreeData();
      }
    },
    async changeProjectRoleUser() {
      let roleMapping = await feathersClient
        .service('project-role-mapping')
        .find({
          query: {
            DepartmentId: null,
            ProcessId: null,
            VariantId: null,
            ProjectRoleId: this.selectedProjectRole,
          },
        });
      if (roleMapping.total == 1) {
        // Update user if mapping exists
        try {
          await feathersClient
            .service('project-role-mapping')
            .patch(roleMapping.data[0].id, {
              RoleUserId: this.selectedProjectRoleUser,
            });
          handleSaveResponse('', 'Project Role', 'updated');
        } catch (error) {
          handleErrorResponse(error);
        }
      } else if (roleMapping.total == 0) {
        // Create if not exist
        try {
          await feathersClient.service('project-role-mapping').create({
            ProjectRoleId: this.selectedProjectRole,
            RoleUserId: this.selectedProjectRoleUser,
          });
          handleSaveResponse('', 'Project Role', 'created');
        } catch (error) {
          handleErrorResponse(error);
        }
      }
      await this.getProjectUsers();
      await this.getTreeData();
    },
    async getTreeData() {
      this.selectedProjectRoleUser = this.projectUsers?.find(
        (f) => f.ProjectRoleId == this.selectedProjectRole
      )?.RoleUser?.id;
      let department = await feathersClient
        .service('department')
        .find({ query: { detail: true, $sort: { Name: -1 } } });
      let process = await feathersClient
        .service('process')
        .find({ query: { $sort: { Number: -1 } } });
      let variant = await feathersClient
        .service('variant')
        .find({ query: { $sort: { Number: -1 } } });
      let projectRoleMapping = await feathersClient
        .service('project-role-mapping')
        .find({});
      if (department.total > 0) {
        this.data = department.data.map((i) => {
          return {
            id: i.id.toString(),
            DepartmentId: i.id,
            ProcessId: null,
            VariantId: null,
            Name: i.Name,
            User: projectRoleMapping.data?.find(
              (f) =>
                f.ProjectRoleId == this.selectedProjectRole &&
                f.ProcessId == null &&
                f.VariantId == null &&
                f.DepartmentId == i.id
            )?.RoleUser?.fullname,
            InheritedUser:
              projectRoleMapping.data?.find(
                (f) =>
                  f.ProjectRoleId == this.selectedProjectRole &&
                  f.ProcessId == null &&
                  f.VariantId == null &&
                  f.DepartmentId == i.id
              )?.RoleUser?.fullname ??
              projectRoleMapping.data?.find(
                (f) =>
                  f.ProjectRoleId == this.selectedProjectRole &&
                  f.ProcessId == null &&
                  f.VariantId == null &&
                  f.DepartmentId == null
              )?.RoleUser?.fullname,
            children: i.processes?.map((i2) => {
              let p = process.data.find((f) => f.id == i2.id);
              return {
                id: `${i.id}.${p.id}`,
                DepartmentId: null,
                ProcessId: i2.id,
                VariantId: null,
                Name: p.Number + '. ' + p.Name,
                User: projectRoleMapping.data?.find(
                  (f) =>
                    f.ProjectRoleId == this.selectedProjectRole &&
                    f.ProcessId == i2.id &&
                    f.VariantId == null &&
                    f.DepartmentId == null
                )?.RoleUser?.fullname,
                InheritedUser:
                  projectRoleMapping.data?.find(
                    (f) =>
                      f.ProjectRoleId == this.selectedProjectRole &&
                      f.ProcessId == i2.id &&
                      f.VariantId == null &&
                      f.DepartmentId == null
                  )?.RoleUser?.fullname ??
                  projectRoleMapping.data?.find(
                    (f) =>
                      f.ProjectRoleId == this.selectedProjectRole &&
                      f.ProcessId == null &&
                      f.VariantId == null &&
                      f.DepartmentId == i.id
                  )?.RoleUser?.fullname ??
                  projectRoleMapping.data?.find(
                    (f) =>
                      f.ProjectRoleId == this.selectedProjectRole &&
                      f.ProcessId == null &&
                      f.VariantId == null &&
                      f.DepartmentId == null
                  )?.RoleUser?.fullname,

                children: variant.data
                  .filter((f) => f.ProcessId == i2.id)
                  ?.map((i3) => {
                    return {
                      id: `${i.id}.${i2.id}.${i3.id}`,
                      Name: i3.Number + '. ' + i3.Name,
                      DepartmentId: null,
                      ProcessId: null,
                      VariantId: i3.id,
                      User: projectRoleMapping.data?.find(
                        (f) =>
                          f.ProjectRoleId == this.selectedProjectRole &&
                          f.ProcessId == null &&
                          f.VariantId == i3.id &&
                          f.DepartmentId == null
                      )?.RoleUser?.fullname,
                      InheritedUser:
                        projectRoleMapping.data?.find(
                          (f) =>
                            f.ProjectRoleId == this.selectedProjectRole &&
                            f.ProcessId == null &&
                            f.VariantId == i3.id &&
                            f.DepartmentId == null
                        )?.RoleUser?.fullname ??
                        projectRoleMapping.data?.find(
                          (f) =>
                            f.ProjectRoleId == this.selectedProjectRole &&
                            f.ProcessId == i2.id &&
                            f.VariantId == null &&
                            f.DepartmentId == null
                        )?.RoleUser?.fullname ??
                        projectRoleMapping.data?.find(
                          (f) =>
                            f.ProjectRoleId == this.selectedProjectRole &&
                            f.ProcessId == null &&
                            f.VariantId == null &&
                            f.DepartmentId == i.id
                        )?.RoleUser?.fullname ??
                        projectRoleMapping.data?.find(
                          (f) =>
                            f.ProjectRoleId == this.selectedProjectRole &&
                            f.ProcessId == null &&
                            f.VariantId == null &&
                            f.DepartmentId == null
                        )?.RoleUser?.fullname,
                    };
                  }),
              };
            }),
          };
        });
      } else return [];
    },
    async getProjectUsers() {
      let projectUsers = await feathersClient
        .service('project-role-mapping')
        .find({
          query: {
            DepartmentId: null,
            ProcessId: null,
            VariantId: null,
          },
        });
      if (projectUsers.total > 0) {
        this.projectUsers = projectUsers.data;
      }
    },
  },
  async mounted() {
    let roles = await feathersClient.service('project-roles').find({});
    if (roles.total > 0) {
      this.projectRoles = roles.data;
    }
    let users = await feathersClient.service('users').find({});
    if (users.total > 0) {
      this.users = users.data;
      this.users.push({ fullname: '--', id: null });
      this.users = this.users.sort((a, b) =>
        a.fullname.localeCompare(b.fullname)
      );
    }
    await this.getProjectUsers();
    await this.getTreeData();
  },
};
</script>

<style></style>
