<template>
  <v-container>
    <h1>Comments</h1>
    <Comments v-model="show" />
  </v-container>
</template>

<script>
import Comments from '@/components/ProcessStep/Comment';

export default {
  components: { Comments },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="scss" scoped></style>
