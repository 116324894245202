<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="processStep"
      :items-per-page="20"
      :sort-by="['Number']"
      :sort-desc="[false]"
      item-key="id"
      class="elevation-1"
      disable-pagination
      show-select
      @item-selected="clickedRow"
      @toggle-select-all="clickedAll"
      v-model="selected"
      dense
      :hide-default-footer="true"
      :loading="isFindProcessStepPending"
      loading-text="Loading... Please wait"
    >
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { handleErrorResponse } from '@/utils/MessageHandler';
export default {
  props: {
    variant: {
      type: Number,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'Number',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
      ],
    };
  },
  mixins: [
    makeFindMixin({
      service: 'process-step',
    }),
  ],
  watch: {
    isFindProcessStepPending: function () {
      if (!this.isFindProcessStepPending) {
        this.selected = this.processStep.filter(
          (f) => f.GroupId == this.group.id
        );
      }
    },
    group: function () {
      this.selected = this.processStep.filter(
        (f) => f.GroupId == this.group.id
      );
    },
  },
  computed: {
    processStepParams() {
      return {
        query: {
          VariantId: this.variant,
          $or: [{ GroupId: this.group.id }, { GroupId: null }],
        },
      };
    },
  },
  methods: {
    async clickedRow(value) {
      if (value.value) {
        value.item.GroupId = this.group.id;
        try {
          await value.item.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      } else {
        value.item.GroupId = null;
        try {
          await value.item.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
      await this.refresh();
    },
    async clickedAll(value) {
      for (const v of value.items) {
        let item = {};
        item.item = v;
        item.value = value.value;
        await this.clickedRow(item, true);
      }
    },
    async refresh() {
      const { ProcessStep } = this.$FeathersVuex.api;
      await ProcessStep.find({
        query: { VariantId: this.variant },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
