import axios from 'axios';
import feathersClient from '@/feathers-client';
import { copyDocument } from '@/utils/LucidChartFunctions';
import { config } from '@/config';

let process = null;
let variants = [];
let processSteps = [];
let specifications = [];

async function CopyProcess(sourceURL, processId, options) {
  let {
    includeImage,
    includeSpecifications,
    includeFields,
    includeTestScenarios,
  } = options;
  process = null;
  variants = [];
  processSteps = [];
  specifications = [];

  await GetBaseData(sourceURL, processId);
  await CreateBaseData(sourceURL, includeImage);
  if (includeSpecifications) {
    await CreateSpecifications(sourceURL);
  }
  if (includeImage) {
    await CopyProcessFlowImage();
  }
  if (includeFields) {
    await CreateFields(sourceURL);
  }
  if (includeTestScenarios) {
    await CreateTestScenarios(sourceURL);
  }
}

async function GetBaseData(sourceURL, processId) {
  let header = await authenticateLoxi(sourceURL);
  if (header) {
    try {
      process = await axios.get(`${sourceURL}/process/${processId}`, header);
      process = process.data;
    } catch (error) {
      console.log(error);
    }
    if (process) {
      try {
        variants = await axios.get(
          `${sourceURL}/variant?ProcessId=${processId}`,
          header
        );
        variants = variants.data.data;
      } catch (error) {
        console.log(error);
      }
      if (variants.length > 0) {
        await Promise.all(
          variants.map(async (v) => {
            try {
              let steps = await axios.get(
                `${sourceURL}/process-step?VariantId=${v.id}`,
                header
              );
              processSteps.push(...steps.data.data);
            } catch (error) {
              console.log(error);
            }
          })
        );
      }
    }
  }
}

async function CreateBaseData(sourceURL, includeImage) {
  let processCreated = null;
  if (process && variants.length > 0 && processSteps.length > 0) {
    let department = await feathersClient
      .service('department')
      .find({ query: { Name: process.department.Name } });
    if (department.total == 0) {
      let departmentImport = await feathersClient
        .service('department')
        .find({ query: { CustomerDepartment: '9999' } });
      if (departmentImport.total == 0) {
        //create
        departmentImport = await feathersClient.service('department').create({
          Name: '9999 Imported',
          CustomerDepartment: '9999',
          ERPModule: '--',
        });
        department = departmentImport.id;
      } else {
        department = departmentImport.data[0].id;
      }
    } else {
      department = department.data[0].id;
    }
    process.OriginalExternalDocumentId = process.ExternalDocumentId;
    process.ExternalDocumentId = null;
    process.EmbedId = null;
    process.PageIndexes = includeImage ? process.PageIndexes : null;
    process.DepartmentId = department;
    process.Phase = 1;
    process.IssueTrackingId = null;
    delete process.createdAt;
    delete process.updatedAt;
    delete process.ModifiedById;
    delete process.AllowedDependencies;
    delete process.id;
    try {
      processCreated = await feathersClient.service('process').create(process);
    } catch (error) {
      console.log(error);
    }
    process.NewProcessId = processCreated.id;
    // Create Variants
    if (processCreated) {
      await Promise.all(
        variants.map(async (v) => {
          let variantCreated = null;
          let header = await authenticateLoxi(sourceURL);
          let applicationOrigin = await axios.get(
            `${sourceURL}/application?id=${v.ApplicationId}`,
            header
          );
          let application = await feathersClient
            .service('application')
            .find({ query: { Code: applicationOrigin.data.data[0].Code } });
          if (application.total == 0) {
            application = 1;
          } else {
            application = application.data[0].id;
          }

          v.ExternalId = includeImage ? v.ExternalId : null;
          v.PageIndexes = includeImage ? v.PageIndexes : null;
          v.ProcessId = processCreated.id;
          v.OriginalId = v.id;
          v.ApplicationId = application;
          delete v.createdAt;
          delete v.updatedAt;
          delete v.ModifiedById;
          delete v.id;

          try {
            variantCreated = await feathersClient.service('variant').create(v);
          } catch (error) {
            console.log(error);
          }
          if (variantCreated) {
            // Create Steps
            v.id = variantCreated.id;
            let steps = processSteps.filter((f) => f.VariantId == v.OriginalId);
            if (steps) {
              await Promise.all(
                steps.map(async (s) => {
                  let stepCreated = null;
                  s.ExternalId = includeImage ? s.ExternalId : null;
                  s.StatusId = 1; // always open
                  s.PriorityId = 1; // always must
                  delete s.createdAt;
                  delete s.updatedAt;
                  delete s.ModifiedById;
                  delete s.id;
                  s.VariantId = variantCreated.id;
                  try {
                    stepCreated = await feathersClient
                      .service('process-step')
                      .create(s);
                  } catch (error) {
                    console.log(error);
                  }
                  s.id = stepCreated.id;
                })
              );
            }
          }
        })
      );
    }
  }
}

async function CreateSpecifications(sourceURL) {
  // Get Specifications
  specifications = [];
  let header = await authenticateLoxi(sourceURL);

  if (header) {
    let allSpecs = null;
    allSpecs = await axios.get(`${sourceURL}/specification`, header);
    await Promise.all(
      variants.map(async (variant) => {
        let specs = null;
        try {
          specs = allSpecs.data.data.filter((s) => {
            return s.variants.some((v) => v.id == variant.OriginalId);
          });
        } catch (error) {
          console.log(error);
        }
        if (specs) {
          specs.forEach((s) => (s.VariantId = variant.id));
          specifications.push(...specs);
        }
      })
    );
  }
  // Find or create specification
  if (specifications.length > 0) {
    for (const s of specifications) {
      let existingspec = null;
      try {
        existingspec = await feathersClient.service('specification').find({
          query: {
            Description1: s.Description1,
            Description2: s.Description2,
            Description3: s.Description3,
            Description4: s.Description4,
          },
        });
      } catch (error) {
        console.log(error);
      }

      if (existingspec.total == 0) {
        // TODO Check if type exists else create it?
        let existingType = null;
        try {
          existingType = await feathersClient
            .service('specification-type')
            .find({ query: { Code: s.specification_type.Code } });
          console.log('existing', existingType);
          if (existingType.total == 0) {
            //Create Type
            let newType = null;
            let createType = { ...s.specification_type };
            delete createType.id;
            delete createType.createdAt;
            delete createType.updatedAt;
            delete createType.ModifiedById;
            try {
              newType = await feathersClient
                .service('specification-type')
                .create(createType);
            } catch (error) {
              console.log('k', error);
            }
            console.log(newType);
            s.SpecificationTypeId = newType.id;
          } else s.SpecificationTypeId = existingType.data[0].id;
        } catch (error) {
          console.log(error);
        }

        // Create spec
        delete s.id;
        delete s.Number;
        delete s.createdAt;
        delete s.updatedAt;
        delete s.ModifiedById;
        s.StatusId = 1;
        s.PriorityId = 1;
        try {
          existingspec = await feathersClient
            .service('specification')
            .create(s);
        } catch (error) {
          console.log(error);
        }
      } else {
        //use existingspec
        existingspec = existingspec.data[0];
      }
      // Link Specifications to Variant
      try {
        await feathersClient.service('specification-variant').create({
          VariantId: s.VariantId,
          SpecificationId: existingspec.id,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
}

async function CopyProcessFlowImage() {
  if (process.OriginalExternalDocumentId) {
    // Copy Image
    let result = await copyDocument(
      process.OriginalExternalDocumentId,
      `${process.Number.toString().padStart(4, '0')}: ${process.Name}`,
      parseInt(config.customer.lucidParentFolderId)
    );
    // Link image to process / variants /steps
    await feathersClient
      .service('process')
      .patch(process.NewProcessId, { ExternalDocumentId: result.documentId });
  }
}

async function CreateFields(sourceURL) {
  let header = await authenticateLoxi(sourceURL);

  if (header) {
    processSteps.map(async (p) => {
      if (p.fields.length > 0) {
        // check if field exist and save local id
        await Promise.all(
          p.fields.map(async (f) => {
            let existingfield = null;
            try {
              existingfield = await feathersClient.service('field').find({
                query: {
                  Name: f.Name,
                },
              });
            } catch (error) {
              console.log(error);
            }

            if (existingfield.total == 0) {
              // Create field
              delete f.id;
              delete f.createdAt;
              delete f.updatedAt;
              delete f.ModifiedById;
              try {
                existingfield = await feathersClient.service('field').create(f);
              } catch (error) {
                console.log(error);
              }
              if (existingfield.id) {
                if (f.fieldvaluelists.length > 0) {
                  await Promise.all(
                    f.fieldvaluelists.map(async (l) => {
                      // Create fieldvalues
                      delete l.id;
                      delete l.createdAt;
                      delete l.updatedAt;
                      delete l.ModifiedById;
                      l.FieldId = existingfield.id;
                      try {
                        await feathersClient
                          .service('fieldvaluelist')
                          .create(l);
                      } catch (error) {
                        console.log(error);
                      }
                    })
                  );
                }
              }
            } else {
              //use existingspec
              existingfield = existingfield.data[0];
            }
            // link to step
            try {
              await feathersClient.service('field-step').create({
                ProcessStepId: p.id,
                FieldId: existingfield.id,
              });
            } catch (error) {
              console.log(error);
            }
          })
        );
      }
    });
  }
}
async function CreateTestScenarios(sourceURL) {
  let header = await authenticateLoxi(sourceURL);

  if (header) {
    await Promise.all(
      variants.map(async (variant) => {
        let tests = null;
        let testscenarios = [];
        try {
          tests = await axios.get(
            `${sourceURL}/test-scenario?VariantId=${variant.OriginalId}`,
            header
          );
          tests = tests.data.data;
        } catch (error) {
          console.log(error);
        }
        if (tests) {
          testscenarios.push(...tests);
        }

        if (testscenarios) {
          await Promise.all(
            testscenarios.map(async (t) => {
              // Create testscenario
              t.VariantId = variant.id;
              delete t.id;
              delete t.createdAt;
              delete t.updatedAt;
              delete t.ModifiedById;

              try {
                await feathersClient.service('test-scenario').create(t);
              } catch (error) {
                console.log(error);
              }
            })
          );
        }
      })
    );
  }
}

async function GetRemoteProcessList(sourceURL) {
  let header = await authenticateLoxi(sourceURL);
  let processList = [];
  if (header) {
    try {
      processList = await axios.get(`${sourceURL}/process`, header);
      processList = processList.data.data;
    } catch (error) {
      console.log(error);
    }
  }
  return processList;
}

async function authenticateLoxi(sourceURL) {
  let token = null;
  let config = null;

  try {
    token = await axios.post(sourceURL + '/authentication', {
      email: 'lode.bovyn@foocus.be',
      password: 'koko',
      strategy: 'local',
    });
  } catch (error) {
    console.log(error);
  }

  if (token) {
    config = {
      headers: {
        Authorization: 'Bearer ' + token.data.accessToken,
      },
    };
  }

  return config;
}

export { CopyProcess, GetRemoteProcessList };
