<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog && this.item">
    <template v-slot="{ clone, save, reset, remove }">
      <ProcessStepEditDialog
        :item="clone"
        :saving="saving"
        @save="
          saving = true;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Name,
                'process step',
                currentItem ? 'updated' : 'created'
              );
              await refresh();
              showDialog = false;
              saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
              saving = false;
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Name, 'process step', 'removed');
              await refresh();
              showDialog = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            })
        "
        @cancel="showDialog = false"
      ></ProcessStepEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import ProcessStepEditDialog from '@/components/ProcessStep/ProcessStepEditDialog';

export default {
  name: 'ProcessStepEdit',
  components: {
    FeathersVuexFormWrapper,
    ProcessStepEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    variant: {},
  },
  watch: {
    currentItem() {
      const { ProcessStep } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = this.currentItem;
      } else {
        let newProcessStep = new ProcessStep();
        newProcessStep.VariantId = this.variant;
        this.item = newProcessStep;
      }
    },
  },
  computed: {
    // item() {
    //   const { ProcessStep } = this.$FeathersVuex.api;
    //   if (this.currentItem) {
    //     return this.currentItem;
    //   } else {
    //     let newProcessStep = new ProcessStep();
    //     newProcessStep.VariantId = this.variant;
    //     return newProcessStep;
    //   }
    // },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { ProcessStep } = this.$FeathersVuex.api;
      ProcessStep.find({
        query: { VariantId: this.variant },
      });
    },
  },
  mounted() {
    const { ProcessStep } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = this.currentItem;
    } else {
      let newProcessStep = new ProcessStep();
      newProcessStep.VariantId = this.variant;
      this.item = newProcessStep;
    }
  },
};
</script>
