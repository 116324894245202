<template>
  <div>
    <div v-if="variant && process" class="caption">
      <router-link
        :to="{
          name: 'processDetail',
          params: { id: process.id, number: process.Number },
        }"
        >{{ process.Name }}</router-link
      >

      <v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small>mdi-chevron-right</v-icon>
        </template>

        <v-list dense>
          <v-list-item v-for="variant in sortedVariants" :key="variant.id">
            <v-list-item-title
              ><router-link
                :to="{ name: 'processStep', params: { id: variant.id } }"
                >{{ variant.Number }}. {{ variant.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <router-link :to="{ name: 'processStep', params: { id: variant.id } }">{{
        variant.Name
      }}</router-link>
      <v-icon small class="ml-2 mr-2">mdi-forward</v-icon>
      <router-link
        :to="{
          name: 'processRequirement',
          params: { id: variant.id, type: 1 },
        }"
        ><b>Requirements</b> ({{ variant.requirements.length }})</router-link
      >
    </div>
    <v-row justify="center" class="mt-2">
      <v-col cols="12" md="3">
        <v-select
          v-model="selectedRequirementType"
          :items="requirementType"
          item-text="Type"
          item-value="id"
          label="Requirement Type"
          placeholder="All"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn @click="clearSelection">Clear</v-btn>
      </v-col>
    </v-row>
    <v-alert border="bottom">
      <div class="subtitle-1" v-if="variant && currentRequirementType">
        {{ fullProcess }} - {{ currentRequirementType.Type }}
      </div>
      <div class="subtitle-1" v-else>All Requirements</div>
    </v-alert>

    <FeathersVuexFind
      v-slot="{ items: requirements }"
      service="requirement"
      :params="filter"
    >
      <section>
        <v-progress-linear
          v-if="requirements.isFindPending"
          indeterminate
          color="secondary"
        ></v-progress-linear>
        <v-card v-for="(item, i) in requirements" :key="i" class="mb-4">
          <v-card-title
            class="blue-grey lighten-5 subtitle-1"
            :class="{ obsolete: item.Obsolete }"
          >
            {{ item.requirement_type.Code
            }}{{ item.Number.toString().padStart(4, '0') }} -
            {{ item.Description1 }}
            <span v-if="item.priority" class="overline ml-4"
              >({{ item.priority.Name }})</span
            >
            <v-spacer></v-spacer>
            <v-chip
              class="ma-2"
              :color="item.status.Color"
              v-if="!item.Obsolete"
              x-small
            >
              {{ item.status.Status }}
            </v-chip>
          </v-card-title>
          <v-expand-transition>
            <div v-show="show[i]">
              <v-card-text :class="{ obsolete: item.Obsolete }">
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description1 }}
                </div>
                <div class="pre">{{ item.Description1 }}</div>
              </v-card-text>
              <v-card-text
                v-if="item.Description2"
                :class="{ obsolete: item.Obsolete }"
              >
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description2 }}
                </div>

                <div class="pre">{{ item.Description2 }}</div>
                <!-- <div v-if="urlify(item.omschrijving2)">
              <v-icon class="mr-2">
                mdi-web
              </v-icon>
              <a :href="urlify(item.omschrijving2)" target="_blank">Link</a>
            </div> -->
              </v-card-text>
              <v-card-text
                v-if="item.Description3"
                :class="{ obsolete: item.Obsolete }"
              >
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description3 }}
                </div>
                <div class="pre">{{ item.Description3 }}</div>
              </v-card-text>
              <v-card-text
                v-if="item.Description4"
                :class="{ obsolete: item.Obsolete }"
              >
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description4 }}
                </div>
                <div class="pre">{{ item.Description4 }}</div>
              </v-card-text>
            </div>
          </v-expand-transition>
          <v-card-actions>
            <v-btn
              v-if="$can('update', 'requirement')"
              color="indigo darken-4"
              @click="clickEdit(item)"
              text
            >
              <v-icon small dark> mdi-pencil </v-icon>
            </v-btn>
            <!-- <v-btn color="indigo darken-4" @click="deleteItem(i)" text>
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn> -->
            <v-spacer></v-spacer>

            <v-btn icon @click="toggle(i)">
              <v-icon>{{
                show[i] ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </section>
    </FeathersVuexFind>
    <v-btn
      v-if="$can('create', 'requirement')"
      elevation="0"
      class="mx-2"
      fab
      small
      color="primary"
      @click="clickAdd()"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <RequirementEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variant.id"
      :type="requirementTypeId"
    />
  </div>
</template>

<script>
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import RequirementEdit from '@/components/Requirement/RequirementEdit';

export default {
  props: ['variant', 'requirementTypeId', 'statusId'],
  mixins: [
    makeFindMixin({ service: 'requirement-type' }),
    makeGetMixin({
      service: 'requirement-type',
      name: 'currentRequirementType',
      id: 'selectedRequirementType',
      watch: 'selectedRequirementType',
    }),
    makeGetMixin({
      service: 'process',
      id: 'processId',
      watch: 'processId',
    }),
  ],
  components: { RequirementEdit },
  data() {
    return {
      show: [],
      detailDialog: false,
      selectedRequirementType: null,
    };
  },
  methods: {
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    clearSelection() {
      this.selectedRequirementType = null;
    },
  },
  computed: {
    processId() {
      return this.variant ? this.variant.ProcessId : null;
    },
    requirementTypeParams() {
      return {};
    },
    sortedVariants() {
      let variants = [...this.process.variants];
      return variants.sort((a, b) => (a.Number > b.Number ? 1 : -1));
    },
    fullProcess() {
      if (this.variant) {
        return (
          this.$appConfig.customer.prefix +
          this.process.Number.toString().padStart(4, '0') +
          '.' +
          this.variant.Number.toString().padStart(2, '0') +
          ' ' +
          this.variant.Name
        );
      } else {
        return 'Process Steps';
      }
    },
    filter() {
      if (this.variant && this.selectedRequirementType) {
        return {
          query: {
            VariantId: parseInt(this.variant.id),
            RequirementTypeId: parseInt(this.selectedRequirementType),
            $sort: {
              Description1: 1,
            },
          },
        };
      } else if (this.variant && this.selectedRequirementType == null) {
        return {
          query: {
            VariantId: parseInt(this.variant.id),

            $sort: {
              Description1: 1,
            },
          },
        };
      } else {
        return { query: {} };
      }
    },
  },
  watch: {
    requirementTypeId: async function (val) {
      this.selectedRequirementType = parseInt(val);
    },
  },
  mounted() {
    this.selectedRequirementType = parseInt(this.requirementTypeId);
  },
};
</script>

<style lang="scss" scoped>
.obsolete {
  text-decoration: line-through;
}
</style>
