<template>
  <div>
    <v-dialog :value="true" persistent max-width="600px">
      <div v-if="item">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">{{ $t('title.profileEdit') }}</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.fullname"
                    :label="$t('form.name')"
                    outlined
                    dense
                    :rules="[rules.required]"
                    autofocus
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.password"
                    :label="$t('form.newPassword')"
                    type="password"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="confirmPassword"
                    :label="$t('form.confirmPassword')"
                    :rules="[rules.confirm]"
                    type="password"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="$i18n.locale"
                    :items="langs"
                    item-text="Language"
                    item-value="Code"
                    :label="$t('form.language')"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">{{
                $t('button.cancel')
              }}</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                @click="$emit('save')"
              >
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ProfileEditDialog',
  data() {
    return {
      validInput: true,
      langs: [
        { Language: 'English', Code: 'en' },
        { Language: 'Nederlands', Code: 'nl' },
        { Language: 'Français', Code: 'fr' },
      ],
      errorMessage: null,
      confirmPassword: '',

      rules: {
        required: (value) => !!value || 'Required.',
        confirm: (value) =>
          value == this.item.password || 'Passwords must match',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
