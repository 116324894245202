<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <RequirementEditDialog
        :item="clone"
        @save="
          showDialog = false;
          save().then(handleSaveResponse).catch(errorHandler);
        "
        @reset="reset"
        @remove="
          remove();
          refresh();
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></RequirementEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import RequirementEditDialog from '@/components/Requirement/RequirementEditDialog';

export default {
  name: 'RequirementEdit',
  components: {
    FeathersVuexFormWrapper,
    RequirementEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    variant: {},
    type: {},
  },
  computed: {
    item() {
      const { Requirement } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        let newRequirement = new Requirement();
        newRequirement.VariantId = this.variant;
        newRequirement.RequirementTypeId = this.type;
        // console.log(newRequirement);
        return newRequirement;
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async refresh() {
      const { Requirement, Variant } = this.$FeathersVuex.api;
      await Requirement.find({
        query: {},
      });
      await Variant.find({ query: { id: this.variant } });
    },
    async handleSaveResponse(savedReq) {
      this.$toasted.global.success(
        `Requirement '${savedReq.Description1}' saved successfully`
      );
      const { Requirement } = this.$FeathersVuex.api;
      Requirement.find({
        query: { VariantId: this.variant },
      });

      await this.refresh();
      // Redirect to the newly-saved item
      // if (this.id === 'new') {
      //   this.$router.push({ params: { id: savedTodo._id } });
      // }
    },
    errorHandler(error) {
      //console.log(error);
      let errorMessage = '';
      if (
        Object.keys(error.errors).length === 0 &&
        error.errors.constructor === Object
      ) {
        errorMessage = error.message;
      } else {
        error.errors.map((item) => {
          errorMessage += item.message + '\n';
        });
        this.errorMessage = errorMessage;
      }
      this.$toasted.global.error(errorMessage);
    },
  },
};
</script>
