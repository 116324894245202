<template>
  <v-container>
    <h1>
      Issues <span v-if="selectedPhase">(Phase {{ selectedPhase }})</span>
    </h1>
    <IssueList v-model="show" :phase="selectedPhase.toString()" />
  </v-container>
</template>

<script>
import IssueList from '@/components/Issues/IssueList.vue';

export default {
  components: { IssueList },
  data() {
    return {
      show: true,
      selectedPhase: 'All',
    };
  },
  mounted() {
    this.selectedPhase = window.localStorage.getItem('phase')
      ? window.localStorage.getItem('phase')
      : 'All';
  },
};
</script>

<style lang="scss" scoped></style>
