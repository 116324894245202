import feathersClient from '@/feathers-client';
import axios from 'axios';
import store from '@/store';

async function getToken() {
  let appInfo = null;
  try {
    appInfo = await feathersClient
      .service('tokens')
      .find({ query: { Application: 'JiraAPI' } });
  } catch (error) {
    console.log('Error getting appInfo');
  }

  return appInfo;
}

async function getUserToken() {
  let userInfo = null;
  let appInfo = await getToken();

  if (appInfo && appInfo.total == 1) {
    try {
      userInfo = await feathersClient.service('tokens-user').find({
        query: {
          TokenUserId: store.getters['auth/user'].id,
          tokenId: appInfo.data[0].id,
        },
      });
    } catch (error) {
      console.log('Error getting userInfo');
    }
  }

  if (userInfo && userInfo.total == 1) {
    return userInfo.data[0];
  } else {
    return '';
  }
}

async function getHeader() {
  let userInfo = await getUserToken();

  let config = {
    headers: {
      Authorization: `Bearer  ${userInfo.AccessToken}`,
      'Content-Type': 'Application/json',
    },
  };
  return config;
}

async function useRefreshToken() {
  console.log('Reauthenticate');

  let appInfo = await getToken();
  let userInfo = await getUserToken();

  let url = 'https://auth.atlassian.com/oauth/token';
  const body = {
    client_id: appInfo.data[0].ClientId,
    client_secret: appInfo.data[0].ClientSecret,
    grant_type: 'refresh_token',
    refresh_token: userInfo.RefreshToken,
  };

  const options = {
    method: 'POST',
    data: body,
    url,
  };

  let accessToken = await axios(options);

  try {
    await feathersClient.service('tokens-user').patch(userInfo.id, {
      RefreshToken: accessToken.data.refresh_token,
      AccessToken: accessToken.data.access_token,
    });
  } catch (error) {
    console.log('Error saving new acces token: ', error);
  }
}

export { getHeader, useRefreshToken };
