/* eslint-disable no-prototype-builtins */
import feathersClient from '@/feathers-client';
import { config } from '@/config';

import { CreateWorkItem as CreateWorkItemDevOps } from './DevOpsFunctions';
import { CreateWorkItem as CreateWorkItemJira } from './JiraFunctions';

async function CreateIssue(data, type) {
  let issue = await feathersClient.service('external-issues').get(data.id);
  let process = data.ProcessId
    ? await feathersClient.service('process').get(data.ProcessId)
    : null;

  if (issue.IssueType?.ExternalType?.length > 0) {
    let WorkItem = null;
    if (config.externalIssues == 'Jira') {
      WorkItem = await CreateWorkItemJira(issue, process, type);
    } else if (config.externalIssues == 'DevOps') {
      WorkItem = await CreateWorkItemDevOps(issue, process, type);
    }

    if (type == 'post') {
      if (WorkItem) {
        if (WorkItem.hasOwnProperty('error')) throw new Error(WorkItem.error);
        await feathersClient
          .service('external-issues')
          .patch(issue.id, { ExternalId: WorkItem.id, Url: WorkItem.link });
      } else {
        throw new Error(
          'Error creating external task on ' + config.externalIssues
        );
      }
    }
  }
}

// async function CreateIssue2(data) {
//   // Get latest version of isssue
//   let issue = await feathersClient.service('external-issues').get(data.id);

//   if (issue) {
//     // Get the mapped status
//     let status = await feathersClient.service('issue-type-status').find({
//       query: { IssueTypeId: issue.IssueTypeId, $sort: { Order: 1 } },
//     });

//     if (status.total > 0) {
//       status = status.data.find((f) => f.StatusId == data.StatusId);
//     } else status = null;

//     // Get the mapped severity
//     let severity = await feathersClient.service('issue-type-severity').find({
//       query: {
//         IssueTypeId: issue.IssueTypeId,
//         $sort: { Order: -1 },
//       },
//     });

//     if (severity.total > 0) {
//       if (issue.Blocking) {
//         severity = severity.data.find((f) => f.Blocking == true);
//       } else {
//         severity = severity.data.find((f) => f.Initial == true);
//       }
//     }

//     // Only create issue if an external type mapping exists
//     if (issue.IssueType?.ExternalType?.length > 0) {
//       let WorkItem = null;

//       if (config.externalIssues == 'DevOps') {
//         WorkItem = await CreateWorkItemDevOps(
//           issue.Title,
//           issue.IssueType.ExternalType,
//           severity.ExternalSeverity ? severity.ExternalSeverity : null,
//           issue.Content1,
//           issue.Content2,
//           issue.process,
//           status.ExternalStatus ? status.ExternalStatus : null
//         );
//       } else if (config.externalIssues == 'Jira') {
//         WorkItem = await CreateWorkItemJira(
//           issue.Title,
//           issue.IssueType.ExternalType,
//           severity.ExternalSeverity ? severity.ExternalSeverity : null,
//           issue.Content1,
//           issue.Content2,
//           issue.process,
//           status.ExternalStatus ? status.ExternalStatus : null
//         );
//       }

//       if (WorkItem) {
//         await feathersClient
//           .service('external-issues')
//           .patch(issue.id, { ExternalId: WorkItem.id, Url: WorkItem.link });
//       } else {
//         throw new Error(
//           'Error creating external task on ' + config.externalIssues
//         );
//       }
//     }
//   }
// }
export { CreateIssue };
