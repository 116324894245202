import { shallowPopulate } from 'feathers-shallow-populate';

import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client';

class Comment extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Comment';
  // Define default properties here
  static instanceDefaults() {
    return {
      Comment: '',
      StatusId: null,
    };
  }
}
const servicePath = 'comment';
const servicePlugin = makeServicePlugin({
  Model: Comment,
  service: feathersClient.service(servicePath),
  servicePath,
});

const options = {
  include: [
    {
      service: 'status',
      nameAs: 'status',
      keyHere: 'StatusId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
    {
      service: 'users',
      nameAs: 'createdBy',
      keyHere: 'CreatedById',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
    {
      service: 'process-step',
      nameAs: 'process_step',
      keyHere: 'ProcessStepId',
      catchOnError: true,
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
  ],
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [shallowPopulate(options)],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
