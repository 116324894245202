import axios from 'axios';
import { config } from '@/config';

async function printingProcess(item) {
  const urlProcess = item?.id
    ? // ? `http://localhost:5062/processdoc/processTemplate/${item.id}`
      // : `http://localhost:5062/processdoc/processTemplate`;
      `${config.docExportUrl}/processdoc/processTemplate/${item.id}`
    : `${config.docExportUrl}/processdoc/processTemplate`;
  const data = JSON.stringify({
    output: 'docx',
    backend: config.apiBaseUrl,
    environmentCode: config.customer.prefix,
    includeSpecifications: true,
    includeApplicationForms: true,
    includeApplications: true,
    includeStatus: true,
    includeSites: true,
    includeRoles: true,
  });
  await print(urlProcess, data);
}

async function printingEnd2End(item) {
  const urlEnd2End = `${config.docExportUrl}/end2Enddoc/end2EndTemplate/${item.id}`;

  const data = JSON.stringify({
    output: 'docx',
    backend: config.apiBaseUrl,
    environmentCode: config.customer.prefix,
    includeSpecifications: true,
    includeApplicationForms: true,
    includeApplications: true,
    includeStatus: true,
    includeSites: true,
    includeRoles: true,
  });
  await print(urlEnd2End, data);
}

async function printingTestScenarioHeader(item) {
  const urlHeader = item?.id
    ? // ? `http://localhost:5062/processdoc/processTemplate/${item.id}`
      // : `http://localhost:5062/processdoc/processTemplate`;
      `${config.docExportUrl}/testSuitedoc/testSuiteTemplate/${item.id}`
    : `${config.docExportUrl}/testSuitedoc/testSuiteTemplate`;

  const data = JSON.stringify({
    output: 'docx',
    backend: config.apiBaseUrl,
    environmentCode: config.customer.prefix,
  });

  await print(urlHeader, data);
}

async function print(urlToPrint, data) {
  try {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlToPrint,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      responseType: 'blob', // Set response type to 'blob'
      data: data,
    };

    const response = await axios.request(config);

    // Extract filename from the Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'document.doc'; // Default filename

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
    }

    // Create a URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // Use the extracted filename
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link); // Clean up the link
  } catch (error) {
    console.error('Error generating document:', error);
  }
}

export { printingProcess, printingTestScenarioHeader, printingEnd2End };
