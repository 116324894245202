<template>
  <v-dialog :value="true" hide-overlay persistent max-width="600px">
    <v-card flat align="center" class="pa-5">
      <v-img src="logos/Loxi_Black.png" width="100%" />
      <div class="container">
        <h1>WE MAKE IT EASY FOR YOU</h1>
        <div class="infobox">
          <br />
          <ul style="list-style-type: disc">
            <li>
              Er mag geen (mis/ge)bruik van Loxi gemaakt worden waardoor Foocus
              BV enige schade zou kunnen oplopen.
            </li>
            <li>
              De Loxi tool mag niet op onwettige, illegale of frauduleuze manier
              gebruikt worden, noch om zulke activiteiten te ondersteunen.
            </li>
            <li>
              Het is de gebruiker niet toegestaan zich enig onderdeel van de
              software toe te eigen, noch te verkopen, verhuren, vervreemden of
              vertrouwelijke informatie over te dragen aan andere partijen.
            </li>
            <li>
              Zowel de beschreven als gebruikte methodologie in Loxi zijn
              vertrouwelijk.
            </li>
            <li>
              De toegang tot een account, biedt de gebruiker geen recht of
              toegang tot de broncode van de Loxi Process Managing Software.
              Loxi blijft te allen tijde het intellectueel eigendom van Foocus
              BV
            </li>
          </ul>
          <br />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
          <v-btn color="primary" @click="$emit('agree')"> Agree </v-btn>
        </v-card-actions>
      </div>
      <div class="footer">
        Copyright &copy; {{ currentYear }} Foocus BV - All rights reserved.
        <br />
        Our mailing address is: info@foocus.be <br /><br />
        <a href="https://loxi.be">www.loxi.be</a>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 180%;
}

div.infobox {
  padding-right: 2%;
  margin: auto;
  width: 95%;
  background-color: rgb(219, 245, 237);
  text-align: left;
  line-height: 1.5em;
}

div.footer {
  font-size: 80%;
}
a {
  color: black;
}
a:hover {
  color: darkcyan;
}
</style>
