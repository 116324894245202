<template>
  <div>
    <FeathersVuexFind
      v-slot="{ items: user }"
      service="users"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="user"
          :items-per-page="100"
          :sort-by="['fullname']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          hide-default-footer
          ><template v-if="$can('create', 'users')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add User</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.fullname"
                              label="Name"
                              :rules="[rules.required]"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.email"
                              label="Email"
                              :rules="[rules.required]"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.password"
                              label="Password"
                              :rules="[rules.required]"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.role"
                              :items="filteredRoles"
                              label="Role"
                              :rules="[rules.required]"
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.vendorId"
                              :items="vendor"
                              item-text="Name"
                              item-value="id"
                              label="Vendor"
                              clearable
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.ExternalId"
                              label="ExternalId"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-checkbox
                              v-model="editedItem.Active"
                              label="Active"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'users')"
            v-slot:[`item.fullname`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.fullname"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.fullname }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.fullname"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'users')"
            v-slot:[`item.email`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.email"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.email }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.email"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'users')"
            v-slot:[`item.ExternalId`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.ExternalId"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.ExternalId }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.ExternalId"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.color`]="props">
            <span v-if="props.item.color">
              <v-icon large class="mr-2" :color="props.item.color">
                mdi-circle
              </v-icon>
            </span>
          </template>
          <template v-if="$can('update', 'users')" v-slot:[`item.role`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.role"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.role }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.role"
                  :items="filteredRoles"
                  label="Edit"
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'users')"
            v-slot:[`item.vendorId`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.vendor"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ vendorName(props.item.vendorId) }}
              <template v-slot:input>
                <v-select
                  class="mt-7"
                  v-model="props.item.vendorId"
                  :items="vendor"
                  item-text="Name"
                  item-value="id"
                  label="Edit"
                  clearable
                  dense
                ></v-select>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'users')"
            v-slot:[`item.agreementAcceptedDate`]="{ item }"
          >
            <v-icon small v-if="item.agreementAcceptedDate" color="primary">
              mdi-check-bold
            </v-icon>
          </template>
          <template v-slot:[`item.Active`]="props">
            <v-edit-dialog
              v-if="$can('update', 'users')"
              :return-value.sync="props.item.Active"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Active">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.Active"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.Active && !$can('update', 'users')">
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>

          <template
            v-if="$can('delete', 'users')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template
            v-if="$can('update', 'users')"
            v-slot:[`item.reset`]="{ item }"
          >
            <v-icon small @click="resetPassword(item)">
              mdi-lock-outline
            </v-icon>
          </template>
        </v-data-table>
      </section></FeathersVuexFind
    >
    <v-dialog v-model="dialogPassword" max-width="800px">
      <v-form ref="form" v-model="validInput">
        <v-card>
          <v-card-title>
            <span class="headline">Reset Password</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newPassword"
                    label="Password"
                    :rules="[rules.required]"
                    dense
                  ></v-text-field> </v-col></v-row></v-container
          ></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="Close()">Cancel</v-btn>
            <v-btn
              :disabled="!validInput"
              color="primary"
              @click="savePassword()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card></v-form
      >
    </v-dialog>
  </div>
</template>

<script>
import feathersClient from '../../feathers-client';
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  mixins: [makeFindMixin({ service: 'vendor' })],
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'fullname',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email',
        },
        {
          text: 'Color',
          align: 'start',
          sortable: true,
          value: 'color',
        },
        {
          text: 'Role',
          align: 'start',
          sortable: true,
          value: 'role',
        },
        {
          text: 'Vendor',
          align: 'start',
          sortable: true,
          value: 'vendorId',
        },
        {
          text: 'ExternalId',
          align: 'left',
          sortable: true,
          value: 'ExternalId',
        },
        {
          text: 'Agreement Accepted',
          align: 'center',
          sortable: true,
          value: 'agreementAcceptedDate',
        },
        {
          text: 'Active',
          align: 'center',
          sortable: true,
          value: 'Active',
        },

        {
          text: 'Reset Password',
          value: 'reset',
          align: 'center',
          sortable: false,
        },
        { text: 'Delete', value: 'actions', align: 'center', sortable: false },
      ],
      dialog: false,
      dialogPassword: false,
      newPassword: null,
      passwordChangeUserId: 0,
      roles: ['owner', 'admin', 'customer', 'vendor'],
      editedItem: {
        fullname: '',
        email: '',
        password: this.generatePassword(),
        role: 'customer',
        vendorId: null,
        Active: true,
      },
      defaultItem: {
        fullname: '',
        email: '',
        password: null,
        role: 'customer',
        vendorId: null,
        Active: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    vendorParams() {
      return {};
    },
    filteredRoles() {
      if (this.user.role != 'owner') {
        return this.roles.filter((f) => f != 'owner');
      } else {
        return this.roles;
      }
    },

    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'users') ? true : item.text != 'Delete';
      });
    },
  },
  methods: {
    save(item) {
      item
        .save()
        .then((saved) => handleSaveResponse(saved.fullname, 'User', 'updated'))
        .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('users')
        .create(this.editedItem)
        .then((saved) => handleSaveResponse(saved.fullname, 'User', 'added'))
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.dialogPassword = false;

      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.password = this.generatePassword();
      this.newPassword = null;
      this.passwordChangeUserId = 0;
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) => handleSaveResponse(saved.fullname, 'User', 'removed'))
        .catch((error) => handleErrorResponse(error));
    },
    generatePassword() {
      var chars =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      var string_length = 14;
      var randomstring = '';
      for (var i = 0; i < string_length; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
      }
      return randomstring;
    },
    resetPassword(item) {
      this.passwordChangeUserId = item.id;
      this.dialogPassword = true;
      this.newPassword = this.generatePassword();
    },
    savePassword() {
      // console.log(this.passwordChangeUserId, this.newPassword);
      feathersClient
        .service('users')
        .patch(this.passwordChangeUserId, { password: this.newPassword })
        .then((saved) => {
          handleSaveResponse(saved.fullname, 'password', 'changed');
          this.Close();
        })
        .catch((error) => {
          handleErrorResponse(error);
          this.Close();
        });
    },
    vendorName(id) {
      let vendor = this.vendor.filter((v) => v.id == id);

      if (vendor && vendor.length > 0) return vendor[0].Name;
      return null;
    },
  },
};
</script>

<style></style>
