<template>
  <v-container
    ><FeathersVuexFind
      v-slot="{ items: IssueTypes }"
      service="issue-type"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="IssueTypes"
          :items-per-page="100"
          :sort-by="['Order']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-if="$can('create', 'IssueTypes')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Issue Type</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Type"
                              label="Issue Type"
                              :rules="[rules.required]"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Order"
                              label="Order"
                              :rules="[rules.required]"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.ExternalType"
                              label="External Type"
                            ></v-text-field
                          ></v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Icon"
                              label="Issue Icon"
                              :rules="[rules.required]"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'issue-type')"
            v-slot:[`item.Type`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Type"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Type }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Type"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type')"
            v-slot:[`item.Order`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Order"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Order }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Order"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type')"
            v-slot:[`item.ExternalType`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.ExternalType"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.ExternalType }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.ExternalType"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.Icon`]="props">
            <v-edit-dialog
              v-if="$can('update', 'issue-type')"
              :return-value.sync="props.item.Icon"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
              ><v-icon color="primary" small> {{ props.item.Icon }}</v-icon>

              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Icon"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
            <v-icon v-if="!$can('update', 'issue-type')" color="primary" small>
              {{ props.item.Icon }}</v-icon
            >
          </template>
          <template
            v-if="$can('create', 'issue-type-status')"
            v-slot:[`item.Status`]="{ item }"
          >
            <v-icon @click="addStatus(item)"> mdi-chevron-right </v-icon>
          </template>
          <template
            v-if="$can('create', 'issue-type-severity')"
            v-slot:[`item.Severity`]="{ item }"
          >
            <v-icon @click="addSeverity(item)"> mdi-chevron-right </v-icon>
          </template>
          <template
            v-if="$can('create', 'issue-type-fields')"
            v-slot:[`item.Fields`]="{ item }"
          >
            <v-icon @click="addField(item)"> mdi-chevron-right </v-icon>
          </template>
          <template
            v-if="$can('delete', 'issue-type')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section></FeathersVuexFind
    >
    <IssueTypeStatus
      v-model="showDialogStatus"
      v-if="showDialogStatus"
      :item="currentItem"
    />
    <IssueTypeSeverity
      v-model="showDialogSeverity"
      v-if="showDialogSeverity"
      :item="currentItem"
    />
    <IssueTypeField
      v-model="showDialogField"
      v-if="showDialogField"
      :item="currentItem"
    />
  </v-container>
</template>

<script>
import feathersClient from '../../feathers-client';
import IssueTypeStatus from './IssueTypeStatus.vue';
import IssueTypeSeverity from './IssueTypeSeverity.vue';
import IssueTypeField from './IssueTypeField.vue';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      headers: [
        {
          text: 'Issue Type',
          align: 'start',
          sortable: true,
          value: 'Type',
        },
        {
          text: 'Order',
          align: 'start',
          sortable: true,
          value: 'Order',
        },
        {
          text: 'External Type',
          align: 'start',
          sortable: true,
          value: 'ExternalType',
        },
        {
          text: 'Issue Icon',
          align: 'center',
          sortable: false,
          value: 'Icon',
        },
        {
          text: 'Statusses',
          align: 'center',
          sortable: false,
          value: 'Status',
        },
        {
          text: 'Severities',
          align: 'center',
          sortable: false,
          value: 'Severity',
        },
        {
          text: 'Fields',
          align: 'center',
          sortable: false,
          value: 'Fields',
        },

        { text: 'Delete', value: 'actions', sortable: false },
      ],
      dialog: false,
      showDialogStatus: false,
      showDialogSeverity: false,
      showDialogField: false,
      currentItem: null,
      editedItem: {
        Type: '',
        ExternalType: '',
        Icon: '',
      },
      defaultItem: {
        Type: '',
        ExternalType: '',
        Icon: '',
      },
    };
  },
  components: { IssueTypeStatus, IssueTypeSeverity, IssueTypeField },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'IssueTypes') ? true : item.text != 'Delete';
      });
    },
  },
  methods: {
    save(item) {
      item
        .save()
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Issue Type', 'updated')
        )
        .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('issue-type')
        .create(this.editedItem)
        .then((saved) => handleSaveResponse(saved.Name, 'Issue Type', 'added'))
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Issue Type', 'removed')
        )
        .catch((error) => handleErrorResponse(error));
    },
    addStatus(item) {
      this.showDialogStatus = true;
      this.currentItem = item;
    },
    addSeverity(item) {
      this.showDialogSeverity = true;
      this.currentItem = item;
    },
    addField(item) {
      this.showDialogField = true;
      this.currentItem = item;
    },
  },
};
</script>

<style></style>
