<template>
  <div>
    <v-card>
      <v-card-title>Scoring Phase 1</v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Vendor</th>
                <th class="text-left">Score</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr v-for="v in vendor" :key="v.id">
                <td>{{ v.Name }}</td>
                <td>{{ Score(v).toFixed(2) }}%</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>
                  <v-progress-circular
                    indeterminate
                    :size="15"
                    :width="1"
                  ></v-progress-circular>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';

export default {
  mixins: [
    makeFindMixin({ service: 'vendor' }),
    makeFindMixin({ service: 'response' }),
  ],
  computed: {
    loading() {
      return this.isFinVendorPending || this.isFindResponsePending;
    },
    vendorParams() {
      return { query: { $sort: { Name: 1 } } };
    },
    responseParams() {
      return { query: { Final: true } };
    },
  },
  methods: {
    Score(v) {
      if (this.response) {
        let filteredResponse = this.response.filter(
          (f) =>
            f.VendorId == v.id &&
            (f.SpecificationId != null || f.VariantId != null)
        );
        let score = [];
        filteredResponse.forEach((r) => {
          score.push(
            (r.complexity.Weight * r.implementation.Weight) /
              (r.complexity.id == 1 ? 1 : r.Divisor)
          );

          // console.log(r.implementation.Weight == 10 ? 1 : r.Divisor);
          // score.push(
          //   (r.complexity.Weight * r.implementation.Weight) /
          //     (r.specification != null
          //       ? r.implementation.Weight == 10
          //         ? 1
          //         : r.specification.priority.Divisor
          //       : 1)
          // );
        });

        return score.length > 0
          ? score.reduce((a, b) => a + b, 0) / score.length
          : 0;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
