<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="processStep"
      :items-per-page="20"
      :sort-by="['Number']"
      :sort-desc="[false]"
      item-key="id"
      class="elevation-1"
      disable-pagination
      dense
      :hide-default-footer="true"
      :loading="isFindProcessStepPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.Color`]="{ item }">
        <span
          v-for="color in group.find((f) => f.id == item.GroupId)?.allGroups"
          :key="color.id"
        >
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="circle pointer"
                :style="{
                  background: color.Color,
                  color: applyDark(color.Color) ? 'white' : 'black',
                }"
              >
                <v-icon
                  v-if="color.Loop"
                  :color="applyDark(color.Color) ? 'white' : 'black'"
                  x-small
                  >mdi-sync</v-icon
                ><span v-else>&nbsp;</span>
              </span></template
            >{{ color?.Name }}</v-tooltip
          >
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
export default {
  props: {
    variant: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'Color',
          width: '80px',
        },
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'Number',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
      ],
    };
  },
  mixins: [
    makeFindMixin({
      service: 'process-step',
      watch: 'variant',
    }),
    makeFindMixin({
      service: 'group',
      watch: 'variant',
    }),
  ],

  computed: {
    processStepParams() {
      return {
        query: {
          VariantId: this.variant,
        },
      };
    },
    groupParams() {
      return {
        query: {
          VariantId: this.variant,
        },
      };
    },
  },
  methods: { applyDark },
};
</script>

<style lang="scss" scoped>
span.circle {
  // background: red;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 2px;
  padding-bottom: 1px;
  text-align: center;
  width: 1.7em;
}

span.pointer {
  cursor: pointer;
}
</style>
