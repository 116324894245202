<template>
  <v-card>
    <v-card-title class="pb-0"
      >Process Steps

      <v-tooltip
        bottom
        v-if="
          ($feature('cloudinaryChartIntegration') && processUrl) ||
          ($feature('lucidChartIntegration') && lucidUrl) ||
          ($feature('drawIOChartIntegration') && drawingUrl)
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            class="ml-4"
            @click="
              openWindow(
                $feature('drawIOChartIntegration') && drawingUrl
                  ? drawingUrl
                  : $feature('lucidChartIntegration') && lucidUrl
                  ? lucidUrl
                  : processUrl
              )
            "
          >
            mdi-image-search-outline
          </v-icon>
        </template>
        <span>Process Flows</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-list dense>
        <v-list-item v-for="step in filteredProcessSteps" :key="step.id">
          <v-row class="mb-1"
            ><v-col cols="12" md="6">
              <v-list-item-content class="text-pre-wrap py-0">
                <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                <v-list-item-title>{{
                  step.Number +
                  '. ' +
                  step.Name +
                  ' (' +
                  step.priority.Name +
                  ')'
                }}</v-list-item-title>
                <span
                  v-if="step.Description"
                  class="mb-3 ql-editor"
                  v-html="step.Description"
                ></span>
                <div v-if="step.fields && step.fields.length > 0">
                  <i>Fields:</i>
                  <ul>
                    <li v-for="f in step.fields" :key="f.id">
                      {{ f.Name }}: {{ f.Description }}
                      <div
                        v-if="f.fieldvaluelists && f.fieldvaluelists.length > 0"
                      >
                        <i>Possible Values:</i>
                        <ul>
                          <li v-for="fv in f.fieldvaluelists" :key="fv.id">
                            {{ fv.Code }}
                            <span v-if="fv.Value">: {{ fv.Value }}</span
                            ><span v-if="fv.Description"
                              >: {{ fv.Description }}</span
                            >
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-list-item-content> </v-col
            ><v-col cols="12" md="6"
              ><ProcessResponse
                :processStep="step"
                :variantResponse="
                  response.filter((f) => f.VendorId == user.vendor.id)
                "
                v-if="user.vendor.ResponsePhase == 2" /></v-col
          ></v-row>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import ProcessResponse from '@/components/VendorResponse/ProcessResponse';
import { mapGetters } from 'vuex';

import {
  getDocumentEmbed,
  getDocumentEmbedToken,
} from '@/utils/LucidChartFunctions';
export default {
  props: ['variant'],
  mixins: [
    makeFindMixin({ service: 'process-step' }),
    makeFindMixin({ service: 'response' }),
  ],
  components: { ProcessResponse },
  data() {
    return {
      embedToken: null,
      NrOfDrawings: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    processStepParams() {
      return {
        query: {
          VariantId: this.variant.id,
          Obsolete: false,
        },
      };
    },
    responseParams() {
      return { query: { VariantId: this.variant.id } };
    },
    filteredProcessSteps() {
      let filter = [...this.processStep];
      filter = filter.filter(
        (s) => !(s.status.Progress == 0 && s.status.Closed == true)
      );
      return filter.sort((a, b) => a.Number - b.Number);
    },
    processUrl() {
      let url = '';
      if (this.processStep[0]) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.processStep[0].variant.process.Number.toString() +
          '.' +
          this.processStep[0].variant.Number.toString();
      }
      return url;
    },
    drawingUrl() {
      if (this.NrOfDrawings > 0) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?variant=${this.processStep[0].variant.id}`;
      } else {
        return null;
      }
    },
    LucidUrl() {
      if (this.processStep[0]) {
        return `https://lucid.app/documents/${
          this.processStep[0].variant.process.ExternalDocumentId
        }/viewer?pages=${
          this.processStep[0].variant.PageIndexes
            ? this.processStep[0].variant.PageIndexes
            : this.processStep[0].variant.Number + 1
        }&token=${this.embedToken}`;
      }
      return null;
    },
  },
  methods: {
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    async LucidEmbed() {
      if (
        this.processStep[0].variant.process &&
        this.processStep[0].variant.process.ExternalDocumentId
      ) {
        if (!this.processStep[0].variant.process.EmbedId) {
          await getDocumentEmbed(this.processStep[0].variant.process);
          // console.log('No Embed');
        }
        this.embedToken = await getDocumentEmbedToken(
          this.processStep[0].variant.process
        );
        // console.log('Embed', this.embedToken);
      } else {
        // console.log('No document Linked');
      }
    },
  },
  async mounted() {
    const { Drawings } = this.$FeathersVuex.api;
    let result = await Drawings.find({
      query: { VariantId: this.processStep[0].variant.id, $limit: 0 },
    });
    this.NrOfDrawings = result.total;
  },
};
</script>

<style></style>
