var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dataload)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{staticClass:"ma-50",attrs:{"indeterminate":"","size":50,"width":4}})],1):_vm._e(),(_vm.eventSettings.dataSource)?_c('ejsschedule',{ref:"scheduleObj",attrs:{"height":"90vh","eventSettings":_vm.eventSettings,"popupOpen":_vm.onPopupOpen,"actionComplete":_vm.OnActionCompleted,"actionBegin":_vm.onActionBegin,"rowAutoHeight":true,"readonly":_vm.dataload,"startHour":"07:00","endHour":"19:00"},scopedSlots:_vm._u([{key:"eventTemplate",fn:function({ data }){return [_c('div',{staticClass:"template-wrap",style:({
          background: data.EventType == 'Work Shop' ? '#d1f5ed' : '#777777FF',
          color: data.EventType == 'Work Shop' ? 'black' : 'white',
        })},[_c('div',{staticClass:"subject",staticStyle:{"position":"relative"}},[(data.PlanStatusId)?_c('span',{staticClass:"e-badge e-badge-circle size_1 #FF0000",style:({
              background: _vm.status?.find((d) => d.id == data.PlanStatusId)
                ?.Color,
            })}):_vm._e(),_vm._v(" "+_vm._s(data.Subject)+" ")]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(data.StartDate.toLocaleTimeString().slice(0, 5))+" - "+_vm._s(data.EndDate.toLocaleTimeString().slice(0, 5))+" ")]),_c('div',{staticClass:"status"},[(_vm.status?.find((d) => d.id == data.PlanStatusId))?_c('v-chip',{attrs:{"x-small":"","color":_vm.status?.find((d) => d.id == data.PlanStatusId)?.Color}},[_vm._v(" "+_vm._s(_vm.status?.find((d) => d.id == data.PlanStatusId)?.StatusToPlan))]):_vm._e()],1)])]}},{key:"tooltipTemplate",fn:function({ data }){return [_c('div',{staticClass:"tooltip-wrap"},[_c('div',{staticClass:"content-area"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(data.Subject))]),_c('div',[_vm._v(" "+_vm._s(data.StartDate.toLocaleDateString('nl-Be', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', }))+" ")]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(data.StartDate.toLocaleTimeString().slice(0, 5))+" - "+_vm._s(data.EndDate.toLocaleTimeString().slice(0, 5))+" ")]),_c('div',[(_vm.status?.find((d) => d.id == data.PlanStatusId))?_c('v-chip',{attrs:{"x-small":"","color":_vm.status?.find((d) => d.id == data.PlanStatusId)?.Color}},[_vm._v(" "+_vm._s(_vm.status?.find((d) => d.id == data.PlanStatusId)?.StatusToPlan))]):_vm._e()],1)])])]}}],null,false,1134103989)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }