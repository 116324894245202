<template>
  <div>
    <v-row
      ><v-col>
        <v-text-field
          class="mb-5"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          @click:clear="search = null"
        ></v-text-field></v-col
    ></v-row>

    <v-row
      ><v-col
        cols="12"
        md="6"
        v-for="(spec, i) in filteredSpecifications"
        :key="spec.id"
      >
        <v-card
          :loading="isFindSpecificationPending"
          height="100%"
          class="mr-1"
        >
          <template slot="progress">
            <v-progress-linear
              v-if="isFindSpecificationPending"
              indeterminate
              color="secondary"
            ></v-progress-linear>
          </template>

          <v-card-title
            class="subtitle-2"
            :class="{ obsolete: spec.Obsolete }"
            v-if="!isFindSpecificationPending"
            >{{ spec.Description1 }}
          </v-card-title>
          <v-card-subtitle
            class="pb-0"
            v-if="spec.specification_type && spec.priority"
            >{{ spec.specification_type.Description1 }} -
            {{ spec.specification_type.Code
            }}{{ spec.Number.toString().padStart(4, '0') }}
            <span class="caption">({{ spec.priority.Name }})</span>
          </v-card-subtitle>

          <v-card-text
            class="ma-0"
            v-if="
              spec.Description2 ||
              spec.Description3 ||
              spec.Description4 ||
              listVariants(spec)
            "
          >
            <v-list dense>
              <v-list-item v-if="spec.Description2">
                <v-list-item-content>
                  <v-list-item-title>{{
                    spec.specification_type.Description2
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><span
                      v-html="spec.Description2"
                      class="text-body-2 ma-0 pa-0 ql-editor"
                    ></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="spec.Description3">
                <v-list-item-content>
                  <v-list-item-title>{{
                    spec.specification_type.Description3
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><span
                      v-html="spec.Description3"
                      class="text-body-2 ma-0 pa-0 ql-editor"
                    ></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="spec.Description4">
                <v-list-item-content>
                  <v-list-item-title>{{
                    spec.specification_type.Description4
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><span
                      v-html="spec.Description4"
                      class="text-body-2 ma-0 pa-0 ql-editor"
                    ></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="listVariants(spec).length > 0">
                <v-list-item-content>
                  <v-list-item-title>Process</v-list-item-title>
                  <v-list-item-subtitle>
                    <ul>
                      <li v-for="v in listVariants(spec)" :key="v.id">
                        {{
                          `${v.process.Number}. ${v.process.Name} (${v.Name})`
                        }}
                        <v-tooltip
                          bottom
                          v-if="
                            ($feature('cloudinaryChartIntegration') &&
                              processUrl) ||
                            ($feature('lucidChartIntegration') && lucidUrl) ||
                            ($feature('drawIOChartIntegration') &&
                              drawingUrl(v))
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-4 mb-2"
                              @click="
                                openWindow(
                                  $feature('drawIOChartIntegration') &&
                                    drawingUrl(v)
                                    ? drawingUrl(v)
                                    : $feature('lucidChartIntegration') &&
                                      lucidUrl
                                    ? lucidUrl
                                    : processUrl
                                )
                              "
                            >
                              mdi-image-search-outline
                            </v-icon>
                          </template>
                          <span>Process Flows</span>
                        </v-tooltip>
                      </li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-subtitle class="mb-6 py-0"
            >Response
            <v-icon
              v-if="vendorResponseFinal(spec) == 2"
              color="green"
              small
              class="mr-1"
              >mdi-check-bold</v-icon
            ><v-icon
              v-if="vendorResponseFinal(spec) == 1"
              color="#FFC698"
              small
              class="mr-1"
              >mdi-close-thick</v-icon
            >
            <v-icon
              v-if="vendorResponseFinal(spec) == 0"
              color="red"
              small
              class="mr-1"
              >mdi-close-thick</v-icon
            >
            <v-btn icon @click="toggle(i)">
              <v-icon>{{
                show[i] ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-btn></v-card-subtitle
          >
          <v-expand-transition>
            <div v-show="show[i]">
              <v-divider class="mx-4"></v-divider>

              <v-card-text class="mb-14">
                <SpecificationResponse
                  :specification="spec"
                  :complexity="complexity"
                  :implementation="implementation"
                  :response="getResponse(spec)"
                  :vendor="vendor"
              /></v-card-text>
            </div>
          </v-expand-transition> </v-card></v-col
    ></v-row>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
import SpecificationResponse from '@/components/VendorResponse/SpecificationResponse';
export default {
  mixins: [
    makeFindMixin({ service: 'specification' }),
    makeFindMixin({ service: 'response' }),
    makeFindMixin({ service: 'complexity' }),
    makeFindMixin({ service: 'implementation' }),
    makeFindMixin({ service: 'vendor' }),
  ],
  components: { SpecificationResponse },
  data() {
    return {
      search: null,
      show: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    specificationParams() {
      return { query: { $sort: { SpecificationTypeId: 1, Number: 1 } } };
    },
    responseParams() {
      return {
        query: {
          VendorId: this.user.vendor ? this.user.vendor.id : null,
          SpecificationId: { $ne: null },
        },
      };
    },
    complexityParams() {
      return { query: { $sort: { Weight: -1 } } };
    },
    implementationParams() {
      return { query: { $sort: { Weight: -1 } } };
    },
    vendorParams() {
      return { query: { id: this.user.vendor ? this.user.vendor.id : null } };
    },
    filteredSpecifications() {
      let filteredSpecs = [...this.specification];

      filteredSpecs = filteredSpecs.filter(
        (s) => !(s.status.Progress == 0 && s.status.Closed == true)
      );

      if (this.search)
        return this.specification.filter(
          (item) =>
            item.Description1.toLowerCase().includes(
              this.search.toLowerCase()
            ) ||
            (item.Description2 != null
              ? item.Description2.toLowerCase().includes(
                  this.search.toLowerCase()
                )
              : false) ||
            (item.Description3 != null
              ? item.Description3.toLowerCase().includes(
                  this.search.toLowerCase()
                )
              : false) ||
            (item.Description4 != null
              ? item.Description4.toLowerCase().includes(
                  this.search.toLowerCase()
                )
              : false)
        );
      else return filteredSpecs;
    },
  },
  methods: {
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    getResponse(spec) {
      if (this.response) {
        return this.response.filter((r) => r.SpecificationId == spec.id);
      }
      return null;
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    processUrl(variant) {
      let url = '';
      if (variant) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          variant.process.Number.toString() +
          '.' +
          variant.Number.toString();
      }
      return url;
    },
    drawingUrl(variant) {
      return `${this.$appConfig.clientBaseUrl}/#/drawing?variant=${variant.id}`;
    },
    listVariants(item) {
      // let variants = [];
      if (item.variants) {
        let sortedvariant = [...item.variants];
        sortedvariant = sortedvariant.sort(
          (a, b) => a.process.Number - b.process.Number
        );
        // sortedvariant.forEach((v) =>
        //   // variants.push(`${v.process.Number}. ${v.process.Name} (${v.Name})`)
        //   variants.push(v);
        // );
        return sortedvariant;
      }
      return null;
    },
    vendorResponseFinal(s) {
      if (this.response) {
        const [response] = this.response.filter(
          (r) => r.VendorId == this.user.vendor.id && r.SpecificationId == s.id
        );
        return response ? (response.Final ? 2 : 1) : 0;
      } else return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
