<template>
  <div>
    <v-dialog :value="true" persistent max-width="600px">
      <v-progress-linear
        v-if="isFindVariantPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindVariantPending">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">Add Variant to Specification</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="item['VariantId']"
                    :items="variantList"
                    item-text="fullname"
                    item-value="id"
                    label="Variant"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                @click="$emit('save')"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  name: 'SpecificationAddVariantDialog',
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mixins: [makeFindMixin({ service: 'variant' })],
  computed: {
    variantParams() {
      return { query: { $sort: { 'process.Number': 1 } } };
    },
    variantList() {
      let list = [...this.variant];
      list.map((item) => {
        item.fullname = `${item.process.Number}.${item.Number} ${item.Name}`;
      });
      return list.sort((a, b) => {
        if (a.process.Number === b.process.Number) {
          return a.Number - b.Number;
        }
        return a.process.Number - b.process.Number;
      });
    },
  },
};
</script>
