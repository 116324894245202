<template>
  <v-container>
    <v-card class="pa-5">
      <h3>Copy Process</h3>
      <!-- <v-icon @click="copyProcess">mdi-content-copy</v-icon>
    <v-progress-circular
      indeterminate
      :size="15"
      :width="1"
      v-if="copying"
    ></v-progress-circular> -->
      <v-row dense class="mt-5">
        <v-col cols="12" md="6">
          <v-select
            :disabled="loading || copying"
            v-model="sourceURL"
            :items="sortedSourceList"
            item-text="Name"
            item-value="URL"
            label="Copy From"
            clearable
            outlined
            :loading="loading"
            @change="
              processList = null;
              processId = null;
              getProcesses();
            "
            dense
          ></v-select></v-col
      ></v-row>
      <v-row v-if="processList" dense>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="processId"
            :disabled="loading || copying"
            :items="fullProcessList"
            item-text="fullName"
            item-value="id"
            label="Select Process"
            outlined
            clearable
            dense
          ></v-autocomplete></v-col
        ><v-col cols="12" md="3">
          <v-btn
            :disabled="!processId || loading || copying"
            color="primary"
            :loading="copying"
            @click="startCopyProcess()"
          >
            Copy Process
          </v-btn></v-col
        ></v-row
      >
      <v-row v-if="processList" dense>
        <v-col cols="12" md="6">
          <v-checkbox
            label="Include Specifications"
            v-model="includeSpecifications"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="includeImage"
            label="Include Images"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="includeFields"
            label="Include Fields"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="includeTestScenarios"
            label="Include Test Scenarios (No Details)"
            hide-details
          ></v-checkbox> </v-col
      ></v-row>
    </v-card>
  </v-container>
</template>

<script>
import { CopyProcess, GetRemoteProcessList } from '@/utils/CopyProcess';
export default {
  data() {
    return {
      loading: false,
      copying: false,
      sourceURL: null,
      processList: null,
      processId: null,
      includeSpecifications: true,
      includeImage: true,
      includeFields: true,
      includeTestScenarios: true,
      sourceList: [
        {
          Name: 'Hifferman',
          URL: 'https://hff-backend.loxi.be',
        },
        {
          Name: 'ATS',
          URL: 'https://ats-backend.loxi.be',
        },
        {
          Name: 'Arop',
          URL: 'https://arp-backend.loxi.be',
        },
        {
          Name: 'ToyChamp',
          URL: 'https://tcp-backend.loxi.be',
        },
        {
          Name: 'ACD',
          URL: 'https://acd-backend.loxi.be',
        },
        {
          Name: 'Lambo',
          URL: 'https://lmb-backend.loxi.be',
        },
        {
          Name: 'Falco Brush',
          URL: 'https://flc-backend.loxi.be',
        },
        {
          Name: 'Makoa',
          URL: 'https://makoa-backend.loxi.be',
        },
      ],
    };
  },
  methods: {
    async getProcesses() {
      this.loading = true;
      this.processList = await GetRemoteProcessList(this.sourceURL);
      this.loading = false;
    },
    async startCopyProcess() {
      this.copying = true;
      await CopyProcess(this.sourceURL, this.processId, {
        includeSpecifications: this.includeSpecifications,
        includeImage: this.includeImage,
        includeFields: this.includeFields,
        includeTestScenarios: this.includeTestScenarios,
      });
      this.copying = false;
    },
  },
  computed: {
    fullProcessList() {
      let processes = [...this.processList];
      return processes
        .sort((a, b) => a.Number - b.Number)
        .map((obj) => {
          return { ...obj, fullName: `${obj.Number}. ${obj.Name}` };
        });
    },
    sortedSourceList() {
      let sources = [...this.sourceList];
      return sources.sort((a, b) => a.Name.localeCompare(b.Name));
    },
  },
};
</script>

<style lang="scss" scoped></style>
