<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <End2EndEditDialog
        :item="clone"
        :saving="saving"
        @save="
          saving = true;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Name,
                'End 2 End',
                currentItem ? 'updated' : 'created'
              );
              await refresh(saved);
              showDialog = false;
              saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh(saved);
              saving = false;
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Name, 'End 2 End', 'removed');
              await refresh(saved);
              showDialog = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh(saved);
            })
        "
        @cancel="showDialog = false"
      ></End2EndEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import End2EndEditDialog from '@/components/End2End/End2EndEditDialog';

export default {
  name: 'End2EndEdit',
  components: {
    FeathersVuexFormWrapper,
    End2EndEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
  },
  watch: {
    currentItem() {
      const { end2End } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = this.currentItem;
      } else {
        this.item = new end2End();
      }
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh(saved) {
      const { end2End } = await this.$FeathersVuex.api;
      end2End.find({
        query: { id: saved.id },
      });
      // Redirect to the newly-saved item
      // if (this.id === 'new') {
      //   this.$router.push({ params: { id: savedTodo._id } });
      // }
    },
  },
  mounted() {
    const { end2End } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = this.currentItem;
    } else {
      this.item = new end2End();
    }
  },
};
</script>
