<template>
  <div>
    <v-icon class="mr-10 mt-7" @click="exportToExcel()" color="primary">
      mdi-microsoft-excel
    </v-icon>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { generateExcel } from '@/utils/ExportExcel';
export default {
  mixins: [makeFindMixin({ service: 'export-responses-full' })],
  methods: {
    selectProps(...props) {
      return function (obj) {
        const newObj = {};
        props.forEach((name) => {
          newObj[name] = obj[name];
        });

        return newObj;
      };
    },
    exportToExcel() {
      this.exportResponsesFullLatestQuery.response.forEach(
        (i) => (i.score = parseFloat(i.score))
      );

      generateExcel(
        [
          {
            Responses: this.exportResponsesFullLatestQuery.response,
          },
        ],
        `Loxi Process Responses Full.xlsx`
      );
    },
  },
  computed: {
    exportResponsesFullParams() {
      return {
        query: {
          prefix: this.$appConfig.customer.prefix,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
