<template>
  <div>
    <v-alert border="bottom">
      <div class="subtitle-1" v-if="variantIdSelected">
        {{ fullProcess }} - {{ currentRequirementType.Type }}
      </div>
      <div class="subtitle-1" v-else>
        {{ currentRequirementType.Type }}
      </div>
    </v-alert>
    <FeathersVuexFind
      v-slot="{ items: requirements }"
      service="requirement"
      :params="filter"
      watch="params"
    >
      <section>
        <v-progress-linear
          v-if="requirements.isFindPending || isFindStatusPending"
          indeterminate
          color="secondary"
        ></v-progress-linear>
        <v-card v-for="(item, i) in requirements" :key="i" class="mb-4">
          <v-card-title
            class="blue-grey lighten-5 subtitle-1"
            :class="{ obsolete: item.Obsolete }"
          >
            {{ item.requirement_type.Code
            }}{{ item.Number.toString().padStart(4, '0') }} -
            {{ item.Description1 }}
            <span v-if="item.priority" class="overline ml-4"
              >({{ item.priority.Name }})</span
            >
            <v-spacer></v-spacer>
            <v-chip
              class="ma-2"
              :color="getStatusInfo(item.StatusId).Color"
              v-if="!item.Obsolete"
              x-small
            >
              {{ getStatusInfo(item.StatusId).Status }}
            </v-chip>
          </v-card-title>
          <v-expand-transition>
            <div v-show="show[i]">
              <v-card-text :class="{ obsolete: item.Obsolete }">
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description1 }}
                </div>
                <div class="pre">{{ item.Description1 }}</div>
              </v-card-text>
              <v-card-text
                v-if="item.Description2"
                :class="{ obsolete: item.Obsolete }"
              >
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description2 }}
                </div>

                <div class="pre">{{ item.Description2 }}</div>
                <!-- <div v-if="urlify(item.omschrijving2)">
              <v-icon class="mr-2">
                mdi-web
              </v-icon>
              <a :href="urlify(item.omschrijving2)" target="_blank">Link</a>
            </div> -->
              </v-card-text>
              <v-card-text
                v-if="item.Description3"
                :class="{ obsolete: item.Obsolete }"
              >
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description3 }}
                </div>
                <div class="pre">{{ item.Description3 }}</div>
              </v-card-text>
              <v-card-text
                v-if="item.Description4"
                :class="{ obsolete: item.Obsolete }"
              >
                <div class="overline font-weight-black">
                  {{ item.requirement_type.Description4 }}
                </div>
                <div class="pre">{{ item.Description4 }}</div>
              </v-card-text>
            </div>
          </v-expand-transition>
          <v-card-actions>
            <v-btn
              v-if="$can('update', 'requirement')"
              color="indigo darken-4"
              @click="clickEdit(item)"
              text
            >
              <v-icon small dark> mdi-pencil </v-icon>
            </v-btn>
            <!-- <v-btn color="indigo darken-4" @click="deleteItem(i)" text>
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn> -->
            <v-spacer></v-spacer>

            <v-btn icon @click="toggle(i)">
              <v-icon>{{
                show[i] ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </section>
    </FeathersVuexFind>
    <v-btn
      v-if="$can('create', 'requirement')"
      elevation="0"
      class="mx-2"
      fab
      small
      color="primary"
      @click="clickAdd()"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <RequirementEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variantIdSelected"
      :type="requirementTypeId"
    />
  </div>
</template>

<script>
import { makeFindMixin, makeGetMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
import RequirementEdit from '@/components/Requirement/RequirementEdit';

export default {
  props: ['variantIdSelected', 'requirementTypeId', 'statusId'],
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeGetMixin({
      service: 'requirement-type',
      name: 'currentRequirementType',
      id: 'requirementTypeId',
      watch: 'requirementTypeId',
    }),
  ],
  components: { RequirementEdit },
  data() {
    return {
      show: [],
      detailDialog: false,
    };
  },
  methods: {
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    getStatusInfo(statusId) {
      let filtered = this.status.filter((item) => {
        return item.id == statusId;
      });
      return filtered[0];
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
  },
  computed: {
    ...mapGetters('process', { getProcessInStore: 'get' }),
    ...mapGetters('variant', { findVariantInStore: 'find' }),
    fullProcess() {
      if (this.variantIdSelected) {
        let currentVariant = this.findVariantInStore({
          query: { id: this.variantIdSelected },
        }).data[0];
        let currentProcess = this.getProcessInStore(currentVariant.ProcessId);
        return (
          this.$appConfig.customer.prefix +
          currentProcess.Number.toString().padStart(4, '0') +
          '.' +
          currentVariant.Number.toString().padStart(2, '0') +
          ' ' +
          currentVariant.Name
        );
      } else {
        return '';
      }
    },
    filter() {
      if (this.variantIdSelected && this.requirementTypeId) {
        return {
          query: {
            VariantId: this.variantIdSelected,
            RequirementTypeId: this.requirementTypeId,
            $sort: {
              Description1: 1,
            },
          },
        };
      } else if (this.requirementTypeId) {
        return {
          query: {
            RequirementTypeId: this.requirementTypeId,
            $sort: {
              Number: 1,
            },
          },
        };
      } else if (this.statusId) {
        return {
          query: {
            StatusId: parseInt(this.statusId),
            $sort: {
              Number: 1,
            },
          },
        };
      } else {
        return {
          query: {
            $sort: {
              Number: 1,
            },
          },
        };
      }
    },
    statusParams() {
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.obsolete {
  text-decoration: line-through;
}
</style>
