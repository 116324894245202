import { render, staticRenderFns } from "./AddTask.vue?vue&type=template&id=db36dc6e&scoped=true"
import script from "./AddTask.vue?vue&type=script&lang=js"
export * from "./AddTask.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db36dc6e",
  null
  
)

export default component.exports