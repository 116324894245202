<template>
  <!-- <v-dialog :value="true" persistent max-width="800px"> -->
  <v-card flat dense class="mt-5">
    <v-card-title>
      <span class="headline">Reorder Process Steps</span>
    </v-card-title>
    <draggable v-model="list" v-bind="dragOptions">
      <transition-group>
        <v-card elevation="1" v-for="item in list" :key="item.id" class="mb-2">
          <v-card-text class="py-0">
            {{ item.Number }}. {{ item.Name }}"
          </v-card-text>
        </v-card>
      </transition-group>
    </draggable>
    <v-card-actions
      ><v-spacer></v-spacer>
      <v-btn outlined @click="showReOrderDialog = false">Cancel</v-btn>
      <v-btn color="primary" @click="saveSteps()"> Save </v-btn>
    </v-card-actions>
  </v-card>
  <!-- </v-dialog> -->
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import draggable from 'vuedraggable';
export default {
  props: ['variantId'],
  name: 'Reorder',
  data() {
    return {
      list: [],
    };
  },
  components: { draggable },
  mixins: [makeFindMixin({ service: 'process-step' })],
  // watch: {
  //   processStep() {
  //     this.list = this.processStep.sort((a, b) =>
  //       a.Number > b.Number ? 1 : -1
  //     );
  //   },
  // },
  methods: {
    saveSteps() {
      this.list.forEach((p, index) => {
        feathersClient
          .service('process-step')
          .patch(p.id, { Number: index + 1 });
      });
      this.showReOrderDialog = false;
    },
  },
  computed: {
    processStepParams() {
      return { query: { VariantId: this.variantId } };
    },
    showReOrderDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  mounted() {
    this.list = this.processStep.sort((a, b) => (a.Number > b.Number ? 1 : -1));
  },
};
</script>

<style></style>
