<template>
  <div>
    <v-flex justify-center>
      <v-btn class="mt-2" color="primary" @click="showSendOverview = true"
        >Send Invites ({{ plannerEvents.length }})</v-btn
      >
    </v-flex>

    <MaskedTextBoxComponent
      id="maskObj"
      class="ml-2"
      :change="searchNodes"
      :showClearButton="true"
      placeholder="Search"
    ></MaskedTextBoxComponent>
    <TreeViewComponent
      id="Tree"
      v-if="fields.dataSource"
      :fields="fields"
      :allowDragAndDrop="true"
      :nodeDragging="onItemDrag"
      :nodeDragStop="onTreeDragStop"
      :nodeTemplate="'treeTemplate'"
      :enablePersistence="true"
    >
      <template v-slot:treeTemplate="{ data }">
        <div>
          <TooltipComponent
            :content="`Current Status: <b>${data.MinStatus?.MinStatus}</b>`"
            target="#target"
          >
            {{ data.Number }}. <span id="target">{{ data.Name }}</span>
            <v-chip
              v-if="data.MinStatus"
              class="ml-2"
              :color="data.MinStatus?.PlanStatusColor"
              x-small
              :text-color="
                applyDark(data.MinStatus?.PlanStatusColor) ? 'white' : 'black'
              "
            >
              {{ data.MinStatus?.PlanStatus }}
            </v-chip>
          </TooltipComponent>
        </div>
      </template></TreeViewComponent
    >
    <SendOverview v-if="showSendOverview" v-model="showSendOverview" />
  </div>
</template>

<script>
import { applyDark } from '@/utils/Utilities';
import feathersClient from '@/feathers-client';

import { TreeViewComponent } from '@syncfusion/ej2-vue-navigations';
import { closest, addClass } from '@syncfusion/ej2-base';
import { MaskedTextBoxComponent } from '@syncfusion/ej2-vue-inputs';
import { TooltipComponent } from '@syncfusion/ej2-vue-popups';

import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';

import SendOverview from './SendOverview.vue';

import { makeFindMixin } from 'feathers-vuex';

export default {
  data() {
    return {
      showSendOverview: false,
      search: null,
      filter: null,
      TreeData: null,
      template:
        "<div><img class='eimage' :src='data.eimg' alt='employee'/><div class='ename'></div><div class='ejob'></div></div>",
      fields: {
        dataSource: null,
        id: 'id',
        text: 'Name',
        child: 'children',
      },
    };
  },
  mixins: [
    makeFindMixin({
      service: 'planner-events',
    }),
  ],
  components: {
    TreeViewComponent,
    MaskedTextBoxComponent,
    TooltipComponent,
    SendOverview,
  },
  computed: {
    plannerEventsParams() {
      return {
        query: {
          Sent: false,
          StartDate: { $gte: new Date().toISOString() },
          $or: [
            { Attendees: { $ne: [] } }, // Attendees is not an empty array
            { Attendees: { $ne: null } }, // Attendees is not null
          ],
        },
      };
    },
  },
  methods: {
    applyDark,
    // onActionBegin: function (event) {
    //   if (event.requestType === 'eventCreate' && this.draggedItemId) {
    //     let treeObj = document.getElementById('Tree').ej2_instances[0];
    //     let treeViewdata = treeObj.fields.dataSource;
    //     let filteredPeople = treeViewdata.filter(this.dataFilter);
    //     treeObj.fields.dataSource = filteredPeople;
    //     let elements = document.querySelectorAll(
    //       '.e-drag-item.treeview-external-drag'
    //     );
    //     for (let i = 0; i < elements.length; i++) {
    //       remove(elements[i]);
    //     }
    //   }
    // },
    onItemDrag: function (event) {
      let scheduleObj =
        this.$parent.$children[2]?.$refs?.scheduleObj?.ej2Instances ??
        this.$parent.$children[1]?.$refs?.scheduleObj?.ej2Instances ??
        this.$parent.$children[0]?.$refs?.scheduleObj?.ej2Instances;
      if (scheduleObj.isAdaptive) {
        let classElement = scheduleObj.element.querySelector('.e-device-hover');
        if (classElement) {
          classElement.classList.remove('e-device-hover');
        }
        if (event.target.classList.contains('e-work-cells')) {
          addClass([event.target], 'e-device-hover');
        }
      }
      if (document.body.style.cursor === 'not-allowed') {
        document.body.style.cursor = '';
      }
      if (event.name == 'nodeDragging') {
        let dragElementIcon = document.querySelectorAll(
          '.e-drag-item .e-icon-expandable'
        );
        for (let i = 0; i < dragElementIcon.length; i++) {
          dragElementIcon[i].style.display = 'none';
        }
      }
    },
    dataFilter: function (item) {
      return item.Id !== parseInt(this.draggedItemId, 10);
    },
    findNodeById(tree, targetId) {
      for (var i = 0; i < tree.length; i++) {
        var node = tree[i];

        // Check if the current node has the target ID
        if (node.id == targetId) {
          return node; // Return the matching node
        }

        // Check if the current node has children
        if (node.children && node.children.length > 0) {
          // Recursively call the function for each child
          var matchingChild = this.findNodeById(node.children, targetId);

          // If a matching child is found, return it
          if (matchingChild) {
            return matchingChild;
          }
        }
      }

      // Return null if no matching node is found
      return null;
    },
    onTreeDragStop: function (event) {
      let scheduleObj =
        this.$parent.$children[2]?.$refs?.scheduleObj?.ej2Instances ??
        this.$parent.$children[1]?.$refs?.scheduleObj?.ej2Instances ??
        this.$parent.$children[0]?.$refs?.scheduleObj?.ej2Instances;
      let treeElement = closest(event.target, '.e-treeview');
      let classElement = scheduleObj.element.querySelector('.e-device-hover');
      if (classElement) {
        classElement.classList.remove('e-device-hover');
      }
      if (!treeElement) {
        event.cancel = true;
        let scheduleElement = closest(event.target, '.e-content-wrap');
        if (scheduleElement) {
          let treeObj = document.getElementById('Tree').ej2_instances[0];
          let treeviewData = treeObj.fields.dataSource;

          if (event.target.classList.contains('e-work-cells')) {
            let filteredData = this.findNodeById(
              treeviewData,
              event.draggedNodeData.id
            );
            let scheduleObj =
              this.$parent.$children[2]?.$refs?.scheduleObj?.ej2Instances ??
              this.$parent.$children[1]?.$refs?.scheduleObj?.ej2Instances ??
              this.$parent.$children[0]?.$refs?.scheduleObj?.ej2Instances;
            let cellData = scheduleObj.getCellDetails(event.target);
            let startDate = new Date(cellData.startTime);
            let eventData = {
              Id: scheduleObj.getEventMaxID() + 1,
              Subject: filteredData.Name,
              // +
              // (filteredData.MinStatus?.PlanStatus
              //   ? ` (${filteredData.MinStatus.PlanStatus})`
              //   : ''),
              StartDate: cellData.startTime,
              EndDate: startDate.setHours(startDate.getHours() + 4), //cellData.endTime,
              IsAllDay: false, //cellData.isAllDay,
              PlanStatusId: filteredData.MinStatus?.PlanStatusId,
              Description: '',
              Sent: false,
              ProcessId: filteredData.ProcessId,
              VariantId: filteredData.VariantId,
              ProcessStepId: filteredData.ProcessStepId,
              EventType: 'Work Shop',
              Attendees: filteredData.ProjectRoles,
            };
            this.draggedItemId = event.draggedNodeData.id;
            // scheduleObj.addEvent(eventData);
            scheduleObj.openEditor(eventData, 'Add', true);
          }
        }
      }
    },
    changeDataSource: function (data) {
      this.fields = {
        dataSource: data,
        id: 'id',
        text: 'Name',
        child: 'children',
      };
    },

    searchNodes: function () {
      var maskObj = document.getElementById('maskObj').ej2_instances[0];
      var _text = maskObj.element.value;
      var predicats = [],
        _array = [],
        _filter = [];
      if (_text === '') {
        this.changeDataSource(this.TreeData);
        var treeObj = document.getElementById('Tree').ej2_instances[0];
        treeObj.collapseAll();
      } else {
        var predicate = new Predicate('name', 'contains', _text, true);
        var filteredList = new DataManager(this.TreeData).executeLocal(
          new Query().where(predicate)
        );
        for (var j = 0; j < filteredList.length; j++) {
          _filter.push(filteredList[j]['id']);
          let filters = this.getFilterItems(filteredList[j], this.TreeData);
          for (let i = 0; i < filters.length; i++) {
            if (_array.indexOf(filters[i]) === -1 && filters[i] != null) {
              _array.push(filters[i]);
              predicats.push(new Predicate('id', 'equal', filters[i], false));
            }
          }
        }
        if (predicats.length === 0) {
          this.changeDataSource([]);
        } else {
          var query = new Query().where(Predicate.or(predicats));
          var newList = new DataManager(this.TreeData).executeLocal(query);
          this.changeDataSource(newList);
          setTimeout(function () {
            var treeObj = document.getElementById('Tree').ej2_instances[0];
            treeObj.expandAll();
          }, 1000);
        }
      }
    },

    getFilterItems(fList, list) {
      var nodes = [];
      nodes.push(fList['id']);
      var query2 = new Query().where('id', 'equal', fList['pid'], false);
      var fList1 = new DataManager(list).executeLocal(query2);
      if (fList1.length !== 0) {
        let pNode = this.getFilterItems(fList1[0], list);
        for (let i = 0; i < pNode.length; i++) {
          if (nodes.indexOf(pNode[i]) === -1 && pNode[i] != null)
            nodes.push(pNode[i]);
        }
        return nodes;
      }
      return nodes;
    },

    async getTreeData() {
      let end2End = await feathersClient.service('end-2-end').find({});
      let process = await feathersClient.service('process').find({});
      let processStep = await feathersClient.service('process-step').find({});
      let variant = await feathersClient.service('variant').find({});
      let projectRoleMapping = await feathersClient
        .service('export-queries')
        .find({ query: { type: 'statusRoleMappingUser' } });
      if (end2End.total > 0) {
        this.TreeData = end2End.data
          .map((i) => {
            return {
              id: i.id.toString(),
              End2EndId: i.id,
              Name: i.Name,
              Number: i.Number,
              MinStatus: i.minStatus,
              Order: i.Number,
              ProjectRoles: null,
              children: i.processes
                ?.map((i2) => {
                  let p = process.data.find((f) => f.id == i2.id);
                  return {
                    id: `${i.id}.${p.id}`,
                    End2EndId: i.id,
                    ProcessId: p.id,
                    Name: p.Name,
                    Number: p.Number,
                    MinStatus: p.minStatus,
                    Order: i2.end2End_process.Order,
                    ProjectRoles:
                      projectRoleMapping
                        .find(
                          (f) =>
                            f.StatusId == p.minStatus?.PlanStatusId &&
                            f.DepartmentId == null &&
                            f.ProcessId == p.id &&
                            f.VariantId == null
                        )
                        ?.Users.split(',') ??
                      projectRoleMapping
                        .find(
                          (f) =>
                            f.StatusId == p.minStatus?.PlanStatusId &&
                            f.DepartmentId == p.DepartmentId &&
                            f.ProcessId == null &&
                            f.VariantId == null
                        )
                        ?.Users.split(',') ??
                      projectRoleMapping
                        .find(
                          (f) =>
                            f.StatusId == p.minStatus?.PlanStatusId &&
                            f.DepartmentId == null &&
                            f.ProcessId == null &&
                            f.VariantId == null
                        )
                        ?.Users.split(','),
                    children: variant.data
                      .filter((f) => f.ProcessId == i2.id)
                      ?.map((i3) => {
                        return {
                          id: `${i.id}.${i2.id}.${i3.id}`,
                          End2EndId: i.id,
                          ProcessId: i2.id,
                          VariantId: i3.id,
                          Name: i3.Name,
                          Number: i3.Number,
                          MinStatus: i3.minStatus,
                          Order: i3.Number,
                          ProjectRoles:
                            projectRoleMapping
                              .find(
                                (f) =>
                                  f.StatusId == i3.minStatus?.PlanStatusId &&
                                  f.DepartmentId == null &&
                                  f.ProcessId == null &&
                                  f.VariantId == i3.id
                              )
                              ?.Users.split(',') ??
                            projectRoleMapping
                              .find(
                                (f) =>
                                  f.StatusId == i3.minStatus?.PlanStatusId &&
                                  f.DepartmentId == null &&
                                  f.ProcessId == p.id &&
                                  f.VariantId == null
                              )
                              ?.Users.split(',') ??
                            projectRoleMapping
                              .find(
                                (f) =>
                                  f.StatusId == i3.minStatus?.PlanStatusId &&
                                  f.DepartmentId == p.DepartmentId &&
                                  f.ProcessId == null &&
                                  f.VariantId == null
                              )
                              ?.Users.split(',') ??
                            projectRoleMapping
                              .find(
                                (f) =>
                                  f.StatusId == i3.minStatus?.PlanStatusId &&
                                  f.DepartmentId == null &&
                                  f.ProcessId == null &&
                                  f.VariantId == null
                              )
                              ?.Users.split(','),
                          children: processStep.data
                            .filter((f) => f.VariantId == i3.id)
                            .map((i4) => {
                              let ps = processStep.data.find(
                                (f) => f.id == i4.id
                              );
                              return {
                                id: `${i.id}.${i2.id}.${i3.id}.${i4.id}`,
                                End2EndId: i.id,
                                ProcessId: i2.id,
                                VariantId: i3.id,
                                ProcessStepId: ps.id,
                                Name: ps.Name,
                                Number: ps.Number,
                                MinStatus: ps.minStatus,
                                Order: ps.Number,
                                ProjectRoles:
                                  projectRoleMapping
                                    .find(
                                      (f) =>
                                        f.StatusId ==
                                          ps.minStatus?.PlanStatusId &&
                                        f.DepartmentId == null &&
                                        f.ProcessId == null &&
                                        f.VariantId == i3.id
                                    )
                                    ?.Users.split(',') ??
                                  projectRoleMapping
                                    .find(
                                      (f) =>
                                        f.StatusId ==
                                          ps.minStatus?.PlanStatusId &&
                                        f.DepartmentId == null &&
                                        f.ProcessId == p.id &&
                                        f.VariantId == null
                                    )
                                    ?.Users.split(',') ??
                                  projectRoleMapping
                                    .find(
                                      (f) =>
                                        f.StatusId ==
                                          ps.minStatus?.PlanStatusId &&
                                        f.DepartmentId == p.DepartmentId &&
                                        f.ProcessId == null &&
                                        f.VariantId == null
                                    )
                                    ?.Users.split(',') ??
                                  projectRoleMapping
                                    .find(
                                      (f) =>
                                        f.StatusId ==
                                          ps.minStatus?.PlanStatusId &&
                                        f.DepartmentId == null &&
                                        f.ProcessId == null &&
                                        f.VariantId == null
                                    )
                                    ?.Users.split(','),
                              };
                            })
                            .sort((a, b) => (a.Order > b.Order ? 1 : -1)),
                        };
                      })
                      .sort((a, b) => (a.Order > b.Order ? 1 : -1)),
                  };
                })
                .sort((a, b) => (a.Order > b.Order ? 1 : -1)),
            };
          })
          .sort((a, b) => (a.Order > b.Order ? 1 : -1));
      } else return [];

      this.fields = {
        dataSource: this.TreeData,
        id: 'id',
        text: 'Name',
        child: 'children',
      };

      // this.fields.dataSource = this.TreeData;
    },
    async refresh() {
      await this.getTreeData();
      let treeObj = document.getElementById('Tree').ej2_instances[0];
      treeObj.refresh();
    },
  },

  async mounted() {
    await this.getTreeData();
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-device-hover {
  background-color: #e0e0e0 !important;
}

.schedule-container {
  padding-right: 10px;
}

.title-container {
  padding-bottom: 10px;
}

.treeview-external-drag .e-list-text,
.e-bigger .treeview-external-drag .e-list-text {
  background: white;
  border: 0.5px solid #e1e7ec;
  height: 30px;
  line-height: 15px;
  padding: 5px;
  width: 220px;
}

.treeview-external-drag .e-list-parent,
.e-bigger .treeview-external-drag .e-list-parent {
  height: 100%;
  padding: 0 2px;
}

.treeview-external-drag .e-list-item,
.e-bigger .treeview-external-drag .e-list-item {
  height: 100%;
  padding: 0 0 5px 0;
}

.treeview-external-drag .e-fullrow,
.e-bigger .treeview-external-drag .e-fullrow {
  height: 55px;
}

.treeview-external-drag .e-list-item.e-hover > .e-fullrow,
.treeview-external-drag .e-list-item.e-active > .e-fullrow,
.treeview-external-drag .e-list-item.e-active.e-hover > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-hover > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active.e-hover > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none !important;
}

.treeview-external-drag .e-text-content,
.e-bigger .treeview-external-drag .e-text-content {
  padding: 0;
}

.e-drag-item.e-treeview.treeview-external-drag,
.e-bigger .e-drag-item.e-treeview.treeview-external-drag {
  padding: 0 !important;
}

.title-text {
  font-size: 18px;
  margin: 0px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 550px) {
  .content-wrapper {
    display: block;
  }

  .schedule-container {
    padding-bottom: 10px;
  }

  .treeview-external-drag.e-treeview,
  .e-bigger .treeview-external-drag.e-treeview {
    width: 225px;
  }

  .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
    position: relative !important;
  }
}
/* csslint ignore:end */
</style>
