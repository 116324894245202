<template>
  <div>
    <v-card>
      <v-card-title>Response Send In Phase 2</v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Vendor</th>
                <th class="text-left">Deadline</th>
                <th class="text-left">Send In Date</th>
                <th class="text-left">Send In Comment</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="v in vendor"
                :key="v.id"
                :class="
                  checkDate(v.SendInDate2, v.Deadline2) == 1 ? 'late' : ''
                "
              >
                <td>{{ v.Name }}({{ v.Package }})</td>
                <td>
                  {{
                    v.Deadline2
                      ? new Date(v.Deadline2).toLocaleString([], {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                      : ''
                  }}
                </td>
                <td>
                  {{
                    v.SendInDate2
                      ? new Date(v.SendInDate2).toLocaleString([], {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                      : ''
                  }}
                </td>
                <td>{{ v.SendInComment2 }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';

export default {
  mixins: [makeFindMixin({ service: 'vendor' })],
  computed: {
    vendorParams() {
      return { query: { ResponsePhase: 2, $sort: { Name: 1 } } };
    },
  },
  methods: {
    checkDate(SendDate, Deadline) {
      if (Deadline && !SendDate) {
        let deadline = new Date(new Date(Deadline).toDateString());
        let today = new Date();
        return today > deadline;
      }
      if (Deadline && SendDate) {
        let deadline = new Date(new Date(Deadline).toDateString());
        let sendDate = new Date(new Date(SendDate).toDateString());
        return sendDate > deadline;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.late {
  background-color: #ffcdd2;
}
</style>
