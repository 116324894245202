<template>
  <v-container>
    <h1>Test Run</h1>
    <TestScenarioRun
      v-if="!isGetTestScenarioRunPending && !isGetTestScenarioRunDetailPending"
      :testScenarioRun="testScenarioRun"
      :testScenarioRunDetail="testScenarioRunDetail"
      :item="testScenarioRunDetail"
      :ro="true"
      @cancel="showDialog = false"
    />
    <!-- <v-overlay :value="!RunNumber">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
  </v-container>
</template>

<script>
import { makeGetMixin } from 'feathers-vuex';
import TestScenarioRun from '@/components/TestScenarios/TestScenarioRun/TestScenarioRun.vue';
export default {
  data() {
    return {
      RunNumber: null,
    };
  },
  components: { TestScenarioRun },
  watch: {
    '$route.params.id': {
      handler: function () {},
      deep: true,
      immediate: true,
    },
    // hasTestScenarioRunDetailLoadedOnce: {
    //   handler: async function () {
    //     const { TestScenarioRunDetail, TestScenarioRun } =
    //       this.$FeathersVuex.api;

    //     try {
    //       let RunDetail = await TestScenarioRunDetail.get(
    //         this.testScenarioRunDetail.id
    //       );

    //       if (RunDetail) {
    //         let Run = await TestScenarioRun.get(RunDetail.TestScenarioRunId);
    //         if (Run) {
    //           this.RunNumber = Run.Number;
    //         }
    //       }
    //     } catch (error) {
    //       //
    //     }
    //   },
    // },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    TestScenarioRunId() {
      return this.testScenarioRunDetail?.TestScenarioRunId;
    },
  },
  mixins: [
    makeGetMixin({
      service: 'test-scenario-run-detail',
      id: 'id',
      watch: 'id',
    }),

    makeGetMixin({
      service: 'test-scenario-run',
      id: 'TestScenarioRunId',
      watch: 'isGetTestScenarioRunDetailPending',
    }),
  ],
};
</script>

<style lang="scss" scoped></style>
