<template>
  <v-dialog v-model="showDialog" width="1000" height="500">
    <v-card flat>
      <v-card-title>
        <span class="headline">Add Project Roles for {{ item.Status }}</span
        ><v-spacer></v-spacer>
      </v-card-title>
      <section>
        <v-data-table
          :headers="headers"
          :items="item.status_project_roles"
          :items-per-page="20"
          :sort-by="['Order']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          disable-pagination
          :hide-default-footer="true"
          :loading="isFindProjectRolesPending"
          loading-text="Loading... Please wait"
        >
          <template v-if="$can('create', 'status_project_role')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Project Role</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.ProjectRoleId"
                              :items="projectRoles"
                              item-text="Role"
                              item-value="id"
                              label="Project Role"
                              outlined
                              dense
                              hide-details="true"
                              :rules="[rules.required]"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('delete', 'status_project_role')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    value: { type: Boolean },
    item: {},
  },
  mixins: [makeFindMixin({ service: 'project-roles' })],
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      dialog: false,
      validInput: false,
      editedItem: {},
      selectedType: null,
    };
  },
  methods: {
    async save(item) {
      try {
        await feathersClient.service('status-project-role').patch(item.id, {
          ProjectRoleId: item.ProjectRoleId,
        });
        handleSaveResponse(item.id, 'Status Project Role', 'updated');
      } catch (error) {
        handleErrorResponse(error);
      }
      await this.Refresh();
    },
    open() {},
    cancel() {},
    close() {},
    Close() {
      this.dialog = false;
      this.validInput = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    async Refresh() {
      console.log(this.$FeathersVuex.api);
      const { StatusProjectRole, Status } = this.$FeathersVuex.api;
      await StatusProjectRole.find({
        query: {},
      });
      await Status.find();
    },

    async Add() {
      this.editedItem.StatusId = this.item.id;
      try {
        await feathersClient
          .service('status-project-role')
          .create(this.editedItem);
        handleSaveResponse('', 'Status Project Role', 'added');
      } catch (error) {
        handleErrorResponse(error);
      }
      await this.Refresh();

      this.Close();
    },
    async deleteItem(item) {
      await feathersClient
        .service('status-project-role')
        .remove(item.id)
        .then(() =>
          handleSaveResponse(item.id, 'Status Project Role', 'removed')
        )
        .catch((error) => handleErrorResponse(error));
      await this.Refresh();
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    projectRolesParams() {
      return {};
    },

    headers() {
      return [
        {
          text: 'Code',
          align: 'start',
          sortable: false,
          value: 'ProjectRole.Code',
        },
        {
          text: 'Role',
          align: 'start',
          sortable: true,
          value: 'ProjectRole.Role',
        },

        { text: 'Delete', align: 'center', value: 'actions', sortable: false },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
