<template>
  <div>
    <v-dialog v-model="showDialog" max-width="1000px">
      <v-card flat class="pb-2">
        <v-card-title>
          <span class="headline">Bug List - {{ item.Name }}</span>
        </v-card-title>
        <v-card-text
          ><v-data-table
            :headers="headers"
            :items="filteredBugs"
            :items-per-page="10"
            :sort-by="['BugClosed', 'BugId']"
            :sort-desc="[false, true]"
            class="elevation-1"
            :loading="isFindExportQueriesPending"
            loading-text="Loading... Please wait"
            ><template v-slot:[`item.BugId`]="{ item }">
              <a target="_blank" :href="item.BugUrl">{{
                item.BugId
              }}</a> </template
            ><template v-slot:[`item.BugReport`]="{ item }">
              <span
                class="text-body-2 ma-0 ql-editor small"
                v-html="item.BugReport"
              >
              </span> </template
            ><template v-slot:[`item.BugClosed`]="{ item }">
              <span v-if="item.BugClosed">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span> </template
            ><template v-slot:[`item.BugBlocking`]="{ item }">
              <span v-if="item.BugBlocking">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
            </template></v-data-table
          >
        </v-card-text></v-card
      ></v-dialog
    >
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    value: { type: Boolean },
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      Required: true,
    },
  },
  mixins: [makeFindMixin({ service: 'export-queries' })],

  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    exportQueriesParams() {
      return {
        query: {
          type: 'buglist',
        },
      };
    },
    filteredBugs() {
      return this.exportQueriesLatestQuery
        ? this.exportQueriesLatestQuery.response.filter((f) =>
            this.type == 'ProcessStep'
              ? f.ProcessStepId == this.item.id
              : this.type == 'Variant'
              ? f.VariantId == this.item.id
              : this.type == 'TestScenarioStep'
              ? f.ProcessStepId == this.item.TestScenarioStep.ProcessStepId
              : false
          )
        : [];
    },
    headers() {
      return [
        {
          text: 'Bug Id',
          align: 'start',
          sortable: true,
          value: 'BugId',
          width: '10%',
          cellClass: 'topalign',
        },
        {
          text: 'Step',
          align: 'start',
          sortable: true,
          value: 'Name',
          width: '25%',
          cellClass: 'topalign',
        },

        {
          text: 'Bug Report',
          align: 'start',
          sortable: false,
          value: 'BugReport',
          cellClass: 'topalign',
        },
        {
          text: 'Bug Closed',
          align: 'center',
          sortable: true,
          value: 'BugClosed',
          width: '5%',
          cellClass: 'topalign',
        },
        {
          text: 'Bug Blocking',
          align: 'center',
          sortable: true,
          value: 'BugBlocking',
          width: '5%',
          cellClass: 'topalign',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
