<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <v-dialog :value="true" persistent max-width="1100px">
        <VariantEditDialog
          :item="clone"
          :saving="saving"
          @save="
            saving = true;
            save()
              .then(async (saved) => {
                handleSaveResponse(
                  saved.Name,
                  'variant',
                  currentItem ? 'updated' : 'created'
                );
                await refresh();
                showDialog = false;
                saving = false;
              })
              .catch(async (error) => {
                handleErrorResponse(error);
                await refresh();
                saving = false;
              });
          "
          @reset="reset"
          @remove="
            remove()
              .then(async (saved) => {
                handleSaveResponse(saved.Name, 'variant', 'removed');
                await refresh();
                showDialog = false;
              })
              .catch(async (error) => {
                handleErrorResponse(error);
                await refresh();
              })
          "
          @cancel="showDialog = false"
        ></VariantEditDialog>
      </v-dialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import { makeGetMixin } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import VariantEditDialog from '@/components/Variant/VariantEditDialog';

export default {
  name: 'VariantEdit',
  components: {
    FeathersVuexFormWrapper,
    VariantEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    process: {},
  },
  mixins: [
    makeGetMixin({
      service: 'variant',
      id: 'id',
      watch: 'params',
    }),
  ],
  watch: {
    currentItem() {
      const { Variant } = this.$FeathersVuex.api;

      if (this.variant) {
        this.item = this.variant;
      } else {
        let newVariant = new Variant();
        newVariant.ProcessId = this.process;
        this.item = newVariant;
      }
    },
  },
  computed: {
    id() {
      return this.currentItem ? this.currentItem.id : 0;
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Variant, Process } = this.$FeathersVuex.api;
      await Process.find({
        query: {},
      });
      await Variant.find({
        query: {},
      });
    },
  },
  mounted() {
    const { Variant } = this.$FeathersVuex.api;

    if (this.variant) {
      this.item = this.variant;
    } else {
      let newVariant = new Variant();
      newVariant.ProcessId = this.process;
      this.item = newVariant;
    }
  },
};
</script>
