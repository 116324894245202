<template>
  <div>
    <v-dialog :value="true" persistent max-width="600px">
      <v-progress-linear
        v-if="isFindErpPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindErpPending">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">Add App to Variant</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="10">
                  <v-autocomplete
                    v-model="item['ErpApplicationId']"
                    :items="erp"
                    item-text="Name"
                    item-value="id"
                    label="Application Form"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    v-if="$can('create', 'erpapplication-variant')"
                    fab
                    elevation="0"
                    x-small
                    class="mt-1"
                    color="primary"
                    @click="clickAddNewApp()"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </v-card-text>
            <v-card-text v-if="detailDialogNewApp">
              <v-form ref="form" v-model="validInput2">
                <v-card flat>
                  <h3>Add New Application Form</h3>

                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="newApp.Name"
                          label="Name"
                          outlined
                          dense
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea
                          v-model="newApp.Filter"
                          label="Path"
                          outlined
                          dense
                          rows="1"
                          auto-grow
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-textarea
                          v-model="newApp.Link"
                          label="Link"
                          outlined
                          dense
                          rows="1"
                          auto-grow
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="newApp.Customized"
                          label="Customized"
                          outlined
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="!validInput2"
                      color="primary"
                      @click="addApp"
                    >
                      Add
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                @click="$emit('save')"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  name: 'VariantAddAppDialog',
  data() {
    return {
      validInput: true,
      validInput2: true,
      errorMessage: null,
      showRemoveMessage: false,
      detailDialogNewApp: false,
      newApp: {},

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mixins: [makeFindMixin({ service: 'erp-application', name: 'erp' })],
  methods: {
    clickAddNewApp() {
      this.detailDialogNewApp = true;
    },
    addApp() {
      feathersClient
        .service('erp-application')
        .create(this.newApp)
        .then((saved) => {
          handleSaveResponse(saved.Name, 'Application Form', 'added');
          this.item.ErpApplicationId = saved.id;
          this.detailDialogNewApp = false;
        })
        .catch((error) => handleErrorResponse(error));
    },
  },
  computed: {
    erpParams() {
      return {};
    },
  },
};
</script>
