<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <E2EAddProcessDialog
        :item="clone"
        @save="
          showDialog = false;
          save()
            .then(handleSaveResponse)
            .catch(errorHandler);
        "
        @reset="reset"
        @remove="
          remove();
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></E2EAddProcessDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import E2EAddProcessDialog from '@/components/End2End/E2EAddProcessDialog';

export default {
  name: 'E2EAddProcess',
  components: {
    FeathersVuexFormWrapper,
    E2EAddProcessDialog,
  },
  data() {
    return {
      //item: null,
    };
  },
  props: {
    value: { type: Boolean },
    end2EndId: null,
  },
  computed: {
    item() {
      const { end2EndProcess } = this.$FeathersVuex.api;

      let newRecord = new end2EndProcess();
      newRecord.end2EndId = this.end2EndId;
      return newRecord;
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    async handleSaveResponse() {
      this.$toasted.global.success(`Saved successfully`);
      const { Process, end2EndProcess } = this.$FeathersVuex.api;
      await end2EndProcess.find();
      await Process.find({
        query: {},
      });
    },
    errorHandler(error) {
      //console.log(error);
      let errorMessage = '';
      if (
        Object.keys(error.errors).length === 0 &&
        error.errors.constructor === Object
      ) {
        errorMessage = error.message;
      } else {
        error.errors.map(item => {
          errorMessage += item.message + '\n';
        });
        this.errorMessage = errorMessage;
      }
      this.$toasted.global.error(errorMessage);
    },
  },
  async mounted() {
    const { end2EndProcess } = this.$FeathersVuex.api;

    let newRecord = new end2EndProcess();
    newRecord.end2EndId = this.end2EndId;
    return newRecord;
  },
};
</script>
