<template>
  <div>
    <v-dialog
      v-if="showSendOverview"
      v-model="showSendOverview"
      max-width="1000px"
    >
      <v-card class="mt-6 pt-6">
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="plannerEvents"
            :items-per-page="20"
            :sort-by="['StartDate']"
            :sort-desc="[false]"
            item-key="id"
            class="elevation-1"
            disable-pagination
            :hide-default-footer="true"
            :loading="isFindPlannerEventsPending"
            loading-text="Loading... Please wait"
            show-select
            v-model="selected"
          >
            <template v-slot:[`item.StartDate`]="{ item }">
              <span>{{ new Date(item.StartDate).toLocaleString() }}</span>
            </template>
            <template v-slot:[`item.Attendees`]="{ item }">
              <v-list dense>
                <v-list-item
                  v-for="(attendee, index) in item.Attendees"
                  :key="index"
                  >{{ attendee }}</v-list-item
                >
              </v-list>
            </template>
            <template v-slot:[`item.EndDate`]="{ item }">
              <span>{{ new Date(item.EndDate).toLocaleString() }}</span>
            </template>
            <template v-slot:[`item.Status`]="{ item }">
              <span v-if="item.MessageId == null && item.Deleted == false"
                >New</span
              >
              <span v-if="item.MessageId != null && item.Deleted == false"
                >Updated</span
              >
              <span v-if="item.MessageId != null && item.Deleted == true"
                >Deleted</span
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="showSendOverview = false">Cancel</v-btn>
          <v-btn
            :disabled="selected?.length == 0"
            :loading="saving"
            color="primary"
            @click="sendInvites"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import { createEvent } from 'ics';
import axios from 'axios';
export default {
  mixins: [
    makeFindMixin({
      service: 'planner-events',
    }),
  ],
  props: {
    value: { type: Boolean },
  },
  computed: {
    showSendOverview: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    plannerEventsParams() {
      return {
        query: {
          Sent: false,
          StartDate: { $gte: new Date().toISOString() },
          $or: [
            { Attendees: { $ne: [] } }, // Attendees is not an empty array
            { Attendees: { $ne: null } }, // Attendees is not null
          ],
        },
      };
    },
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  methods: {
    async sendInvites() {
      this.saving = true;
      for (const event of this.selected) {
        const StartDate = new Date(event.StartDate);
        const EndDate = new Date(event.EndDate);

        const difference = EndDate.getTime() - StartDate.getTime(); // This will give difference in milliseconds
        const durationInMinutes = Math.round(difference / 60000);

        console.log(
          event.Attendees.map((m) => {
            return { email: m, rsvp: true };
          })
        );

        let eventData = {
          title: event.Name,
          description: event.Description ? event.Description : '',
          busyStatus: 'BUSY',
          organizer: {
            name: this.user.fullname,
            email: this.user.email,
          },
          attendees: event.Attendees.map((m) => {
            return { name: m, email: m, rsvp: true };
          }),
          // attendees: [
          //   { name: 'Lode', email: 'lode.bovyn@foocus.be', rsvp: true },
          // ],
          start: [
            StartDate.getFullYear(),
            StartDate.getMonth() + 1,
            StartDate.getDate(),
            StartDate.getHours(),
            StartDate.getMinutes(),
          ],
          duration: { minutes: durationInMinutes },
          uid: event.MessageId ? event.MessageId : crypto.randomUUID(),
          method: event.Deleted ? 'cancel' : 'request', // cancel or request also change method when sending ical in nodemailer
        };

        const { error, value } = createEvent(eventData);

        console.log(error);

        const nodemailer = {
          from: this.user.email,
          to: event.Attendees,
          subject: event.Subject,
          html: event.Description,
          icalEvent: {
            filename: 'LoxiInvitation.ics',
            method: event.Deleted ? 'cancel' : 'request',
            content: btoa(value),
            encoding: 'base64',
          },
        };

        console.log(nodemailer, eventData);

        let url = this.$appConfig.apiBaseUrl + '/mailer'; // `http://localhost:3030/mailer`;
        console.log(eventData, nodemailer);
        try {
          await axios.post(
            url,
            nodemailer,

            {
              headers: {
                Authorization: window.localStorage.getItem('feathers-jwt'),
              },
            }
          );

          event.MessageId = eventData.uid;
          event.Sent = true;
          try {
            await event.save();
          } catch (error) {
            console.log(error);
          }
          // await feathersClient.service('planner-events').patch(event.id, {
          //   MessageId: eventData.uid,
          //   Sent: true,
          // });
        } catch (error) {
          console.log(error);
        }
      }
      await this.refresh();
      this.saving = false;
      this.showSendOverview = false;
      location.reload(); // Reload after save to make sure messageid is filled in TO DO maybi just reattach data to calendar
    },
    async refresh() {
      const { PlannerEvents } = this.$FeathersVuex.api;
      await PlannerEvents.find();
    },
  },
  data() {
    return {
      selected: [],
      saving: false,
      headers: [
        {
          text: 'Status',
          align: 'center',
          sortable: false,
          value: 'Status',
        },
        {
          text: 'Subject',
          align: 'start',
          sortable: false,
          value: 'Subject',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'Description',
        },
        {
          text: 'Attendees',
          align: 'start',
          sortable: false,
          value: 'Attendees',
        },
        {
          text: 'StartDate',
          align: 'start',
          sortable: false,
          value: 'StartDate',
        },
        {
          text: 'EndDate',
          align: 'start',
          sortable: false,
          value: 'EndDate',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
