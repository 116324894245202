// Also update globale in backend

const IssueFieldMapper = {
  Title: 'Title',
  Description: 'Content1',
  'Extra Description': 'Content2',
  Type: 'IssueType.Type',
  Status: 'Status.Status',
  Severity: 'Blocking',
  Url: 'Url',
  Process: 'ProcessId',
  CreatedUser: 'CreatedBy.ExternalId',
  ModifiedUser: 'ModifiedBy.ExternalId',
  CreatedDate: 'createdAt',
  ModfiedDate: 'updatedAt',
  Assignee: 'Assigned.ExternalId',
  'Due Date': 'DueDate',
  Department: 'Department',
  Id: 'ExternalId',
  Project: 'Project',
  Area: '',
  Iteration: '',
};

const IssueFieldsToMap = Object.keys(IssueFieldMapper);

const SyncTypes = ['No', 'Loxi', 'Extern', 'Both'];

// exports.IssueFieldMapper;
// export { IssueFieldsToMap, SyncTypes, IssueFieldMapper };
// For CommonJS (Node.js) style
if (typeof module !== 'undefined' && module.exports) {
  module.exports = { IssueFieldsToMap, SyncTypes, IssueFieldMapper };
}

// For ES6 (import) style
if (typeof exports !== 'undefined') {
  exports.IssueFieldsToMap = IssueFieldsToMap;
  exports.SyncTypes = SyncTypes;
  exports.IssueFieldMapper = IssueFieldMapper;
}
