<template>
  <v-card>
    <v-card-title>Response Phase 1 Progress</v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Vendor</th>
              <th class="text-left">Count</th>
              <th class="text-left">Progress</th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr v-for="v in vendor" :key="v.id">
              <td>{{ v.Name }}({{ v.Package }})</td>
              <td>{{ doneResponse(v.id) }}/{{ counterAll }}</td>
              <td>
                <v-progress-linear
                  :value="((doneResponse(v.id) / counterAll) * 100).toFixed(2)"
                  height="20"
                  dark
                  color="primary"
                >
                  <strong
                    >{{
                      ((doneResponse(v.id) / counterAll) * 100).toFixed(2)
                    }}%</strong
                  >
                </v-progress-linear>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td>
                <v-progress-circular
                  indeterminate
                  :size="15"
                  :width="1"
                ></v-progress-circular>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
export default {
  mixins: [
    makeFindMixin({ service: 'response' }),
    makeFindMixin({ service: 'vendor' }),
    makeFindMixin({ service: 'variant' }),
    makeFindMixin({ service: 'specification' }),
  ],
  computed: {
    loading() {
      return (
        this.isFinVendorPending ||
        this.isFindResponsePending ||
        this.isFindVariantPending ||
        this.isFindSpecificationPending
      );
    },
    responseParams() {
      return { query: { Final: true } };
    },
    vendorParams() {
      return { query: { $sort: { Name: 1 } } };
    },
    variantParams() {
      return {};
    },
    specificationParams() {
      return { query: { Obsolete: false } };
    },
    counterAll() {
      if (this.variant && this.specification) {
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );

        return this.variant.length + filteredSpecs.length;
      }
      return 9999999;
    },
  },
  methods: {
    doneResponse(v) {
      if (this.variant && this.specification && this.response) {
        // let filteredSpecs = this.specification.filter(
        //   (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        // );
        // let filteredResponse = this.response.filter(
        //   (f) => !filteredSpecs.some((s) => s.id == f.SpecificationId)
        // );
        return this.response.filter(
          (f) =>
            f.VendorId === v &&
            (f.SpecificationId !== null || f.VariantId !== null)
        ).length;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
