<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <FieldValueEditDialog
        :item="clone"
        @save="
          showDialog = false;
          save().then(handleSaveResponse).catch(errorHandler);
        "
        @reset="reset"
        @remove="
          remove();
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></FieldValueEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import FieldValueEditDialog from '@/components/FieldValues/FieldValueEditDialog';

export default {
  name: 'FieldValuesEdit',
  components: {
    FeathersVuexFormWrapper,
    FieldValueEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    fieldId: { type: Number },
  },
  computed: {
    item() {
      const { FieldValueList } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        let newValue = new FieldValueList();
        newValue.FieldId = this.fieldId;
        // console.log(newValue);
        return newValue;
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse(saved) {
      this.$toasted.global.success(
        `Field value '${saved.Value}' saved successfully`
      );
      // Redirect to the newly-saved item
      // if (this.id === 'new') {
      //   this.$router.push({ params: { id: savedTodo._id } });
      // }
    },
    errorHandler(error) {
      let errorMessage = '';
      error.errors.map((item) => {
        errorMessage += item.message + '\n';
      });
      this.errorMessage = errorMessage;
      this.$toasted.global.error(errorMessage);
    },
  },
};
</script>
