<template>
  <v-container>
    <v-card class="mt-5">
      <v-card-title>
        <span>Test Scenario Execution</span>
      </v-card-title>
      <v-row>
        <v-col cols="12" md="5" class="ml-2">
          Filters
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>

          <v-tooltip bottom v-if="filterupdated">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2 mb-1"
                color="primary"
                @click="saveFilter"
                v-bind="attrs"
                v-on="on"
                >mdi-filter-plus</v-icon
              ></template
            >Save Filter
          </v-tooltip>
          <v-tooltip bottom v-if="filterExists">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2 mb-1"
                color="primary"
                @click="clearFilter"
                v-bind="attrs"
                v-on="on"
                >mdi-filter-remove</v-icon
              ></template
            >Clear Filter</v-tooltip
          >
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-card flat v-if="show" class="my-0 py-2">
          <v-card-title>
            <v-row
              ><v-col cols="12" md="4" class="my-0 py-2"
                ><v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                ></v-text-field></v-col
              ><v-col cols="12" md="3" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.department"
                  :items="department"
                  item-text="Name"
                  item-value="id"
                  label="Department"
                  clearable
                  hide-details
                  multiple
                  dense
                  small-chips
                  @change="filterupdated = true"
                ></v-autocomplete></v-col
              ><v-col cols="12" md="3" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.user"
                  :items="users"
                  item-text="fullname"
                  item-value="id"
                  label="User"
                  clearable
                  hide-details
                  multiple
                  dense
                  @change="filterupdated = true"
                  ><template v-slot:item="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ><template #selection="{ item }">
                    <UserAvatar
                      :Name="item.fullname"
                      :Image="item.gravatarUrl"
                      :Color="item.color"
                    />
                    {{ item.fullname }}</template
                  ></v-autocomplete
                ></v-col
              ><v-col cols="12" md="2" class="my-0 py-2">
                <v-checkbox
                  v-model="selectionFilter.showAll"
                  label="Show All"
                  hide-details
                  dense
                ></v-checkbox></v-col
            ></v-row>
          </v-card-title>
        </v-card>
      </v-expand-transition>
      <v-data-table
        :headers="headers"
        :items="filteredTestScenarioRuns"
        sort-by="id"
        :sort-desc="true"
        class="text-pre-wrap"
        :loading="isFindTestScenarioRunPending"
        loading-text="Loading... Please wait"
        fixed-header
        height="calc(100vh - 400px)"
        :items-per-page="15"
        show-expand
        single-expand
      >
        <template
          v-if="$can('delete', 'test-scenario-run')"
          v-slot:[`item.delete`]="{ item }"
        >
          <v-icon small @click="DeleteConfirm(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length">
            <TestScenarioRunSteps
              class="ma-2"
              :testScenarioRun="item"
              :userList="userList"
            />
          </td>
        </template>
        <template v-slot:[`item.Assigned`]="{ item, index }">
          <v-menu v-if="item.currentTest">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-progress-circular
                      v-if="saving[index]"
                      indeterminate
                      :size="15"
                      :width="1"
                    ></v-progress-circular>
                    <v-icon v-if="!item.currentTest?.fullname && !saving[index]"
                      >mdi-account-plus</v-icon
                    >
                    <v-avatar
                      v-if="item.currentTest?.fullname && !saving[index]"
                      :class="
                        applyDark(item.currentTest?.color)
                          ? 'white--text caption'
                          : 'black--text caption'
                      "
                      text--color="white"
                      :color="item.currentTest?.color"
                      size="25"
                      ><img
                        v-if="item.currentTest?.gravatarUrl"
                        :src="item.currentTest?.gravatarUrl"
                        :alt="item.currentTest?.fullname"
                      />
                      <span v-else>{{
                        item.currentTest?.fullname
                          .match(/(\b\S)?/g)
                          .join('')
                          .match(/(^\S|\S$)?/g)
                          .join('')
                          .toUpperCase()
                      }}</span></v-avatar
                    >
                  </v-btn>
                </template>
                <span>{{
                  item.currentTest
                    ? item.currentTest?.fullname
                    : 'No User Assigned'
                }}</span>
              </v-tooltip>
            </template>
            <v-list dense style="max-height: 500px" class="overflow-y-auto">
              <v-list-item
                v-for="(user, index2) in userList"
                :key="user.id"
                :value="index2"
                @click="assignUser(index, item, user.id)"
              >
                <v-list-item-title
                  ><v-avatar
                    :class="
                      applyDark(user.color)
                        ? 'white--text caption mr-2'
                        : 'black--text caption mr-2'
                    "
                    text--color="white"
                    :color="user.color"
                    size="25"
                    ><img
                      v-if="user.gravatarUrl"
                      :src="user.gravatarUrl"
                      :alt="user.fullname"
                    />
                    <span v-else>{{
                      user.fullname
                        .match(/(\b\S)?/g)
                        .join('')
                        .match(/(^\S|\S$)?/g)
                        .join('')
                        .toUpperCase()
                    }}</span></v-avatar
                  >{{ user.fullname }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:[`item.Progress`]="{ item }">
          <v-progress-linear
            :value="((item.AllSteps - item.OpenSteps) / item.AllSteps) * 100"
            height="20"
            dark
            :class="
              applyDark(
                item.OpenSteps == 0
                  ? 'green'
                  : item.BlockingBug
                  ? '#B20000FF'
                  : item.OpenBug
                  ? '#FFC698'
                  : 'primary'
              )
                ? 'white--text'
                : 'black--text'
            "
            :color="
              item.OpenSteps == 0
                ? 'green'
                : item.BlockingBug
                ? '#B20000FF'
                : item.OpenBug
                ? '#FFC698'
                : 'primary'
            "
          >
            <strong
              >{{ item.AllSteps - item.OpenSteps }}/ {{ item.AllSteps }}</strong
            >
          </v-progress-linear>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showDeleteConfirm" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Delete Run </v-card-title>
        <v-card-text
          >Delete Test run {{ deleteItem ? deleteItem.id : '' }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="showDeleteConfirm = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="
              dialog = false;
              DeleteTestRun();
            "
          >
            Delete Run
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';

import TestScenarioRunSteps from '@/components/TestScenarios/TestScenarioRun/TestScenarioRunSteps.vue';
import UserAvatar from '@/components/general/UserAvatar.vue';
import { applyDark } from '@/utils/Utilities';
import feathersClient from '@/feathers-client';

import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  data() {
    return {
      selectionFilter: {
        showAll: false,
        user: [],
        department: [],
      },
      queryAllowed: false,
      filterExists: null,
      filterupdated: false,
      showDialog: false,
      showDeleteConfirm: false,
      deleteItem: null,
      currentItem: null,
      search: '',
      saving: [],
      show: true,
      reload: 0,
      headers: [
        {
          text: 'Delete',
          value: 'delete',
          align: 'center',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Run',
          value: 'id',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Test Number',
          value: 'Number',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Name',
          value: 'Name',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Current Test',
          value: 'currentTest.Name',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Current Department',
          value: 'currentTest.Department',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Current Assignee',
          value: 'Assigned',
          align: 'center',
          sortable: false,
          groupable: false,
        },

        {
          text: 'Progress',
          value: 'Progress',
          align: 'center',
          sortable: false,
          groupable: false,
          width: 100,
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  components: { TestScenarioRunSteps, UserAvatar },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run',
      queryWhen: 'queryAllowed',
      watch: [
        'uniqueTestScenarioRunIds',
        'selectionFilter.showAll',
        'selectionFilter.user.length',
        'selectionFilter.department.length',
        'reload',
      ],
    }),

    makeFindMixin({
      service: 'department',
    }),
    makeFindMixin({
      service: 'users',
    }),
  ],
  methods: {
    applyDark,
    saveFilter() {
      window.localStorage.setItem(
        'TestScenarioRunFilter',
        JSON.stringify(this.selectionFilter)
      );
      this.filterExists = true;
      this.filterupdated = false;
    },
    clearFilter() {
      window.localStorage.removeItem('TestScenarioRunFilter');
      this.selectionFilter = {};
      this.filterExists = false;
      this.filterupdated = false;
    },
    DeleteConfirm(item) {
      this.showDeleteConfirm = true;
      this.deleteItem = item;
    },
    async DeleteTestRun() {
      const { TestScenarioRun, TestScenarioRunDetail } = this.$FeathersVuex.api;
      try {
        await feathersClient
          .service('test-scenario-run')
          .remove(this.deleteItem.id);
      } catch (error) {
        handleErrorResponse(error);
      }
      handleSaveResponse('', 'Test Scenario Run', 'deleted');
      await TestScenarioRun.find({
        query: { id: this.deleteItem.TestScenarioRunId },
      });
      await TestScenarioRunDetail.find({
        query: { TestScenarioRunId: this.deleteItem.id },
      });
      this.reload = this.reload + 1;
      this.showDeleteConfirm = false;
    },
    async assignUser(index, item, id) {
      this.saving[index] = true;
      const { TestScenarioRun, TestScenarioRunDetail } = this.$FeathersVuex.api;
      let details = await TestScenarioRunDetail.find({
        query: { TestScenarioRunId: item.id },
      });
      if (details) {
        for (const test of details.data) {
          if (test.AllDone == false) {
            try {
              test.AssignedUserId = id;
              await test.save();
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        }
      }
      this.saving[index] = false;
      await TestScenarioRun.find({ query: { id: item.TestScenarioRunId } });
      await TestScenarioRunDetail.find({
        query: { TestScenarioRunId: item.id },
      });
    },
  },
  computed: {
    testScenarioRunParams() {
      const { showAll, department, user } = this.selectionFilter;

      const query = {};

      if (department?.length > 0) {
        query.customDepartment = { $in: department };
      }

      if (user?.length > 0) {
        query.customUser = { $in: user };
      }

      if (!showAll) {
        query.Done = false;
      }

      return { query };
    },

    usersParams() {
      return { query: { Active: true, $sort: { fullname: 1 } } };
    },

    userList() {
      let users = [...this.users];
      users.push({ id: null, fullname: '----' });
      return users;
    },
    departmentParams() {
      return { query: { $sort: { Name: 1 } } };
    },
    filteredTestScenarioRuns() {
      let filteredTests =
        this.testScenarioRunLatestQuery?.response?.data || this.testScenarioRun;
      // if (this.selectedDepartment) {
      //   filteredTests = filteredTests.filter(
      //     (item) =>
      //       item.currentTest?.DepartmentId == this.selectedDepartment ||
      //       this.uniqueTestScenarioRunIds.includes(item.id)
      //   );
      // }
      // if (this.selectedUser) {
      //   // Also check assigned user from the details

      //   filteredTests = filteredTests.filter(
      //     (item) =>
      //       item.currentTest?.UserId == this.selectedUser ||
      //       this.uniqueTestScenarioRunIds.includes(item.id)
      //   );
      // }
      // if (!this.showAll) {
      //   filteredTests = filteredTests.filter((item) => item.OpenSteps != 0);
      // }
      if (this.search)
        return filteredTests.filter(
          (item) =>
            item.id.toString().includes(this.search.toLowerCase()) ||
            item.Number.toString().includes(this.search.toLowerCase()) ||
            item.Name?.toLowerCase().includes(this.search.toLowerCase()) ||
            item.Description?.toLowerCase().includes(this.search.toLowerCase())
        );
      else return filteredTests;
    },
  },
  mounted() {
    if (window.localStorage.getItem('TestScenarioRunFilter') === null) {
      this.selectionFilter = {};
    } else {
      this.selectionFilter = JSON.parse(
        window.localStorage.getItem('TestScenarioRunFilter')
      );
      this.filterExists = true;
    }
    this.queryAllowed = true;
  },
};
</script>

<style lang="scss">
.greenHeader {
  th {
    background-color: #d1f5ed !important;
  }
}
.green2Header {
  th {
    background-color: #88ffe5 !important;
  }
}
</style>
