<template>
  <div class="mb-1">
    <v-list-item @click="$emit('clicked-steps')">
      <v-list-item-icon>
        <v-icon class="mr-2"> mdi-arrow-decision </v-icon>
      </v-list-item-icon>

      <span>Process Steps</span> <v-spacer></v-spacer>
      <StatusListIndividual
        v-if="statusList && statusList.length > 0"
        :statusList="statusList"
      />
    </v-list-item>
    <v-list-item>
      <v-icon small color="primary" @click="clickAddIssue()">
        mdi-login-variant
      </v-icon>
      <StatusListIndividual
        v-if="issueStatus && issueStatus.length > 0"
        class="ml-1"
        :statusList="issueStatus"
      />
    </v-list-item>
    <v-list-item v-if="bugStatusList && bugStatusList.length > 0">
      <v-icon small color="primary" @click="showBugs = true"> mdi-bug</v-icon>
      <StatusListIndividual class="ml-1" :statusList="bugStatusList" />
    </v-list-item>
    <BugList v-model="showBugs" :item="variant" :type="'Variant'" />
    <v-dialog
      v-if="issueDialog"
      v-model="issueDialog"
      width="100vw"
      height="auto"
      ><v-card height="100vh" class="pa-5">
        <IssueList
          v-if="issueDialog"
          v-model="issueDialog"
          :variant="variant"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import StatusListIndividual from '@/components/StatusListIndividual';
import BugList from '@/components/general/BugList';
import IssueList from '@/components/Issues/IssueList.vue';
export default {
  data() {
    return { showBugs: false, issueDialog: false, VariantId: null };
  },
  props: ['variant', 'statusList', 'issueStatus', 'bugStatusList'],
  components: { StatusListIndividual, BugList, IssueList },
  methods: {
    clickAddIssue() {
      this.issueDialog = true;
      // console.log(item.id);
    },
  },
};
</script>
