import feathersClient from '@/feathers-client';
import { GetWorkItem, FindWorkItem } from './DevOpsFunctions';

async function getField(application, field) {
  let mappings = await feathersClient.service('mappings').find({
    query: {
      Application: application,
      Field: field,
      // Order: '0',
      $sort: { Order: 1 },
      $limit: 1,
    },
  });
  if (mappings.total > 0) {
    return mappings.data[0].ApplicationValue;
  } else return '';
}

async function getFieldValue(application, field, value) {
  let mappings = await feathersClient.service('mappings').find({
    query: {
      Application: application,
      Field: field,
      LoxiValue: value,
      // Order: '0',
      $sort: { Order: 1 },
      $limit: 1,
    },
  });
  if (mappings.total > 0) {
    return mappings.data[0].ApplicationValue;
  } else return '';
}

async function calculatedSeverity(blocking, application) {
  let mappings = await feathersClient.service('mappings').find({
    query: {
      Application: application,
      Field: 'Blocking',
      LoxiBoolean: blocking,
      $sort: { Order: 1 },
      $limit: 1,
    },
  });
  if (mappings.total > 0) {
    return mappings.data[0].ApplicationValue;
  } else return '';
}

async function calculatedParent(processNumber, application) {
  let response = { assigned: null, parents: [] };
  if (application == 'DevOps') {
    let parent = await FindWorkItem(processNumber);
    // console.log('p', parent);
    if (parent.length > 0) {
      let Assigned = await GetWorkItem(parent[0].id);
      if (Assigned && Assigned.Assigned) {
        response.assigned = Assigned.Assigned;
      }
      parent.forEach((p) => {
        response.parents.push(p.id);
      });
    } else {
      console.log('no parent');
    }
  }

  return response;
}

async function calculatedStatus() {
  let body = [];

  let StatusField = await feathersClient.service('mappings').find({
    query: {
      Application: 'DevOps',
      Field: 'Status',
      $sort: { Order: 1 },
      $limit: 1,
    },
  });

  if (StatusField.total > 0) {
    StatusField = StatusField.data[0].ApplicationValue;
  } else return null;

  if (StatusField) {
    body.push({
      op: 'add',
      path: '/fields/System.State',
      from: null,
      value: StatusField,
    });
  } else {
    console.log('no Status field');
    return null;
  }
  return body;
}

export {
  calculatedParent,
  calculatedSeverity,
  calculatedStatus,
  getField,
  getFieldValue,
};
