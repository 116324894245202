import axios from 'axios';
import feathersClient from '@/feathers-client';
import vue from 'vue';
import qs from 'qs';
import { config } from '@/config';
import {
  calculatedParent,
  calculatedSeverity,
  getField,
} from './GetTaskFields';

async function getToken() {
  let appInfo = null;
  try {
    appInfo = await feathersClient
      .service('tokens')
      .find({ query: { Application: 'DevOps' } });
  } catch (error) {
    console.log('Error getting appInfo');
  }

  return appInfo;
}

async function getUserToken() {
  // let userInfo = null;
  let appInfo = await getToken();

  if (appInfo && appInfo.total == 1) {
    // try {
    //   userInfo = await feathersClient.service('tokens-user').find({
    //     query: {
    //       TokenUserId: 1, //store.getters['auth/user'].id, # TODO temp fix always use key of user lode
    //       tokenId: appInfo.data[0].id,
    //     },
    //   });
    // } catch (error) {
    //   console.log('Error getting userInfo');
    // }
    return appInfo.data[0];
  }

  // if (userInfo && userInfo.total == 1) {
  //   return userInfo.data[0];
  // } else {
  //   return '';
  // }
}

async function getHeader(type = 1) {
  let appInfo = await getUserToken();
  let auth = btoa(':' + appInfo.AccessToken); // btoa(appInfo.AccessToken.toString('base64');

  let config = {
    headers: {
      // Authorization: `Bearer  ${userInfo.AccessToken}`,
      Authorization: `Basic ${auth}`,
      // Authorization: `Basic OnpndmlmdmlvZ3czb2xhbWJlcGlrNGdubmMybmU0MmJ4M2g3cXg0ZjZxZGZwNXJldGdkY2E=`,
      'Content-Type':
        type == 1 ? 'application/json-patch+json' : 'Application/json',
    },
  };
  return config;
}

async function CreateWorkItem(
  title,
  type,
  blocking,
  content1,
  content2,
  variant
) {
  let TaskType = await getField('DevOps', 'Type', type);
  if (!TaskType) TaskType = 'Task';
  let url = `https://dev.azure.com/${config.devOps.organisation}/${config.devOps.project}/_apis/wit/workitems/$${TaskType}?api-version=7.1-preview.3`;
  let body = [
    {
      op: 'add',
      path: '/fields/System.Title',
      from: null,
      value: title,
    },
    {
      op: 'add',
      path:
        type == 'Bug'
          ? '/fields/Microsoft.VSTS.TCM.ReproSteps'
          : '/fields/System.Description',
      from: null,
      value: content1,
    },
    {
      op: 'add',
      path: '/fields/Microsoft.VSTS.TCM.SystemInfo',
      from: null,
      value: content2,
    },
  ];

  // Blocking
  let Severity = await calculatedSeverity(blocking, 'DevOps');
  if (Severity) {
    body.push({
      op: 'add',
      path: '/fields/Microsoft.VSTS.Common.Severity',
      from: null,
      value: Severity,
    });
  }

  // Parent Issue
  if (variant) {
    let processNumber = `${
      config.customer.prefix
    }${variant.process.Number.toString().padStart(4, '0')}`;
    let parent = await calculatedParent(processNumber, 'DevOps');
    if (parent.parents.length > 0) {
      body.push({
        op: 'add',
        path: '/fields/System.AssignedTo',
        value: parent.assigned,
      });
      parent.parents.forEach((p) => {
        body.push({
          op: 'add',
          path: '/relations/-',
          value: {
            rel: 'System.LinkTypes.Related',
            url: 'https://dev.azure.com/foocus/_apis/wit/workItems/' + p,
            attributes: {
              comment: '',
            },
          },
        });
      });
    }
  }
  // Department
  let DepartmentField = await getField('DevOps', 'Department');
  if (
    variant &&
    variant.process &&
    variant.process.department &&
    DepartmentField
  ) {
    body.push({
      op: 'add',
      path: '/fields/' + DepartmentField,
      from: null,
      value: variant.process.department.ERPModule,
    });
  }

  // Area
  let Area = await getField('DevOps', 'Area');
  if (Area) {
    body.push({
      op: 'add',
      path: '/fields/System.AreaPath',
      from: null,
      value: Area,
    });
  }

  //Iteration
  let Iteration = await getField('DevOps', 'Iteration');
  if (Iteration) {
    body.push({
      op: 'add',
      path: '/fields/System.IterationPath',
      from: null,
      value: Iteration,
    });
  }

  // Status
  if (type == 'Bug') {
    let Status = await getField('DevOps', 'Status');

    if (Status) {
      body.push({
        op: 'add',
        path: '/fields/System.State',
        from: null,
        value: Status,
      });
    }
  }
  // console.log(body);
  let result = null;
  let response = null;
  try {
    result = await axios.post(url, body, await getHeader());
    //console.log(result);
    if (result.status == 200) {
      response = {
        id: result.data.key,
        link: result.data.self,
      };
      return result;
    } else if (result.status == 203) {
      console.log('Login failed?');
      //userefresh and retry
      await useRefresToken();
      try {
        result = await axios.post(url, body, await getHeader());
        if (result.status == 200) {
          response = {
            id: result.data.key,
            link: result.data.self,
          };
        } else {
          console.log('redirect');
          // window.location.replace('https://www.foocus.be');
        }
      } catch (error) {
        console.log('redirect');
        // window.location.replace('https://www.foocus.be');
      }

      // if still failing redirect to link account page
    } else vue.toasted.global.error('Error creating Bug');
  } catch (error) {
    console.log(error);
  }

  return response;
}

async function GetWorkItem(item) {
  let url = `https://dev.azure.com/${config.devOps.organisation}/${config.devOps.project}/_apis/wit/workitems/${item}?api-version=7.1-preview.3`;

  let result = null;
  let response = null;
  try {
    result = await axios.get(url, await getHeader());
    if (result.status == 200) {
      response = {
        status: result.data.fields['System.State'],
        bugReport: result.data.fields['Microsoft.VSTS.TCM.ReproSteps'],
        Assigned: result.data.fields['System.AssignedTo']
          ? result.data.fields['System.AssignedTo'].displayName
          : '',
      };
    } else vue.toasted.global.error('Error getting Bug');
  } catch (error) {
    console.log(error);
  }

  return response;
}

async function FindWorkItem(item) {
  let url = `https://dev.azure.com/${config.devOps.organisation}/${config.devOps.project}/_apis/wit/wiql?api-version=7.0`;
  let mappings = await feathersClient.service('mappings').find({
    query: {
      Application: 'DevOps',
      Field: 'LoxiID',
      Order: '0',
      $sort: { Order: 1 },
      $limit: 1,
    },
  });
  // console.log(mappings);
  let LoxiField = null;
  let ParentType = null;
  if (mappings.total > 0) {
    LoxiField = mappings.data[0].ApplicationValue;
  } else return [];

  mappings = await feathersClient.service('mappings').find({
    query: {
      Application: 'DevOps',
      Field: 'ParentType',
      Order: '0',
      $sort: { Order: 1 },
      $limit: 1,
    },
  });
  if (mappings.total > 0) {
    ParentType = mappings.data[0].ApplicationValue;
  } else return [];

  let body = {
    query:
      'Select [System.Id], [System.Title], [System.State] From WorkItems Where ' +
      LoxiField +
      " Contains  '" +
      item +
      "' AND System.WorkItemType = '" +
      ParentType +
      "'",
  };
  body = JSON.stringify(body);
  let result = null;
  let response = null;
  let parents = [];

  try {
    result = await axios.post(url, body, await getHeader(2));
    // console.log('f', result);
    if (result.status == 200) {
      response = result.data.workItems;
      if (response.length > 0) {
        response.forEach((r) => {
          // console.log(r);
          parents.push(r);
        });
        // response = response[0].id;
      } else parents = [];
    } else if (result.status == 203) {
      await useRefresToken();
      result = await axios.post(url, body, await getHeader(2));
      //console.log(result);
      response = result.data.workItems;
      if (response.length > 0) {
        response.forEach((r) => {
          // console.log(r);
          parents.push(r);
        });
        // response = response[0].id;
      } else parents = [];
    } else vue.toasted.global.error('Error getting Parent WorkItem');
  } catch (error) {
    console.log(error);
  }
  return parents;
}

async function useRefresToken() {
  console.log('Reauthenticate');
  let userInfo = await getUserToken();
  let appInfo = await getToken();

  let url = 'https://app.vssps.visualstudio.com/oauth2/token';
  let body = {
    client_assertion_type:
      'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
    client_assertion: appInfo.data[0].ClientSecret,
    grant_type: 'refresh_token',
    assertion: userInfo.RefreshToken,
    redirect_uri: appInfo.data[0].RedirectUri,
  };
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(body),
    url,
  };

  let accessToken = await axios(options);

  //console.log(accessToken);
  try {
    // await feathersClient.service('tokens-user').patch(userInfo.id, { # TODO temp fix always use key of user lode
    await feathersClient.service('tokens-user').patch(1, {
      RefreshToken: accessToken.data.refresh_token,
      AccessToken: accessToken.data.access_token,
    });
  } catch (error) {
    console.log('Error saving new acces token: ', error);
  }
}

export { CreateWorkItem, GetWorkItem, FindWorkItem };
