// src/feathers-client.js
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import { iff, discard } from 'feathers-hooks-common';
import feathersVuex from 'feathers-vuex';
import { config } from '@/config';

//console.log(config.apiBaseUrl);
//let url = process.env.VUE_APP_API_BASE_URL || 'http://localhost:3030';
let url = config.apiBaseUrl;
// if (
//   process.env.NODE_ENV !== 'production' &&
//   process.env.NODE_ENV !== 'test' &&
//   typeof console !== 'undefined'
// ) {
//   url = 'http://localhost:3030';
// } else {
//   url = 'https://dev-backend.loxi.be';
// }
const socket = io(url, { transports: ['websocket'] });
// const socket = io('http://localhost:3030', { transports: ['websocket'] });

// const socket = io('https://bpm-backend.herokuapp.com/', {
//   transports: ['websocket'],
// });

const feathersClient = feathers()
  .configure(
    socketio(socket, {
      timeout: 20000,
    })
  )
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        iff(
          (context) => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        ),
      ],
    },
  });

export default feathersClient;

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } =
  feathersVuex(feathersClient, {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: 'id', // Must match the id field in your database table/collection
    whitelist: ['$regex', '$options'],
  });

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex };
