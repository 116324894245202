<template>
  <v-container>
    <h1>Test Scenario Suites</h1>
    <TestScenarioHeaderList />
  </v-container>
</template>

<script>
import TestScenarioHeaderList from '@/components/TestScenarios/TestScenarioHeader/TestScenarioHeaderList.vue';
export default {
  components: { TestScenarioHeaderList },
};
</script>

<style lang="scss" scoped></style>
