var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function({ clone, save, reset, remove }){return [_c('SpecificationEditDialog',{attrs:{"item":clone,"saving":_vm.saving},on:{"save":function($event){_vm.saving = true;
        save()
          .then(async (saved) => {
            _vm.handleSaveResponse(
              saved.Description1,
              'specification',
              _vm.currentItem ? 'updated' : 'created'
            );
            const { SpecificationVariant } = _vm.$FeathersVuex.api;
            let exists = await SpecificationVariant.find({
              query: {
                VariantId: saved.VariantId,
                SpecificationId: saved.id,
              },
            });
            if (exists.data.length == 0 && saved.VariantId != null) {
              let newRecord = new SpecificationVariant();
              newRecord.VariantId = saved.VariantId;
              newRecord.SpecificationId = saved.id;
              newRecord.save().then(async (saved2) => {
                _vm.handleSaveResponse(
                  saved2.id,
                  'specification variant',
                  'saved'
                );
                await _vm.refresh();
                _vm.showDialog = false;
                _vm.saving = _vm.fasle;
              });
            } else {
              await _vm.refresh();
              _vm.showDialog = false;
              _vm.saving = false;
            }
          })
          .catch(async (error) => {
            _vm.handleErrorResponse(error);
            await _vm.refresh();
            _vm.saving = false;
          });},"reset":reset,"remove":function($event){remove()
          .then(async (saved) => {
            _vm.handleSaveResponse(
              saved.Description1,
              'specification',
              'removed'
            );

            await _vm.refresh();
          })
          .catch(async (error) => {
            _vm.handleErrorResponse(error);
            await _vm.refresh();
          });

        _vm.showDialog = false;},"cancel":function($event){_vm.showDialog = false}}})]}}],null,false,1176827974)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }