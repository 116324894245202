<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <FieldListAddDialog
        :item="clone"
        @save="
          showDialog = false;
          save().then(handleSaveResponse).catch(errorHandler);
        "
        @reset="reset"
        @remove="
          remove();
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></FieldListAddDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import FieldListAddDialog from '@/components/ProcessStep/FieldListAddDialog';

export default {
  name: 'FieldListAdd',
  components: {
    FeathersVuexFormWrapper,
    FieldListAddDialog,
  },
  data() {
    return {
      item: null,
    };
  },
  props: {
    value: { type: Boolean },
    stepid: null,
  },
  computed: {
    //   item() {
    //     const { fieldlist } = this.$FeathersVuex.api;
    //     if (this.currentItem) {
    //       return await fieldlist.get(this.currentItem.id);
    //     } else {
    //       let newFieldList = new fieldlist();
    //       newFieldList.FieldListId = this.FieldlistId;
    //       return newFieldList;
    //     }
    //   },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    async handleSaveResponse() {
      this.$toasted.global.success(`Saved successfully`);
      const { ProcessStep, Field } = this.$FeathersVuex.api;
      await Field.find({});
      await ProcessStep.find({
        query: { VariantId: this.variant },
      });
    },

    errorHandler(error) {
      //console.log(error);
      let errorMessage = '';
      if (
        Object.keys(error.errors).length === 0 &&
        error.errors.constructor === Object
      ) {
        errorMessage = error.message;
      } else {
        error.errors.map((item) => {
          errorMessage += item.message + '\n';
        });
        this.errorMessage = errorMessage;
      }
      this.$toasted.global.error(errorMessage);
    },
  },
  async mounted() {
    const { FieldStep } = this.$FeathersVuex.api;
    let newFieldStep = new FieldStep();
    newFieldStep.ProcessStepId = this.stepid;
    this.item = newFieldStep;
  },
};
</script>
