import * as XLSX from 'xlsx';

function generateExcel(payload, name) {
  // payload format  [{ process: [payload], status: [payload] }];
  let wb = XLSX.utils.book_new();
  let filename = name;
  payload.forEach((e) => {
    let sheet = Object.keys(e)[0];
    let data = e[sheet];
    var ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, sheet);
  });
  XLSX.writeFile(wb, filename);
}

export { generateExcel };
