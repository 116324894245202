<template>
  <div>
    <div v-if="dataload" class="d-flex justify-center align-center">
      <v-progress-circular
        class="ma-50"
        indeterminate
        :size="50"
        :width="4"
      ></v-progress-circular>
    </div>
    <ejsschedule
      ref="scheduleObj"
      height="90vh"
      v-if="eventSettings.dataSource"
      :eventSettings="eventSettings"
      :popupOpen="onPopupOpen"
      :actionComplete="OnActionCompleted"
      :actionBegin="onActionBegin"
      :rowAutoHeight="true"
      :readonly="dataload"
      startHour="07:00"
      endHour="19:00"
    >
      <template v-slot:eventTemplate="{ data }">
        <div
          class="template-wrap"
          :style="{
            background: data.EventType == 'Work Shop' ? '#d1f5ed' : '#777777FF',
            color: data.EventType == 'Work Shop' ? 'black' : 'white',
          }"
        >
          <div class="subject" style="position: relative">
            <span
              v-if="data.PlanStatusId"
              class="e-badge e-badge-circle size_1 #FF0000"
              :style="{
                background: status?.find((d) => d.id == data.PlanStatusId)
                  ?.Color,
              }"
            ></span>
            {{ data.Subject }}
          </div>
          <div class="time">
            {{ data.StartDate.toLocaleTimeString().slice(0, 5) }} -
            {{ data.EndDate.toLocaleTimeString().slice(0, 5) }}
          </div>
          <div class="status">
            <v-chip
              v-if="status?.find((d) => d.id == data.PlanStatusId)"
              x-small
              :color="status?.find((d) => d.id == data.PlanStatusId)?.Color"
            >
              {{
                status?.find((d) => d.id == data.PlanStatusId)?.StatusToPlan
              }}</v-chip
            >
            <!-- {{ status.find((d) => d.id == data.PlanStatusId)?.Status }} -->
          </div>
        </div>
      </template>
      <template v-slot:tooltipTemplate="{ data }">
        <div class="tooltip-wrap">
          <div class="content-area">
            <div class="name">{{ data.Subject }}</div>
            <div>
              {{
                data.StartDate.toLocaleDateString('nl-Be', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
              }}
            </div>
            <div class="time">
              {{ data.StartDate.toLocaleTimeString().slice(0, 5) }} -
              {{ data.EndDate.toLocaleTimeString().slice(0, 5) }}
            </div>
            <div>
              <v-chip
                v-if="status?.find((d) => d.id == data.PlanStatusId)"
                x-small
                :color="status?.find((d) => d.id == data.PlanStatusId)?.Color"
              >
                {{
                  status?.find((d) => d.id == data.PlanStatusId)?.StatusToPlan
                }}</v-chip
              >
            </div>
          </div>
        </div>
      </template>
    </ejsschedule>
  </div>
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  DragAndDrop,
  Resize,
  ExcelExport,
} from '@syncfusion/ej2-vue-schedule';
import {
  DropDownList,
  MultiSelect,
  CheckBoxSelection,
} from '@syncfusion/ej2-dropdowns';

// import { CheckBox } from '@syncfusion/ej2-buttons';
import { createElement } from '@syncfusion/ej2-base';
//import the loadCldr from ej2-base
import { loadCldr } from '@syncfusion/ej2-base';
import { setCulture } from '@syncfusion/ej2-base';

import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/nl/ca-gregorian.json';
import * as numbers from 'cldr-data/main/nl/numbers.json';
import * as timeZoneNames from 'cldr-data/main/nl/timeZoneNames.json';

MultiSelect.Inject(CheckBoxSelection);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
setCulture('nl');
export default {
  data() {
    return {
      height: '550px',
      processList: [],
      variantList: [],
      processStepList: [],
      statusList: [],
      userList: [],
      status: null,
      dataload: false,
      eventSettings: {
        dataSource: null,
        template: 'eventTemplate',
        tooltipTemplate: 'tooltipTemplate',
        enableTooltip: true,
        fields: {
          id: 'id',
          startTime: { name: 'StartDate' },
          endTime: { name: 'EndDate' },
        },
      },
    };
  },
  methods: {
    onActionBegin: function (args) {
      if (args.requestType === 'toolbarItemRendering') {
        let exportItem = {
          align: 'Right',
          showTextOn: 'Both',
          prefixIcon: 'e-icon-schedule-excel-export',
          text: 'Excel Export',
          cssClass: 'e-excel-export',
          click: this.onExportClick.bind(this),
        };
        args.items.push(exportItem);
      }
    },

    onExportClick: function () {
      let scheduleObj = this.$refs.scheduleObj;
      let exportValues = {
        fields: [
          'id',
          'Subject',
          'StartDate',
          'EndDate',
          'Location',
          'PlanStatusId',
          'ProcessId',
          'VariantId',
          'ProcessStepId',
        ],
      };
      scheduleObj.exportToExcel(exportValues);
    },
    async OnActionCompleted(args) {
      if (args.addedRecords?.length > 0) {
        // Add Events
        for (const event of args.addedRecords) {
          delete event.id; // db determines id
          if (event.Attendees?.length == 0) event.Attendees = null;
          try {
            let data = await feathersClient
              .service('planner-events')
              .create(event);
            handleSaveResponse(data.Subject, 'Event', 'Created');
            this.$emit('eventCreated');
            location.reload();
          } catch (error) {
            console.log(error);
            handleErrorResponse(error);
          }
        }
      }
      if (args.changedRecords?.length > 0) {
        // Add Events
        for (const event of args.changedRecords) {
          try {
            event.Sent = false;
            if (event.Attendees?.length == 0) event.Attendees = null;

            let data = await feathersClient
              .service('planner-events')
              .patch(event.id, event);
            handleSaveResponse(data.Subject, 'Event', 'Updated');
            this.$emit('eventCreated');
          } catch (error) {
            handleErrorResponse(error);
          }
        }
      }
      if (args.deletedRecords?.length > 0) {
        // Add Events
        for (const event of args.deletedRecords) {
          if (event.Sent == false) {
            event.Deleted = true;
            event.Sent = true;
          } else {
            event.Deleted = true;
            event.Sent = false;
          }
          console.log(event);
          try {
            let data = await feathersClient
              .service('planner-events')
              .patch(event.id, event);
            handleSaveResponse(data.Subject, 'Event', 'Deleted');
            this.$emit('eventCreated');
          } catch (error) {
            handleErrorResponse(error);
          }
        }
      }
    },
    onPopupOpen(args) {
      if (args.type === 'Editor') {
        // Create required custom elements in initial time
        if (!args.element.querySelector('.custom-field-row0')) {
          let row = createElement('div', { className: 'custom-field-row0' });
          let formElement = args.element.querySelector('.e-schedule-form');
          formElement.firstChild.insertBefore(
            row,
            args.element.querySelector('.e-description-row')
          );
          let container = createElement('div', {
            className: 'custom-field-container0',
          });
          let inputEle = createElement('input', {
            className: 'e-field',
            attrs: { name: 'EventType' },
          });
          container.appendChild(inputEle);
          row.appendChild(container);
          let dropDownList = new DropDownList({
            dataSource: ['Meeting', 'Work Shop', 'Training', 'Projectoverleg'],
            // fields: { text: 'text', value: 'text' },
            value: args.data.EventType,
            floatLabelType: 'Always',
            placeholder: 'Event Type',
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute('name', 'EventType');
        }
        if (!args.element.querySelector('.custom-field-row1')) {
          let row = createElement('div', { className: 'custom-field-row1' });
          let formElement = args.element.querySelector('.e-schedule-form');
          formElement.firstChild.insertBefore(
            row,
            args.element.querySelector('.e-description-row').nextSibling
          );
          let container = createElement('div', {
            className: 'custom-field-container1',
          });
          let inputEle = createElement('input', {
            className: 'e-field',
            attrs: { name: 'PlanStatusId' },
          });
          container.appendChild(inputEle);
          row.appendChild(container);
          let dropDownList = new DropDownList({
            dataSource: this.statusList,
            fields: { text: 'text', value: 'value' },
            value: args.data.PlanStatusId,
            floatLabelType: 'Always',
            placeholder: 'Status',
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute('name', 'PlanStatusId');
        }

        if (!args.element.querySelector('.custom-field-row2')) {
          let row = createElement('div', { className: 'custom-field-row2' });
          let formElement = args.element.querySelector('.e-schedule-form');
          formElement.firstChild.insertBefore(
            row,
            args.element.querySelector('.e-description-row').nextSibling
          );
          let container = createElement('div', {
            className: 'custom-field-container2',
          });
          let inputEle = createElement('input', {
            className: 'e-field',
            attrs: { name: 'ProcessStepId' },
          });
          container.appendChild(inputEle);
          row.appendChild(container);
          let dropDownList = new DropDownList({
            dataSource: this.processStepList,
            fields: { text: 'text', value: 'value' },
            value: args.data.ProcessStepId,
            floatLabelType: 'Always',
            placeholder: 'Process Step',
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute('name', 'ProcessStepId');
        }
        if (!args.element.querySelector('.custom-field-row3')) {
          let row = createElement('div', { className: 'custom-field-row3' });
          let formElement = args.element.querySelector('.e-schedule-form');
          formElement.firstChild.insertBefore(
            row,
            args.element.querySelector('.e-description-row').nextSibling
          );
          let container = createElement('div', {
            className: 'custom-field-container3',
          });
          let inputEle = createElement('input', {
            className: 'e-field',
            attrs: { name: 'VariantId' },
          });
          container.appendChild(inputEle);
          row.appendChild(container);
          let dropDownList = new DropDownList({
            dataSource: this.variantList,
            fields: { text: 'text', value: 'value' },
            value: args.data.VariantId,
            floatLabelType: 'Always',
            placeholder: 'Variant',
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute('name', 'VariantId');
        }

        if (!args.element.querySelector('.custom-field-row4')) {
          let row = createElement('div', { className: 'custom-field-row4' });
          let formElement = args.element.querySelector('.e-schedule-form');
          formElement.firstChild.insertBefore(
            row,
            args.element.querySelector('.e-description-row').nextSibling
          );
          let container = createElement('div', {
            className: 'custom-field-container4',
          });
          let inputEle = createElement('input', {
            className: 'e-field',
            attrs: { name: 'ProcessId' },
          });
          container.appendChild(inputEle);
          row.appendChild(container);
          let dropDownList = new DropDownList({
            dataSource: this.processList,
            fields: { text: 'text', value: 'value' },
            value: args.data.ProcessId,
            floatLabelType: 'Always',
            placeholder: 'Process',
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute('name', 'ProcessId');
        }
        if (!args.element.querySelector('.custom-field-row5')) {
          let row = createElement('div', { className: 'custom-field-row5' });
          let formElement = args.element.querySelector('.e-schedule-form');
          formElement.firstChild.insertBefore(
            row,
            args.element.querySelector('.e-description-row').nextSibling
          );
          let container = createElement('div', {
            className: 'custom-field-container5',
          });
          let inputEle = createElement('input', {
            className: 'e-field',
            attrs: { name: 'Attendees' },
          });
          container.appendChild(inputEle);
          row.appendChild(container);

          let dropDownList = new MultiSelect({
            dataSource: this.userList,
            fields: { text: 'text', value: 'value' },
            value: args.data.Attendees,
            floatLabelType: 'Always',
            placeholder: 'Attendees',
            mode: 'CheckBox',
          });
          dropDownList.appendTo(inputEle);
          inputEle.setAttribute('name', 'Attendees');
        }
        // if (!args.element.querySelector('.custom-field-row5')) {
        //   let row = createElement('div', { className: 'custom-field-row5' });
        //   let formElement = args.element.querySelector('.e-schedule-form');
        //   formElement.firstChild.insertBefore(
        //     row,
        //     args.element.querySelector('.e-description-row').nextSibling
        //   );
        //   let container = createElement('div', {
        //     className: 'custom-field-container5',
        //   });
        //   let inputEle = createElement('input', {
        //     className: 'e-field',
        //     attrs: { name: 'Sent' },
        //   });
        //   container.appendChild(inputEle);
        //   row.appendChild(container);
        //   let checkbox = new CheckBox({ label: 'Sent' });
        //   checkbox.appendTo(inputEle);
        //   inputEle.setAttribute('name', 'Sent');
        // }
      }
    },
  },
  components: {
    ejsschedule: ScheduleComponent,
  },
  provide: {
    schedule: [
      Day,
      Week,
      WorkWeek,
      Month,
      Agenda,
      DragAndDrop,
      Resize,
      ExcelExport,
    ],
  },
  async mounted() {
    this.dataload = true;
    let data = await feathersClient
      .service('planner-events')
      .find({ query: { Deleted: false } });
    this.eventSettings.dataSource = data.data;
    let processList = await feathersClient.service('process').find({});
    for (const process of processList.data) {
      this.processList.push({ text: process.Name, value: process.id });
    }
    let variantList = await feathersClient.service('variant').find({});
    for (const variant of variantList.data) {
      this.variantList.push({ text: variant.Name, value: variant.id });
    }
    let processStepList = await feathersClient.service('process-step').find({});
    for (const processStep of processStepList.data) {
      this.processStepList.push({
        text: processStep.Name,
        value: processStep.id,
      });
    }
    let statusList = await feathersClient
      .service('status')
      .find({ query: { Active: true } });
    this.status = statusList.data;
    for (const status of statusList.data.sort((a, b) => a.Number - b.Number)) {
      this.statusList.push({ text: status.StatusToPlan, value: status.id });
    }
    let userList = await feathersClient.service('users').find({});
    for (const user of userList.data) {
      this.userList.push({ text: user.fullname, value: user.email });
    }
    this.dataload = false;
  },
};
</script>

<style lang="scss">
.e-badge.size_1 {
  font-size: 6px;
  margin-bottom: 3px;
}
.custom-field-row0 {
  padding-bottom: 12px;
}
.custom-field-row1 {
  padding-bottom: 12px;
}
.custom-field-row2 {
  padding-bottom: 12px;
}
.custom-field-row3 {
  padding-bottom: 12px;
}
.custom-field-row4 {
  padding-bottom: 12px;
}
.custom-field-row5 {
  padding-bottom: 12px;
}
.e-description-row {
  padding-bottom: 12px;
}

.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment,
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment,
.e-schedule .e-month-view .e-appointment {
  background: lightgray;
  color: #000000;
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-excel-export::before {
  content: '\e242';
}

.e-schedule
  .e-vertical-view
  .e-content-wrap
  .e-appointment
  .e-appointment-details {
  padding: 0;
  height: 100%;
}

.e-schedule .template-wrap {
  height: 100%;
  white-space: normal;
}

.e-schedule .template-wrap .subject {
  font-weight: 500;
  font-size: 14px;
  padding: 4px 4px 0px;
  text-wrap: wrap;
}

.e-schedule .template-wrap .time {
  font-size: 10px;
  padding: 0px 4px 0px;
  overflow: hidden;
}
.e-schedule .template-wrap .status {
  font-size: 12px;
  padding: 4px 4px 4px;
  overflow: hidden;
}
</style>
