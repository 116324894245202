<template>
  <div v-if="variants && toggle">
    <v-card class="ma-2 pa-2" autoheight>
      <v-row class="subtitle-1">
        <v-col cols="1"></v-col>
        <v-col cols="3">Imported Text</v-col><v-col cols="2">Pages</v-col
        ><v-col cols="6">Link to Existing Variant</v-col></v-row
      >
      <v-row
        class="mt-2"
        v-for="(variant, index) in variants"
        :key="variant.ExternalId"
      >
        <v-col cols="1"
          ><v-icon v-if="!variant.Existing" @click="removeVariant(index)"
            >mdi-trash-can</v-icon
          ></v-col
        >
        <v-col cols="3" v-bind:class="{ 'warning--text': variant.Existing }"
          >{{ variant.Number }}. {{ variant.Name }} </v-col
        ><v-col cols="2">
          <v-select
            v-if="!variant.Existing"
            chips
            small-chips
            v-model="variant.SelectedPageIndexes"
            :items="pages"
            item-text="title"
            item-value="index"
            label="Pages"
            outlined
            dense
            hide-details
            multiple
          ></v-select></v-col
        ><v-col cols="6">
          <v-autocomplete
            v-if="!variant.Existing"
            :disabled="variant.linkExisting"
            v-model="variant.linkExistingVariant"
            :items="fullVariantList"
            item-text="Name"
            return-object
            hide-details
            outlined
            dense
            clearable
            label="Select Variant"
            @change="
              linkVariant(variant);
              toggle = !toggle;
              toggle = !toggle;
            "
          >
            <template v-slot:append>
              <v-icon
                v-if="variant.linkExisting && variant.linkExistingVariant"
                color="green"
                >mdi-check-bold</v-icon
              >
            </template>
            <template v-if="!variant.Existing" v-slot:append-outer>
              <v-icon
                v-if="!variant.linkExisting && variant.linkExistingVariant"
                color="green"
                :disabled="!variant.linkExistingVariant"
                @click="linkVariant(variant)"
                >mdi-link</v-icon
              >
              <v-icon
                v-if="variant.linkExisting"
                color="red"
                :disabled="!variant.linkExistingVariant"
                @click="linkVariant(variant)"
                >mdi-link-off</v-icon
              >
              <v-icon
                v-if="!variant.linkExistingVariant || !variant.linkExisting"
                >mdi-new-box</v-icon
              >
            </template>
          </v-autocomplete>
          <div v-if="variant.Existing">
            <v-icon small color="warning" class="mr-3"
              >mdi-format-list-text</v-icon
            ><span>Variant exists</span>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-btn v-if="checkNewVariant" color="primary" @click="clickSaveVariant()">
      Save
    </v-btn>
    <v-btn v-if="!checkNewVariant" text outlined @click="next()"> Skip </v-btn>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  props: ['documentParsed', 'process'],
  mixins: [
    makeFindMixin({
      service: 'variant',
      name: 'fullVariantList',
      watch: 'process.id',
    }),
  ],
  data() {
    return {
      variants: [],
      toggle: true,
      pages: [],
    };
  },

  methods: {
    removeVariant(index) {
      this.variants.splice(index, 1);
    },
    linkVariant(variant) {
      this.variants = this.variants.map((s) =>
        s.ExternalId === variant.ExternalId
          ? {
              ...s,
              linkExisting: !s.linkExisting,
              linkExistingVariant: variant.linkExisting
                ? null
                : s.linkExistingVariant,
            }
          : s
      );

      // trick to refresh dialogs
      this.toggle = false;
      this.toggle = true;
    },
    async clickSaveVariant() {
      this.variants = await Promise.all(
        this.variants.map(async (p) => {
          if (!p.Existing) {
            let pageindex = p.SelectedPageIndexes.filter((f) => f != null); // remove nulls
            pageindex = pageindex.map((item) => item + 1);
            p.PageIndexes = String(pageindex);
            let saved = null;
            if (p.linkExisting) {
              // patch
              try {
                saved = await feathersClient
                  .service('variant')
                  .patch(p.linkExistingVariant.id, {
                    ExternalId: p.ExternalId,
                    PageIndexes: String(p.PageIndexes),
                  });
              } catch (error) {
                handleErrorResponse(error);
              }
              handleSaveResponse(saved.Name, 'Variant', 'updated');
            } else {
              //create
              try {
                saved = await p.save();
              } catch (error) {
                handleErrorResponse(error);
              }

              handleSaveResponse(saved.Name, 'Variant', 'created');
            }
            p.id = saved.id;
          }
          return p;
        })
      );
      this.next();
    },
    next() {
      this.$emit('next', { type: 'variant', data: this.variants });
    },
  },
  computed: {
    fullVariantListParams() {
      return {
        query: { ProcessId: this.process ? this.process.id : 0 },
      };
    },
    checkNewVariant() {
      return this.variants.filter((f) => f.Existing == false).length > 0;
    },
  },
  async mounted() {
    let pages = this.process.PageIndexes.split(',');
    this.pages = [...this.documentParsed];
    pages.forEach((p) => {
      // this.pages.splice(parseInt(p) - 1, 1);
      this.pages = this.pages.filter((f) => f.index != parseInt(p) - 1);
    });

    const { Variant } = this.$FeathersVuex.api;

    // Get imported Variants
    this.documentParsed[0].blocks
      .filter((p) => p.type == 'SubProcess')
      .forEach(async (variant, index) => {
        let newVariant = new Variant();

        let existingVariant = await Variant.find({
          query: { ExternalId: variant.id, ProcessId: this.process.id },
        });

        if (
          existingVariant &&
          existingVariant.data &&
          existingVariant.data.length > 0
        ) {
          newVariant = existingVariant.data[0];
          newVariant.Existing = true;
        } else {
          newVariant.Existing = false;
          newVariant.Number = index + 1;
          newVariant.Name = variant.text;
          newVariant.ExternalId = variant.id;
          newVariant.ApplicationId = 1;
          newVariant.ExtraPages = '';
          newVariant.ProcessId = this.process.id;

          let findPage = this.documentParsed.filter((p) => {
            let nbr = p.title.split('.');
            nbr = parseInt(nbr[1]);
            return nbr == index + 1;
          });
          // console.log(findPage);
          newVariant.SelectedPageIndexes = [
            findPage.length > 0 ? findPage[0].index : null,
          ]; //[index + 1];

          let variantList = await Variant.find({
            query: { ProcessId: this.process.id },
          });

          newVariant.linkExistingVariant = variantList.data.find(
            (f) =>
              f.Name.toLowerCase().trim() == variant.text.toLowerCase().trim()
          );

          if (newVariant.linkExistingVariant) {
            let searchPages = this.documentParsed.filter((page) =>
              page.title
                .toLowerCase()
                .trim()
                .includes(
                  newVariant.linkExistingVariant.Name.toLowerCase().trim()
                )
            );

            if (searchPages && searchPages.length > 0) {
              newVariant.SelectedPageIndexes = searchPages.map((m) => m.index);
            }

            newVariant.linkExisting = true;
          } else {
            let searchPages = this.documentParsed.filter((page) =>
              page.title
                .toLowerCase()
                .trim()
                .includes(variant.text.toLowerCase().trim())
            );
            if (searchPages && searchPages.length > 0) {
              newVariant.SelectedPageIndexes = searchPages.map((m) => m.index);
              newVariant.Number = searchPages[0].index;
            }

            newVariant.linkExisting = false;
            newVariant.linkExistingVariant = null;
          }
        }

        this.variants.push(newVariant);
        this.variants.sort((a, b) => a.Number - b.Number);
      });
    this.toggle = false;
    this.toggle = true;
  },
};
</script>

<style lang="scss" scoped></style>
