<template>
  <v-container>
    <h1>Test Scenario Overview</h1>
    <v-tabs v-model="selectedTab" fixed-tabs active-class="activeTab">
      <v-tab :disabled="!$can('read', 'test-scenario-header')">
        <v-icon class="mr-2"> mdi-vector-link</v-icon> TestScenario Suites
      </v-tab>

      <v-tab>
        <v-icon class="mr-2"> mdi-run</v-icon> TestScenario Execution
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-card flat>
          <v-row justify="center">
            <v-col cols="12" md="12"> <TestScenarioHeaderList /> </v-col>
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-row justify="center">
            <v-col cols="12" md="12">
              <TestScenarioRunList />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import TestScenarioHeaderList from '@/components/TestScenarios/TestScenarioHeader/TestScenarioHeaderList.vue';
import TestScenarioRunList from '@/components/TestScenarios/TestScenarioRun/TestScenarioRunList.vue';
export default {
  components: { TestScenarioHeaderList, TestScenarioRunList },
  data() {
    return {
      selectedTab: null,
    };
  },
  watch: {
    selectedTab(newIndex) {
      // Store the new active tab index in localStorage
      localStorage.setItem('testScenarioOverview.activeTabIndex', newIndex);
    },
  },
  created() {
    // Retrieve the active tab index from localStorage
    const savedIndex = localStorage.getItem(
      'testScenarioOverview.activeTabIndex'
    );
    if (savedIndex !== null) {
      this.selectedTab = parseInt(savedIndex, 10);
    }

    if (!this.$can('read', 'test-scenario-header')) {
      this.selectedTab = 1;
    }
  },
};
</script>

<style lang="scss" scoped></style>
