import { shallowPopulate } from 'feathers-shallow-populate';

import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client';

class ProcessStep extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'ProcessStep';
  // Define default properties here
  static instanceDefaults() {
    return {
      Number: 0,
      Name: null,
      Description: null,
      Alternative: 0,
      PriorityId: 1,
      Obsolete: false,
      StatusId: 1,
      Mandatory: true,
    };
  }
}
const servicePath = 'process-step';
const servicePlugin = makeServicePlugin({
  Model: ProcessStep,
  service: feathersClient.service(servicePath),
  servicePath,
});

const options = {
  include: [
    {
      service: 'status',
      nameAs: 'status',
      keyHere: 'StatusId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
    {
      service: 'priority',
      nameAs: 'priority',
      keyHere: 'PriorityId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
    {
      service: 'variant',
      nameAs: 'variant',
      keyHere: 'VariantId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
    // {
    //   service: 'response',
    //   nameAs: 'responses',
    //   keyHere: 'id',
    //   keyThere: 'ProcessStepId',
    //   asArray: true, // by default
    //   params: {}, // by default
    // },
  ],
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [shallowPopulate(options)],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
