<template>
  <v-container>
    <h1>End 2 End Process List</h1>
    <End2EndList />
  </v-container>
</template>

<script>
import End2EndList from '@/components/End2End/End2EndList.vue';

export default {
  components: { End2EndList },
};
</script>

<style lang="scss" scoped></style>
