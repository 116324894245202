<template>
  <div v-if="item">
    <v-dialog v-model="showDialog" width="1000" height="500">
      <v-card flat>
        <v-card-title>
          <span class="headline">Companies</span><v-spacer></v-spacer>
        </v-card-title>

        <section>
          <v-data-table
            :headers="headers"
            :items="sortedCompanyVariants"
            :items-per-page="20"
            :sort-by="['CompanyCode']"
            :sort-desc="[false]"
            item-key="id"
            class="elevation-1"
            disable-pagination
            :hide-default-footer="true"
            :loading="isFindCompanyPending || isFindCompanyVariantPending"
            loading-text="Loading... Please wait"
          >
            <template v-if="$can('create', 'company_variant')" v-slot:footer>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="800px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      class="mx-2"
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <v-form ref="form" v-model="validInput">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Add Company Relation</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12">
                              <v-select
                                v-model="editedItem['CompanyId']"
                                :items="filteredCompany"
                                item-text="Name"
                                item-value="id"
                                label="Company"
                                outlined
                                :rules="[rules.required]"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-checkbox
                                v-model="editedItem.Central"
                                label="Central"
                                >Central</v-checkbox
                              >
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined @click="Close()">Cancel</v-btn>
                        <v-btn
                          :disabled="!validInput"
                          color="primary"
                          @click="Add()"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card></v-form
                  >
                </v-dialog>
              </v-toolbar>
            </template>
            <template
              v-if="$can('read', 'company_variant')"
              v-slot:[`item.CompanyCode`]="{ item }"
            >
              {{
                company
                  ? company.find((f) => f.id == item.CompanyId).CompanyCode
                  : ''
              }}
            </template>
            <template
              v-if="$can('read', 'company-variant')"
              v-slot:[`item.Name`]="{ item }"
            >
              {{
                company ? company.find((f) => f.id == item.CompanyId).Name : ''
              }}
            </template>

            <template
              v-if="$can('update', 'company-variant')"
              v-slot:[`item.Central`]="props"
            >
              <v-edit-dialog
                v-if="$can('read', 'company-variant')"
                :return-value.sync="props.item.Central"
                @save="save(props.item)"
                @cancel="cancel"
                @open="open"
                @close="close"
                large
                ><span v-if="$can('update', 'company-variant')">
                  <v-icon v-if="props.item.Central" color="primary" small
                    >mdi-check-bold</v-icon
                  >
                  <v-icon v-else color="primary" small>mdi-minus</v-icon>
                </span>
                <template v-slot:input>
                  <v-simple-checkbox
                    v-model="props.item.Central"
                  ></v-simple-checkbox>
                </template>
              </v-edit-dialog>
            </template>
            <template
              v-if="$can('delete', 'company_variant')"
              v-slot:[`item.actions`]="{ item }"
            >
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      dialog: false,
      editedItem: {
        CompanyId: 0,
        Central: false,
      },
      defaultItem: {
        CompanyId: 0,
        Central: false,
      },
    };
  },
  mixins: [
    makeFindMixin({ service: 'company' }),
    makeFindMixin({ service: 'company-variant' }),
  ],

  props: {
    value: { type: Boolean },
    item: {},
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    companyParams() {
      return {};
    },
    companyVariantParams() {
      return { query: { VariantId: this.item.id } };
    },
    filteredCompany() {
      return this.company.filter(
        (f) => !this.companyVariant.find((a) => a.CompanyId == f.id)
      );
    },
    sortedCompanyVariants() {
      return this.companyVariant
        .map((obj) => {
          return {
            ...obj,
            CompanyCode: this.company
              ? this.company.find((f) => f.id == obj.CompanyId).CompanyCode
              : '',
          };
        })
        .sort((a, b) => {
          return ('' + a.CompanyCode).localeCompare(b.CompanyCode);
        });
    },
    headers() {
      return [
        {
          text: 'Company Code',
          align: 'start',
          sortable: false,
          value: 'CompanyCode',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Central',
          align: 'start',
          sortable: false,
          value: 'Central',
        },
        { text: 'Delete', value: 'actions', sortable: false },
      ];
    },
  },
  methods: {
    async save(item) {
      await feathersClient
        .service('company-variant')
        .patch(item.id, { Central: item.Central })
        .then(() => handleSaveResponse(item.id, 'Company', 'updated'))
        .catch((error) => handleErrorResponse(error));
      // item
      //   .save()
      //   .then((saved) => handleSaveResponse(saved.id, 'Company', 'updated'))
      //   .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    async Add() {
      feathersClient
        .service('company-variant')
        .create({
          VariantId: this.item.id,
          CompanyId: this.editedItem.CompanyId,
          Central: this.editedItem.Central,
        })
        .then(() =>
          handleSaveResponse(this.editedItem.CompanyId, 'Company', 'added')
        )
        .catch((error) => handleErrorResponse(error));
      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    async deleteItem(item) {
      await feathersClient
        .service('company-variant')
        .remove(item.id)
        .then(() => handleSaveResponse(item.id, 'Company', 'removed'))
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
