<template>
  <v-container>
    <v-card min-height="600" flat>
      <h1>Settings</h1>

      <v-tabs
        vertical
        justify-tab-title="start"
        class="settingsTabs"
        active-class="activeTab"
      >
        <v-tab>
          <!-- <v-icon left>
            mdi-domain
          </v-icon> -->
          Departments
        </v-tab>
        <v-tab>
          <!-- <v-icon left>
            mdi-application-outline
          </v-icon> -->
          Applications
        </v-tab>
        <v-tab v-if="$feature('erpApplication')">
          <!-- <v-icon left>
            mdi-application-outline
          </v-icon> -->
          Applications Forms
        </v-tab>
        <v-tab> Roles </v-tab>
        <v-tab>
          <!-- <v-icon left>
            mdi-list-box-outline
          </v-icon> -->
          Specification Types
        </v-tab>
        <v-tab>
          <!-- <v-icon left>
            mdi-traffic-light-outline
          </v-icon> -->
          Status
        </v-tab>
        <v-tab> Sites </v-tab>
        <v-tab> Priority </v-tab>
        <v-tab> Implementation </v-tab>
        <v-tab> Complexity </v-tab>
        <v-tab> Vendors </v-tab>
        <v-tab> Users </v-tab>
        <v-tab> Project Roles </v-tab>
        <v-tab> Project Role Mapping </v-tab>
        <v-tab> Issue Types </v-tab>
        <v-tab> Test Scenario Types </v-tab>
        <v-tab> Info </v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Department />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Application />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="$feature('erpApplication')">
          <v-card flat>
            <v-card-text>
              <ERPApplication />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Role />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <SpecificationType />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Status />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Sites />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Priority />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Implementation />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Complexity />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Vendor />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <User />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <ProjectRoles />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <ProjectRoleMapping />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <IssueTypes />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <TestScenarioTypes />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <Info />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import Department from '@/components/Settings/Department';
import Application from '@/components/Settings/Application';
import ERPApplication from '@/components/Settings/ERPApplication';
import Role from '@/components/Settings/Role';
import SpecificationType from '@/components/Settings/SpecificationType';
import Sites from '@/components/Settings/Sites';
import Status from '@/components/Settings/Status';
import Priority from '@/components/Settings/Priority';
import Implementation from '@/components/Settings/Implementation';
import Complexity from '@/components/Settings/Complexity';
import Vendor from '@/components/Settings/Vendor';
import User from '@/components/Settings/User';
import ProjectRoles from '@/components/Settings/ProjectRoles';
import ProjectRoleMapping from '@/components/Settings/ProjectRoleMapping';
import IssueTypes from '@/components/Settings/IssueTypes';
import Info from '@/components/Settings/Info';
import TestScenarioTypes from '@/components/Settings/TestScenarioType.vue';

export default {
  components: {
    Department,
    Application,
    ERPApplication,
    Role,
    SpecificationType,
    Status,
    Priority,
    Implementation,
    Complexity,
    Vendor,
    User,
    Info,
    Sites,
    ProjectRoles,
    ProjectRoleMapping,
    IssueTypes,
    TestScenarioTypes,
  },
};
</script>

<style>
.settingsTabs [role='tab'] {
  justify-content: flex-start;
}
</style>
