<template>
  <div>
    <v-dialog v-if="showDialog" v-model="showDialog" max-width="800px">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="budget"
          sort-by="Description"
          disable-pagination
          hide-default-footer
          :loading="isFindBudgetPending"
          loading-text="Loading... Please wait"
        >
          <template
            v-if="$can('update', 'budget')"
            v-slot:[`item.edit`]="{ item }"
          >
            <v-icon small class="mr-2" @click="clickEdit(item)" color="primary">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:[`body.append`]>
            <tr class="sticky-table-footer">
              <td v-text="'Total'" />
              <td v-text="''" />
              <td align="right" v-text="'1234'" />
              <td align="right" v-text="'5678'" />
            </tr>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn
            v-if="$can('create', 'budget')"
            elevation="0"
            class="mx-2"
            fab
            small
            color="primary"
            @click="clickAdd()"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  mixins: [makeFindMixin({ service: 'budget' })],
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    variantId: {},
  },
  data() {
    return {
      headers: [
        {
          text: 'Edit',
          value: 'edit',
          sortable: false,
          groupable: false,
        },

        {
          text: 'Description',
          value: 'Description',
          groupable: false,
        },
        {
          text: 'Estimated Budget',
          value: 'EstimatedBudget',
          align: 'right',
          groupable: false,
        },
        {
          text: 'Actual Budget',
          value: 'ActualBudget',
          align: 'right',
          groupable: false,
        },

        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  methods: {
    clickEdit(item) {
      console.log(item);
    },
    clickAdd(item) {
      console.log(item);
    },
  },
  computed: {
    budgetParams() {
      return {};
    },
    sumBudget() {
      let sum = 0;
      this.budget.map((f) => {
        sum += f.EstimatedBudget;
      });

      return sum;
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #ede9e9;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
