<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog && item">
    <template v-slot="{ clone, save, reset, remove }">
      <IssueReplyEditDialog
        :item="clone"
        :saving="saving"
        @save="
          saving = true;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Reply,
                'reply',
                currentItem ? 'updated' : 'created'
              );
              await refresh();
              showDialog = false;
              saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
              saving = false;
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.reply, 'reply', 'removed');
              await refresh();
              showDialog = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            })
        "
        @cancel="showDialog = false"
      ></IssueReplyEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import IssueReplyEditDialog from './IssueReplyEditDialog.vue';

export default {
  name: 'IssueReplyEdit',
  components: {
    FeathersVuexFormWrapper,
    IssueReplyEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  watch: {
    async currentItem() {
      const { ExternalIssueReply } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = await ExternalIssueReply.get(this.currentItem.id);
      } else {
        let newIssueReply = new ExternalIssueReply();
        newIssueReply.ExternalIssueId = this.externalIssueId;
        this.item = newIssueReply;
      }
    },
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    externalIssueId: { type: Number, required: false },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { ExternalIssues, ExternalIssueReply } = this.$FeathersVuex.api;

      await ExternalIssues.find({
        query: { id: this.externalIssueId },
      });
      await ExternalIssueReply.find({
        query: { ExternalIssueId: this.externalIssueId },
      });
    },
  },
  async mounted() {
    const { ExternalIssueReply } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = await ExternalIssueReply.get(this.currentItem.id);
    } else {
      let newIssueReply = new ExternalIssueReply();
      newIssueReply.ExternalIssueId = this.externalIssueId;
      this.item = newIssueReply;
    }
  },
};
</script>
