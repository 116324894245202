<template>
  <div v-if="item">
    <v-dialog v-model="showDialog" width="1000" height="500">
      <v-card flat>
        <v-card-title>
          <span class="headline">Roles</span><v-spacer></v-spacer>
        </v-card-title>

        <section>
          <v-data-table
            :headers="headers"
            :items="sortedRoles"
            :items-per-page="20"
            :sort-by="['Name']"
            :sort-desc="[false]"
            item-key="id"
            class="elevation-1"
            disable-pagination
            :hide-default-footer="true"
            :loading="isFindRolePending || isFindRoleVariantPending"
            loading-text="Loading... Please wait"
          >
            <template v-if="$can('create', 'role_variant')" v-slot:footer>
              <v-toolbar flat color="white">
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="800px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      class="mx-2"
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </template>
                  <v-form ref="form" v-model="validInput">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Add Role Relation</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="10">
                              <v-select
                                v-model="editedItem['RoleId']"
                                :items="role"
                                item-text="Name"
                                item-value="id"
                                label="Role"
                                outlined
                                :rules="[rules.required]"
                                dense
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-select
                                v-model="editedItem.Permission"
                                :items="permissionValues"
                                label="Permission"
                                :rules="[rules.required]"
                                outlined
                                dense
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined @click="Close()">Cancel</v-btn>
                        <v-btn
                          :disabled="!validInput"
                          color="primary"
                          @click="Add()"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card></v-form
                  >
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.Name`]="{ item }">
              {{ role.find((f) => f.id == item.RoleId).Name }}
            </template>
            <template
              v-if="$can('update', 'role_variant')"
              v-slot:[`item.Permission`]="{ item }"
            >
              <v-edit-dialog
                :return-value.sync="item.Permission"
                @save="save(item)"
                @cancel="cancel"
                @open="open"
                @close="close"
                large
              >
                {{ item.Permission }}
                <template v-slot:input>
                  <v-select
                    class="mt-7"
                    v-model="item.Permission"
                    :items="permissionValues"
                    label="Edit"
                    dense
                  ></v-select>
                </template>
              </v-edit-dialog>
            </template>
            <template
              v-if="$can('delete', 'role_variant')"
              v-slot:[`item.actions`]="{ item }"
            >
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      dialog: false,
      editedItem: {
        RoleId: 0,
        Permission: 'W',
      },
      defaultItem: {
        RoleId: 0,
        Permission: 'W',
      },
      permissionValues: ['W', 'R'],
    };
  },
  mixins: [
    makeFindMixin({ service: 'role' }),
    makeFindMixin({ service: 'role-variant' }),
  ],

  props: {
    value: { type: Boolean },
    item: {},
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    roleParams() {
      return { query: { $sort: { Name: 1 } } };
    },
    roleVariantParams() {
      return { query: { VariantId: this.item.id } };
    },
    sortedRoles() {
      return this.roleVariant
        .map((obj) => {
          return {
            ...obj,
            Name: this.role
              ? this.role.find((f) => f.id == obj.RoleId).Name
              : '',
          };
        })
        .sort((a, b) => {
          return ('' + a.Name).localeCompare(b.Name);
        });
    },
    headers() {
      return [
        {
          text: 'Role',
          align: 'start',
          sortable: false,
          value: 'Name',
        },

        {
          text: 'Permission',
          align: 'start',
          sortable: false,
          value: 'Permission',
        },
        { text: 'Delete', value: 'actions', sortable: false },
      ];
    },
  },
  methods: {
    async save(item) {
      await feathersClient
        .service('role-variant')
        .patch(item.id, { Permission: item.Permission })
        .then(() => handleSaveResponse(item.id, 'Role', 'updated'))
        .catch((error) => handleErrorResponse(error));
      // item
      //   .save()
      //   .then((saved) => handleSaveResponse(saved.id, 'Company', 'updated'))
      //   .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    async Add() {
      feathersClient
        .service('role-variant')
        .create({
          VariantId: this.item.id,
          RoleId: this.editedItem.RoleId,
          Permission: this.editedItem.Permission,
        })
        .then(() => handleSaveResponse(this.editedItem.RoleId, 'Role', 'added'))
        .catch((error) => handleErrorResponse(error));
      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    async deleteItem(item) {
      await feathersClient
        .service('role-variant')
        .remove(item.id)
        .then(() => handleSaveResponse(item.id, 'Role', 'removed'))
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
