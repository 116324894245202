<template>
  <v-container>
    <ProfileEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
    />
  </v-container>
</template>

<script>
import ProfileEdit from '@/components/Profile/ProfileEdit';
import { mapGetters } from 'vuex';

export default {
  components: { ProfileEdit },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    currentItem() {
      return this.user;
    },
  },
  data() {
    return {
      detailDialog: true,
    };
  },
};
</script>

<style lang="scss" scoped></style>
