<template>
  <div>
    <v-icon class="mr-10 mt-7" @click="exportToExcel()" color="primary">
      mdi-microsoft-excel
    </v-icon>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { generateExcel } from '@/utils/ExportExcel';
export default {
  mixins: [
    makeFindMixin({ service: 'export-process' }),
    makeFindMixin({ service: 'export-specification' }),
    makeFindMixin({ service: 'implementation' }),
    makeFindMixin({ service: 'complexity' }),
  ],
  methods: {
    selectProps(...props) {
      return function (obj) {
        const newObj = {};
        props.forEach((name) => {
          newObj[name] = obj[name];
        });

        return newObj;
      };
    },
    exportToExcel() {
      generateExcel(
        [
          {
            Process: this.exportProcessLatestQuery.response.sort((a, b) => {
              if (a.id < b.id) return -1;
              if (a.id > b.id) return 1;
              return 0;
            }),
          },
          {
            Specifications: this.exportSpecificationLatestQuery.response.sort(
              (a, b) => {
                if (a.id < b.idd) return -1;
                if (a.id > b.id) return 1;
                return 0;
              }
            ),
          },
          {
            Implementation: this.implementation.map(
              this.selectProps('id', 'Name')
            ),
          },
          {
            Complexity: this.complexity.map(this.selectProps('id', 'Name')),
          },
        ],
        `Process List.xlsx`
      );
    },
  },
  computed: {
    exportProcessParams() {
      return {
        query: { prefix: this.$appConfig.customer.prefix },
      };
    },
    exportSpecificationParams() {
      return {
        query: {
          type: 'fullSpecification',
          prefix: this.$appConfig.customer.prefix,
        },
      };
    },
    implementationParams() {
      return {};
    },
    complexityParams() {
      return {};
    },
  },
};
</script>

<style lang="scss" scoped></style>
