<template>
  <div>
    <v-icon class="mr-10 mt-7" @click="exportToExcel()" color="primary">
      mdi-microsoft-excel
    </v-icon>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { generateExcel } from '@/utils/ExportExcel';
export default {
  mixins: [
    makeFindMixin({ service: 'export-step-response' }),
    makeFindMixin({ service: 'export-specification-response' }),
    makeFindMixin({ service: 'export-variant-response' }),
  ],
  props: ['vendor', 'phase'],
  methods: {
    selectProps(...props) {
      return function (obj) {
        const newObj = {};
        props.forEach((name) => {
          newObj[name] = obj[name];
        });

        return newObj;
      };
    },
    exportToExcel() {
      generateExcel(
        [
          {
            Process: this.exportVariantResponseLatestQuery.response,
          },
          {
            Specifications:
              this.exportSpecificationResponseLatestQuery.response,
          },
          {
            ProcessSteps: this.exportStepResponseLatestQuery.response,
          },
        ],
        `Loxi Process Responses ${this.vendor.Name}.xlsx`
      );
    },
  },
  computed: {
    exportStepResponseParams() {
      return {
        query: {
          prefix: this.$appConfig.customer.prefix,
          vendorId: this.vendor.id,
        },
      };
    },
    exportVariantResponseParams() {
      return {
        query: {
          prefix: this.$appConfig.customer.prefix,
          vendorId: this.vendor.id,
        },
      };
    },
    exportSpecificationResponseParams() {
      return {
        query: { vendorId: this.vendor.id },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
