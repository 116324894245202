<template>
  <div>
    <v-card
      :color="
        editItem.Final
          ? '#C7FFBF'
          : editItem.ImplementationId && editItem.ComplexityId
          ? '#FFFCBD'
          : '#FFCDD2'
      "
    >
      <v-card-title>Response</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                v-model="editItem.ImplementationId"
                :items="implementation"
                item-text="Name"
                item-value="id"
                hide-details
                label="Implementation"
                :disabled="
                  !$can('update', 'response') ||
                  editItem.Final ||
                  (vendor[0]
                    ? vendor[0].ResponsePhase == 1
                      ? vendor[0].SendInDate != null
                      : vendor[0].SendInDate2 != null
                    : true)
                "
                outlined
                dense
                clearable
                @blur="updateResponse()"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="editItem.ComplexityId"
                :items="filteredComplexity(editItem.ImplementationId)"
                item-text="Name"
                item-value="id"
                hide-details
                label="Complexity"
                :disabled="
                  !$can('update', 'response') ||
                  editItem.Final ||
                  (vendor[0]
                    ? vendor[0].ResponsePhase == 1
                      ? vendor[0].SendInDate != null
                      : vendor[0].SendInDate2 != null
                    : true)
                "
                outlined
                dense
                clearable
                @change="updateResponse()"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="editItem.Remark"
                label="Remark"
                hide-details
                :disabled="
                  !$can('update', 'response') ||
                  editItem.Final ||
                  (vendor[0]
                    ? vendor[0].ResponsePhase == 1
                      ? vendor[0].SendInDate != null
                      : vendor[0].SendInDate2 != null
                    : true)
                "
                outlined
                dense
                rows="1"
                auto-grow
                clearable
                @blur="updateResponse()"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox
                v-model="editItem.Final"
                hide-details
                :disabled="
                  !$can('update', 'response') ||
                  (vendor[0]
                    ? vendor[0].ResponsePhase == 1
                      ? vendor[0].SendInDate != null
                      : vendor[0].SendInDate2 != null
                    : true)
                "
                :label="`Final`"
                dense
                @click="updateResponse(true)"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay opacity="0" :value="saving">
      <v-progress-circular
        indeterminate
        size="64"
        color="#000000"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import feathersClient from '@/feathers-client';
import {
  // handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  props: [
    'specification',
    'complexity',
    'implementation',
    'response',
    'vendor',
  ],
  components: {},
  watch: {
    response: {
      handler: async function () {
        this.editItem =
          this.response && this.response[0]
            ? this.response[0]
            : this.defaultItem;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      editItem: {},
      defaultItem: {
        ComplexityId: null,
        ImplementationId: null,
        Remark: '',
        final: false,
      },
      saving: false,
      debounce: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    saveRemark() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.updateResponse();
      }, 600);
    },

    filteredComplexity(item) {
      if (this.complexity && this.implementation) {
        let imp = this.implementation.find((f) => f.id == item);
        if (imp && imp.AllowedComplexity && imp.AllowedComplexity.length > 0) {
          return this.complexity.filter((f) =>
            imp.AllowedComplexity.includes(f.id)
          );
        } else return this.complexity;
      }
      return null;
    },

    updateResponse(final) {
      if (
        this.response &&
        this.response[0] &&
        this.editItem.ComplexityId &&
        this.editItem.ImplementationId
      ) {
        this.saving = true;
        // update response
        feathersClient
          .service('response')
          .update(this.response[0].id, this.editItem)
          .then(async () => {
            // handleSaveResponse(
            //   this.specification.Description1,
            //   'response',
            //   'updated'
            // );
            this.saving = false;
          })
          .catch(async (error) => {
            handleErrorResponse(error);
            this.saving = false;
          });
      } else {
        //create response
        if (this.editItem.ComplexityId && this.editItem.ImplementationId) {
          this.saving = true;
          this.editItem.VendorId = this.user.vendor.id;
          this.editItem.DateReceived = new Date();
          this.editItem.SpecificationId = this.specification.id;
          feathersClient
            .service('response')
            .create(this.editItem)
            .then(async () => {
              // handleSaveResponse(
              //   this.specification.Description1,
              //   'response',
              //   'added'
              // );
              this.saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              this.saving = false;
            });
        } else {
          let missing = [];
          if (!this.editItem.ComplexityId) missing.push('Complexity');
          if (!this.editItem.ImplementationId) missing.push('Implementation');
          this.editItem.Final = false;
          if (final) {
            this.$toasted.global.info(
              `Please fill in ${missing.join(
                ' and '
              )} before your response is registered.`
            );
          }
        }
      }
    },
  },
  mounted() {
    this.editItem =
      this.response && this.response[0] ? this.response[0] : this.defaultItem;
  },
};
</script>

<style lang="scss" scoped></style>
