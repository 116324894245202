<template>
  <div>
    <v-card flat class="my-5">
      <div v-if="!loading">
        <v-row align="stretch" justify="center">
          <v-col cols="10">
            <v-chip class="ma-2" color="#FFCDD2">
              <b class="mr-3">
                {{
                  this.user.vendor.ResponsePhase == 1
                    ? counterOpen
                    : counterOpen2
                }}</b
              >
              Open -
              {{
                this.user.vendor.ResponsePhase == 1
                  ? ((counterOpen / counterAll) * 100).toFixed(0)
                  : ((counterOpen2 / counterAll2) * 100).toFixed(0)
              }}%
            </v-chip>
            <v-chip class="ma-2" color="#FFFCBD">
              <b class="mr-3">
                {{
                  this.user.vendor.ResponsePhase == 1
                    ? counterProgress
                    : counterProgress2
                }}</b
              >
              In Progress -
              {{
                this.user.vendor.ResponsePhase == 1
                  ? ((counterProgress / counterAll) * 100).toFixed(0)
                  : ((counterProgress2 / counterAll2) * 100).toFixed(0)
              }}%
            </v-chip>
            <v-chip class="ma-2" color="#C7FFBF">
              <b class="mr-3">
                {{
                  this.user.vendor.ResponsePhase == 1
                    ? counterDone
                    : counterDone2
                }}</b
              >
              Done -
              {{
                this.user.vendor.ResponsePhase == 1
                  ? ((counterDone / counterAll) * 100).toFixed(0)
                  : ((counterDone2 / counterAll2) * 100).toFixed(0)
              }}%
            </v-chip></v-col
          >
          <v-col cols="2">
            <v-progress-circular
              :rotate="-90"
              :size="95"
              :width="12"
              :value="
                this.user.vendor.ResponsePhase == 1
                  ? (
                      ((counterAll - counterOpen - counterProgress) /
                        counterAll) *
                      100
                    ).toFixed(2)
                  : (
                      ((counterAll2 - counterOpen2 - counterProgress2) /
                        counterAll2) *
                      100
                    ).toFixed(2)
              "
              color="black"
            >
              {{
                this.user.vendor.ResponsePhase == 1
                  ? (
                      ((counterAll - counterOpen - counterProgress) /
                        counterAll) *
                      100
                    ).toFixed(2)
                  : (
                      ((counterAll2 - counterOpen2 - counterProgress2) /
                        counterAll2) *
                      100
                    ).toFixed(2)
              }}%
            </v-progress-circular></v-col
          >
        </v-row>
      </div>
      <div v-else>
        <v-progress-circular
          indeterminate
          :size="15"
          :width="1"
        ></v-progress-circular>
      </div>
    </v-card>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
export default {
  mixins: [
    makeFindMixin({ service: 'variant' }),
    makeFindMixin({ service: 'specification' }),
    makeFindMixin({ service: 'process-step' }),
    makeFindMixin({ service: 'response' }),
  ],
  computed: {
    loading() {
      return (
        this.isFindVariantPending ||
        this.isFindSpecificationPending ||
        this.isFindProcessStepPending ||
        this.isFindResponsePending
      );
    },
    ...mapGetters({
      user: 'auth/user',
    }),
    variantParams() {
      return {};
    },
    specificationParams() {
      return { query: { Obsolete: false } };
    },
    responseParams() {
      return {
        query: {
          VendorId: this.user.vendor ? this.user.vendor.id : null,
        },
      };
    },
    processStepParams() {
      return { query: { Obsolete: false } };
    },
    counterOpen() {
      return this.counterAll - this.counterDone - this.counterProgress;
    },
    counterOpen2() {
      return this.counterAll2 - this.counterDone2 - this.counterProgress2;
    },
    counterProgress() {
      if (this.variant && this.specification && this.response) {
        let filteredResponse = this.response.filter((f) => f.Final == false);
        let counterVariants = this.variant.filter((v) =>
          filteredResponse.some((r) => r.VariantId == v.id)
        );
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );
        let counterSpecifications = filteredSpecs.filter((s) =>
          filteredResponse.some((r) => r.SpecificationId == s.id)
        );
        return counterSpecifications.length + counterVariants.length;
      }
      return 9999999;
    },
    counterDone() {
      if (this.variant && this.specification && this.response) {
        let filteredResponse = this.response.filter((f) => f.Final == true);
        let counterVariants = this.variant.filter((v) =>
          filteredResponse.some((r) => r.VariantId == v.id)
        );
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );
        let counterSpecifications = filteredSpecs.filter((s) =>
          filteredResponse.some((r) => r.SpecificationId == s.id)
        );
        return counterSpecifications.length + counterVariants.length;
      }
      return 9999999;
    },

    counterProgress2() {
      return this.counterAll2 - this.counterDone2;
    },
    counterDone2() {
      if (this.processStep && this.specification && this.response) {
        let filteredResponse = this.response.filter((f) => f.Final == true);
        let filteredSteps = this.processStep.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );
        let counterProcessSteps = filteredSteps.filter((v) =>
          filteredResponse.some((r) => r.ProcessStepId == v.id)
        );
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );
        let counterSpecifications = filteredSpecs.filter((s) =>
          filteredResponse.some((r) => r.SpecificationId == s.id)
        );
        return counterSpecifications.length + counterProcessSteps.length;
      }
      return 9999999;
    },
    counterAll() {
      if (this.variant && this.specification) {
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );

        return this.variant.length + filteredSpecs.length;
      }
      return 9999999;
    },
    counterAll2() {
      if (this.specification && this.processStep) {
        let filteredSpecs = this.specification.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );
        let filteredProcessSteps = this.processStep.filter(
          (s) => !(s.status.Progress == 0 && s.status.Closed == true)
        );

        return filteredSpecs.length + filteredProcessSteps.length;
      }
      return 9999999;
    },
  },
};
</script>

<style lang="scss" scoped></style>
