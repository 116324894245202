<template>
  <div>
    <v-row dense>
      <v-icon
        class="mr-10 mt-7"
        @click="expanded = expanded.length == 0 ? process : []"
        color="primary"
      >
        mdi-arrow-expand-vertical</v-icon
      >
      <ExportResponses
        v-if="$can('export', 'response')"
        :vendor="user.vendor"
        :phase="user.vendor.ResponsePhase"
      />
      <v-text-field
        class="mt-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="process"
      :search="search"
      sort-by="Number"
      group-by="department.Name"
      show-expand
      :single-expand="false"
      :expanded.sync="expanded"
      show-group-by
      disable-pagination
      hide-default-footer
      :loading="isFindProcessPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
          {{ group }}
        </td>
      </template>
      <template v-slot:[`item.Status`]="{ item }">
        <div v-if="!loading">
          <div v-if="user.vendor.ResponsePhase == 1">
            <v-tooltip v-if="counterOpen(item) > 0" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  color="#FFCDD2"
                  x-small
                >
                  {{ counterOpen(item) }}
                </v-chip>
              </template>
              <span>Open</span>
            </v-tooltip>
            <v-tooltip v-if="counterProgress(item) > 0" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  color="#FFFCBD"
                  x-small
                >
                  {{ counterProgress(item) }}
                </v-chip>
              </template>
              <span>In Progress</span>
            </v-tooltip>
            <v-tooltip v-if="counterDone(item) > 0" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  color="#C7FFBF"
                  x-small
                >
                  {{ counterDone(item) }}
                </v-chip>
              </template>
              <span>Done</span>
            </v-tooltip>
          </div>
          <div v-if="user.vendor.ResponsePhase == 2">
            <v-tooltip v-if="counterProgress2(item) > 0" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  color="#FFFCBD"
                  x-small
                >
                  {{ counterProgress2(item) }}
                </v-chip>
              </template>
              <span>In Progress</span>
            </v-tooltip>
            <v-tooltip v-if="counterDone2(item) > 0" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  color="#C7FFBF"
                  x-small
                >
                  {{ counterDone2(item) }}
                </v-chip>
              </template>
              <span>Done</span>
            </v-tooltip>
          </div>
        </div>
        <v-progress-circular
          v-else
          indeterminate
          :size="15"
          :width="1"
        ></v-progress-circular>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length"><ProcessVariants :process="item" /></td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import ProcessVariants from '@/components/VendorResponse/ProcessVariants.vue';
import { mapGetters } from 'vuex';
import ExportResponses from '@/components/ExportData/ExportResponses';
export default {
  mixins: [
    makeFindMixin({ service: 'process' }),
    makeFindMixin({ service: 'process-step' }),
    makeFindMixin({ service: 'response' }),
  ],
  components: { ProcessVariants, ExportResponses },
  data() {
    return {
      headers: [
        { text: 'Nr', value: 'Number', groupable: false },
        { text: 'Process', value: 'Name', groupable: false },
        { text: 'Status', value: 'Status', groupable: false },
        {
          text: 'Department',
          value: 'department.Name',
          groupable: true,
        },
        { text: '', value: 'data-table-expand' },
      ],
      expanded: [],
      search: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    loading() {
      return (
        this.isFinProcessPending ||
        this.isFindResponsePending ||
        this.isFindProcessStepPending
      );
    },
    processParams() {
      return {};
    },
    processStepParams() {
      return { query: { Obsolete: false, $select: ['id', 'VariantId'] } };
    },
    responseParams() {
      let query = {};
      query.VendorId = this.user.vendor ? this.user.vendor.id : null;
      this.user.vendor.ResponsePhase == 1
        ? (query.VariantId = { $ne: null })
        : (query.ProcessStepId = { $ne: null });

      return {
        query,
      };
    },
  },
  methods: {
    counterOpen(v) {
      if (v && v.variants) {
        return (
          v.variants.length - this.counterProgress(v) - this.counterDone(v)
        );
      }
      return 9999999;
    },
    counterProgress(v) {
      if (v && v.variants && this.response) {
        let responsefiltered = this.response.filter((f) => f.Final == false);
        let counterVariants = v.variants.filter((v) =>
          responsefiltered.some((r) => r.VariantId == v.id)
        );

        return counterVariants.length;
      }
      return 9999999;
    },
    counterDone(v) {
      if (v && v.variants && this.response) {
        let responsefiltered = this.response.filter((f) => f.Final == true);
        let counterVariants = v.variants.filter((v) =>
          responsefiltered.some((r) => r.VariantId == v.id)
        );

        return counterVariants.length;
      }
      return 9999999;
    },
    counterTotal2(v) {
      if (v && v.variants && this.processStep) {
        let counter = 0;
        v.variants.forEach((f) => {
          let steps = this.processStep.filter((p) => p.VariantId == f.id);
          counter = counter + steps.length;
        });

        return counter;
      }
      return 9999999;
    },
    counterProgress2(v) {
      return this.counterTotal2(v) - this.counterDone2(v); //- steps.flat(1).length;
    },
    counterDone2(v) {
      if (v && v.variants && this.processStep && this.response) {
        let responsefiltered = this.response.filter((f) => f.Final == true);
        let steps = [];
        v.variants.forEach((f) => {
          steps.push(
            this.processStep.filter(
              (p) =>
                p.VariantId == f.id &&
                responsefiltered.some((r) => r.ProcessStepId == p.id)
            )
          );
        });

        return steps.flat(1).length;
      }
      return 9999999;
    },
  },
};
</script>

<style lang="scss" scoped></style>
