<template>
  <div>
    <h3>Overview RFP</h3>
    <ExportResponses v-if="$can('export', 'fullrepsonses')" />
    <v-row class="ma-1"
      ><v-col cols="12" md="6"> <ResponseProgress1 /></v-col
      ><v-col cols="12" md="6"> <ResponseProgress2 /></v-col
    ></v-row>
    <v-row class="ma-1"
      ><v-col cols="12" md="12"> <ResponseSend1 /></v-col
      ><v-col cols="12" md="12"> <ResponseSend2 /></v-col
    ></v-row>
    <v-row class="ma-1"
      ><v-col cols="12" md="6"> <ResponseScoring1 /></v-col
      ><v-col cols="12" md="6"> <ResponseScoring2 /></v-col
    ></v-row>
  </div>
</template>

<script>
import ResponseProgress1 from './ResponseProgress1.vue';
import ResponseProgress2 from './ResponseProgress2.vue';
import ResponseSend1 from './ResponseSend1.vue';
import ResponseSend2 from './ResponseSend2.vue';
import ResponseScoring1 from './ResponseScoring1.vue';
import ResponseScoring2 from './ResponseScoring2.vue';
import ExportResponses from '@/components/ExportData/ExportResponsesFull';
export default {
  components: {
    ResponseProgress1,
    ResponseProgress2,
    ResponseSend1,
    ResponseSend2,
    ResponseScoring1,
    ResponseScoring2,
    ExportResponses,
  },
};
</script>

<style lang="scss" scoped></style>
