<template>
  <v-container
    ><FeathersVuexFind
      v-slot="{ items: ProjectRoles }"
      service="project-roles"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="ProjectRoles"
          :items-per-page="100"
          sort-by="Code"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-if="$can('create', 'project-roles')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Project Role</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="2" md="2">
                            <v-text-field
                              v-model="editedItem.Code"
                              label="Code"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="10" md="10">
                            <v-text-field
                              v-model="editedItem.Role"
                              label="Role"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'project-roles')"
            v-slot:[`item.Code`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Code"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Code }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Code"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'project-roles')"
            v-slot:[`item.Role`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Role"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Role }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Role"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template
            v-if="$can('delete', 'priority')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section></FeathersVuexFind
    ></v-container
  >
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      headers: [
        {
          text: 'Code',
          align: 'start',
          sortable: true,
          value: 'Code',
        },
        {
          text: 'Role',
          align: 'start',
          sortable: true,
          value: 'Role',
        },

        { text: 'Delete', value: 'actions', sortable: false },
      ],
      dialog: false,
      editedItem: {
        Role: '',
      },
      defaultItem: {
        Role: '',
      },
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'project-roles')
          ? true
          : item.text != 'Delete';
      });
    },
  },
  methods: {
    save(item) {
      item
        .save()
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Project Roles', 'updated')
        )
        .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('project-roles')
        .create(this.editedItem)
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Project Roles', 'added')
        )
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) =>
          handleSaveResponse(saved.Name, 'Project Roles', 'removed')
        )
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
