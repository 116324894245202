<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <ProcessCommentEditDialog
        :item="clone"
        :saving="saving"
        @save="
          saving = true;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Comment,
                'comment',
                currentItem ? 'updated' : 'created'
              );
              await refresh();
              showDialog = false;
              saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
              saving = false;
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Comment, 'comment', 'removed');
              await refresh();
              showDialog = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            })
        "
        @cancel="showDialog = false"
      ></ProcessCommentEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import ProcessCommentEditDialog from '@/components/ProcessStep/ProcessCommentEditDialog';

export default {
  name: 'ProcessCommentEdit',
  components: {
    FeathersVuexFormWrapper,
    ProcessCommentEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  watch: {
    currentItem() {
      const { Comment } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = this.currentItem;
      } else {
        let newComment = new Comment();
        newComment.ProcessStepId = this.stepid;
        newComment.TestScenarioRunStepId = this.steprunid;
        this.item = newComment;
      }
    },
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    stepid: {},
    steprunid: {},
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Comment, Status, ProcessStep, TestScenarioRunSteps } =
        this.$FeathersVuex.api;
      await ProcessStep.find({ query: {} });
      await Comment.find({
        query: { ProcessStepId: this.stepid },
        include: {
          model: Status,
        },
      });
      if (this.steprunid) {
        await TestScenarioRunSteps.get(this.steprunid);
      }
    },
  },
  mounted() {
    const { Comment } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = this.currentItem;
    } else {
      let newComment = new Comment();
      newComment.ProcessStepId = this.stepid;
      newComment.TestScenarioRunStepId = this.steprunid;
      this.item = newComment;
    }
  },
};
</script>
