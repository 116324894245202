<template>
  <div v-if="process && toggle">
    <v-card class="ma-2 pa-2" autoheight>
      <v-row class="subtitle-1"
        ><v-col cols="3">Imported Text</v-col><v-col cols="3">Pages</v-col
        ><v-col cols="6">Link to Existing Process</v-col></v-row
      >
      <v-row>
        <v-col cols="3" v-bind:class="{ 'warning--text': process.Existing }"
          >{{ process.Number }}. {{ process.Name }} </v-col
        ><v-col cols="3">
          <v-select
            v-if="!process.Existing"
            chips
            small-chips
            v-model="process.SelectedPageIndexes"
            :items="documentParsed"
            item-text="title"
            item-value="index"
            label="Pages"
            outlined
            dense
            hide-details
            multiple
          ></v-select></v-col
        ><v-col cols="6">
          <v-autocomplete
            v-if="!process.Existing"
            :disabled="process.linkExisting"
            v-model="process.linkExistingProcess"
            :items="fullProcessList"
            item-text="Name"
            item-value="id"
            return-object
            dense
            hide-details
            outlined
            clearable
            label="Select Process"
            @change="
              linkProcess();
              toggle = !toggle;
              toggle = !toggle;
            "
          >
            <template v-slot:append>
              <v-icon
                v-if="process.linkExisting && process.linkExistingProcess"
                color="green"
                >mdi-check-bold</v-icon
              >
            </template>
            <template v-if="!process.Existing" v-slot:append-outer>
              <v-icon
                v-if="!process.linkExisting && process.linkExistingProcess"
                color="green"
                :disabled="!process.linkExistingProcess"
                @click="linkProcess()"
                >mdi-link</v-icon
              >
              <v-icon
                v-if="process.linkExisting"
                color="red"
                :disabled="!process.linkExistingProcess"
                @click="linkProcess()"
                >mdi-link-off</v-icon
              >
              <v-icon
                v-if="!process.linkExistingProcess || !process.linkExisting"
                >mdi-new-box</v-icon
              >
            </template>
          </v-autocomplete>
          <div v-if="process.Existing">
            <v-icon small color="warning" class="mr-3">mdi-sitemap</v-icon
            ><span>Process exists</span>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-btn v-if="!process.Existing" color="primary" @click="clickSaveProcess()">
      Save >>
    </v-btn>
    <v-btn v-if="process.Existing" text outlined @click="next"> Skip </v-btn>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  props: ['documentInfo', 'documentParsed'],
  mixins: [
    makeFindMixin({ service: 'department' }),
    makeFindMixin({ service: 'process', name: 'fullProcessList' }),
  ],
  data() {
    return {
      process: null,
      toggle: true,
    };
  },
  methods: {
    linkProcess() {
      this.process.linkExisting = !this.process.linkExisting;
      this.process.linkExistingProcess
        ? null
        : this.process.linkExistingProcess;

      this.toggle = false;
      this.toggle = true;
    },
    async clickSaveProcess() {
      let pageindex = this.process.SelectedPageIndexes.filter((f) => f != null); // remove nulls
      pageindex = pageindex.map((item) => item + 1);
      this.process.PageIndexes = String(pageindex);

      let saved = null;

      if (this.process.linkExisting) {
        // patch
        try {
          saved = await feathersClient
            .service('process')
            .patch(this.process.linkExistingProcess.id, {
              ExternalDocumentId: this.process.ExternalDocumentId,
              PageIndexes: this.process.PageIndexes,
            });
        } catch (error) {
          handleErrorResponse(error);
        }
        handleSaveResponse(saved.Name, 'Process', 'updated');
      } else {
        //create
        try {
          saved = await this.process.save();
        } catch (error) {
          handleErrorResponse(error);
        }
        handleSaveResponse(saved.Name, 'Process', 'added');
      }
      this.process.id = saved.id;

      // exit send process and next step
      this.next();
    },
    next() {
      this.$emit('next', { type: 'process', data: this.process });
    },
  },
  computed: {
    departmentParams() {
      return {};
    },
    fullProcessListParams() {
      return {};
    },

    processNumber() {
      let number = parseInt(this.documentInfo.title.substring(0, 4));
      return number;
    },
    departmentImported() {
      if (this.processNumber && this.department) {
        let numberRounder = Math.floor(this.processNumber / 100) * 100;
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        numberRounder = zeroPad(numberRounder, 4);
        let result = this.department.filter(
          (item) => item.CustomerDepartment == numberRounder
        );
        return result;
      } else return null;
    },
  },
  async mounted() {
    const { Process } = this.$FeathersVuex.api;
    let existingProcess = await Process.find({
      query: { ExternalDocumentId: this.documentInfo.id },
    });

    if (
      existingProcess &&
      existingProcess.data &&
      existingProcess.data.length > 0
    ) {
      this.process = existingProcess.data[0];
      this.process.Existing = true;
    } else {
      this.process = new Process();
      this.process.Existing = false;
      this.process.Number = this.processNumber;
      this.process.Name = this.documentInfo.title
        .substring(5, 9999)
        .replace(/(^\W*)|(\W*$)/g, '');
      this.process.ExternalDocumentId = this.documentInfo.id;
      this.process.DepartmentId = this.departmentImported
        ? this.departmentImported[0].id
        : null;
      this.process.StatusId = 1;
      this.process.SelectedPageIndexes = [0];
      let processList = await Process.find();
      this.process.linkExistingProcess = processList.data.find(
        (f) =>
          f.Name.toLowerCase().trim() ==
          this.documentInfo.title
            .substring(5, 9999)
            .replace(/(^\W*)|(\W*$)/g, '')
            .toLowerCase()
            .trim()
      );
      if (this.process.linkExistingProcess) {
        this.process.linkExisting = true;
      } else {
        this.process.linkExisting = false;
        this.process.linkExistingProcess = null;
      }
    }
    this.toggle = false;
    this.toggle = true;
  },
};
</script>

<style lang="scss" scoped></style>
