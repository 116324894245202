<template>
  <v-dialog v-model="showDialog" width="1000" height="500">
    <v-card flat>
      <v-card-title>
        <span class="headline">Add Severities for {{ item.Type }}</span
        ><v-spacer></v-spacer>
      </v-card-title>
      <section>
        <v-data-table
          :headers="headers"
          :items="item.issue_type_severities"
          :items-per-page="20"
          :sort-by="['Order']"
          :sort-desc="[false]"
          item-key="id"
          class="elevation-1"
          disable-pagination
          :hide-default-footer="true"
          loading-text="Loading... Please wait"
        >
          <template v-if="$can('create', 'issue-type-severity')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-select
                v-model="selectedType"
                v-if="item.issue_type_severities.length == 0"
                :items="issueType"
                item-text="Type"
                item-value="id"
                label="Issue Type"
                outlined
                dense
                hide-details="true"
                ><template v-slot:item="{ item }">
                  <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                  {{ item.Type
                  }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                    >mdi-connection</v-icon
                  >
                </template>
                <template #selection="{ item }">
                  <v-icon small class="mr-2"> {{ item.Icon }} </v-icon>
                  {{ item.Type
                  }}<v-icon v-if="item.ExternalType" class="mb-3 ml-1" x-small
                    >mdi-connection</v-icon
                  >
                </template></v-select
              ><v-btn
                v-if="item.issue_type_severities.length == 0"
                color="primary"
                @click="copyFields"
                :disabled="
                  !selectedType || item.issue_type_severities.length > 0
                "
                >Copy From</v-btn
              >
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-if="dialog" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Severity</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field
                              v-model="editedItem.ExternalSeverity"
                              label="External Severity"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="editedItem.Order"
                              label="Order"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-checkbox
                              v-model="editedItem.Initial"
                              label="Initial"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-checkbox
                              v-model="editedItem.Blocking"
                              label="Blocking"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Add</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'issue-type-severity')"
            v-slot:[`item.ExternalSeverity`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.ExternalSeverity"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.ExternalSeverity }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.ExternalSeverity"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'issue-type-severity')"
            v-slot:[`item.Order`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Order"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Order }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Order"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.Initial`]="props">
            <v-edit-dialog
              v-if="$can('update', 'external-issue-severity')"
              :return-value.sync="props.item.Initial"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Initial">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.Initial"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.Closed && !$can('update', 'status')">
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>
          <template v-slot:[`item.Blocking`]="props">
            <v-edit-dialog
              v-if="$can('update', 'external-issue-severity')"
              :return-value.sync="props.item.Blocking"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              <span v-if="props.item.Blocking">
                <v-icon small class="mr-2" color="primary">
                  mdi-check-bold
                </v-icon>
              </span>
              <template v-slot:input>
                <v-simple-checkbox
                  v-model="props.item.Blocking"
                ></v-simple-checkbox>
              </template>
            </v-edit-dialog>
            <span v-if="props.item.Closed && !$can('update', 'status')">
              <v-icon small class="mr-2" color="primary">
                mdi-check-bold
              </v-icon>
            </span>
          </template>
          <template
            v-if="$can('delete', 'issue-type-severity')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import feathersClient from '../../feathers-client';
import { makeFindMixin } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  props: {
    value: { type: Boolean },
    item: {},
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      dialog: false,
      validInput: false,
      selectedType: null,
      editedItem: {},
    };
  },
  mixins: [makeFindMixin({ service: 'issue-type' })],
  methods: {
    async save(item) {
      try {
        await feathersClient.service('issue-type-severity').patch(item.id, {
          ExternalSeverity: item.ExternalSeverity,
          Initial: item.Initial,
          Blocking: item.Blocking,
          Order: item.Order,
        });
        handleSaveResponse(item.id, 'External Issue Type', 'updated');
      } catch (error) {
        handleErrorResponse(error);
      }
      await this.Refresh();
    },
    open() {},
    cancel() {},
    close() {},
    Close() {
      this.dialog = false;
      this.validInput = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    async Refresh() {
      const { IssueType } = this.$FeathersVuex.api;
      await IssueType.find({
        query: {},
      });
    },
    async copyFields() {
      const result = await feathersClient
        .service('issue-type-severity')
        .find({ query: { IssueTypeId: this.selectedType } });

      if (result.total > 0) {
        for (const severity of result.data) {
          await feathersClient.service('issue-type-severity').create({
            ExternalSeverity: severity.ExternalSeverity,
            Order: severity.Order,
            Initial: severity.Initial,
            Blocking: severity.Blocking,
            IssueTypeId: this.item.id,
          });
        }
      }
      await this.Refresh();
      this.selectedType = null;
    },
    async Add() {
      this.editedItem.IssueTypeId = this.item.id;
      try {
        await feathersClient
          .service('issue-type-severity')
          .create(this.editedItem);
        handleSaveResponse('', 'Issue Type Status', 'added');
      } catch (error) {
        handleErrorResponse(error);
      }
      await this.Refresh();

      this.Close();
    },
    async deleteItem(item) {
      await feathersClient
        .service('issue-type-severity')
        .remove(item.id)
        .then(() => handleSaveResponse(item.id, 'Issue Type Status', 'removed'))
        .catch((error) => handleErrorResponse(error));
      await this.Refresh();
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    issueTypeParams() {
      return { query: { id: { $ne: this.item.id } } };
    },
    headers() {
      return [
        {
          text: 'External Severity',
          align: 'start',
          sortable: true,
          value: 'ExternalSeverity',
        },
        {
          text: 'Order',
          align: 'start',
          sortable: true,
          value: 'Order',
        },
        {
          text: 'Initial',
          align: 'center',
          sortable: false,
          value: 'Initial',
        },
        {
          text: 'Blocking',
          align: 'center',
          sortable: false,
          value: 'Blocking',
        },

        { text: 'Delete', value: 'actions', sortable: false },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
