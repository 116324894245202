<template>
  <div>
    <v-dialog
      v-if="showDialog"
      :value="showDialog"
      persistent
      max-width="1300px"
    >
      <v-card flat class="pb-2">
        <v-card-title>
          <span class="headline" v-if="!newIssue"
            >{{ CurrentIssue.Title }}
            <a :href="CurrentIssue.Url" target="_blank">{{
              CurrentIssue.ExternalId
            }}</a>
            ({{ CurrentIssue.Closed ? 'Closed' : 'Open' }})</span
          >
          <span v-else class="headline">Add Task</span>
        </v-card-title>
        <v-card-text v-if="newIssue">
          <v-row class="mb-5"
            ><v-col cols="12" md="6">
              <v-text-field
                v-model="CurrentIssue.Title"
                label="Task Name*"
                outlined
                dense
                hide-details
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="selectedType"
                :items="IssueTypes"
                label="Select Type"
                dense
                outlined
                hide-details
                :disabled="type == 'Comment'"
              ></v-select></v-col
            ><v-col cols="12" md="3">
              <v-checkbox
                v-model="blocking"
                dense
                hide-details
                label="Blocking"
                :disabled="type == 'Comment'"
              ></v-checkbox
            ></v-col>
          </v-row>
          <RichEditor
            v-if="!init"
            v-model="CurrentIssue.Content1"
            :disabled="false"
            placeholder="What is your problem"
          ></RichEditor>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="showDialog = false">Cancel</v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              @click="save"
              :disabled="!CurrentIssue.Content1 || !CurrentIssue.Title"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-card-text v-else>
          {{
            CurrentIssue.Blocking ? `Blocking ${type}` : `Non-Blocking ${type}`
          }}

          <v-card elevation="2" class="ma-10">
            <!-- eslint-disable vue/no-v-text-v-html-on-component -->
            <v-card-text class="ql-editor" v-html="CurrentIssue.Content1">
            </v-card-text>
            <v-card-text class="ql-editor" v-html="CurrentIssue.Content2">
            </v-card-text>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import RichEditor from '@/components/general/RichEditor.vue';

import {
  handleErrorResponse,
  handleSaveResponse,
} from '@/utils/MessageHandler';
import { CreateWorkItem as CreateWorkItemDevOps } from '@/utils/DevOpsFunctions';
import { CreateWorkItem as CreateWorkItemJira } from '@/utils/JiraFunctions';
import { htmlToText } from 'html-to-text';

export default {
  components: {
    RichEditor,
  },
  props: {
    value: { type: Boolean },
    type: { type: String, required: true },
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      CurrentIssue: {
        Title: null,
        Content1: null,
      },
      selectedType: this.type == 'Comment' ? 'Task' : 'Bug',
      blocking: this.type == 'Comment' ? false : true,
      IssueTypes: ['Bug', 'Task'],
      loading: false,
      init: true,
      newIssue: true,
      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    htmlToText,
    openWindow(url) {
      window.open(url, 'popup');
      return false;
    },

    async save() {
      this.loading = true;
      let WorkItem = null;
      try {
        if (this.$appConfig.externalIssues == 'Jira') {
          WorkItem = await CreateWorkItemJira(
            this.CurrentIssue.Title,
            this.selectedType,
            this.blocking,
            this.CurrentIssue.Content1,
            '',
            this.item.process_step ? this.item.process_step.variant : null
          );
        } else if (this.$appConfig.externalIssues == 'DevOps') {
          WorkItem = await CreateWorkItemDevOps(
            this.CurrentIssue.Title,
            this.selectedType,
            this.blocking,
            this.CurrentIssue.Content1,
            '',
            this.item.process_step ? this.item.process_step.variant : null
          );
        }

        if (WorkItem) {
          this.CurrentIssue.ExternalId = WorkItem.id; //WorkItem.data.id;
          this.CurrentIssue.Type = this.selectedType;
          this.type == 'Comment'
            ? (this.CurrentIssue.CommentId = this.item.id)
            : null;
          this.CurrentIssue.Url = WorkItem.link; //WorkItem.data._links.html.href;
          this.CurrentIssue.Blocking = this.blocking;
          await this.CurrentIssue.save();
          await this.refresh();
          this.showDialog = false;
        } else {
          throw new Error('Error creating task');
        }

        handleSaveResponse('', 'Task', 'saved');
      } catch (error) {
        // console.log(error);
        handleErrorResponse(error);
      }

      this.loading = false;
    },
    async refresh() {
      if (this.type == 'Comment') {
        const { Comment } = this.$FeathersVuex.api;
        await Comment.find({ query: { id: this.item.id } });
      }
    },
  },
  async mounted() {
    this.loading = true;
    const { ExternalIssues } = this.$FeathersVuex.api;
    switch (this.type) {
      case 'Comment':
        this.CurrentIssue = await ExternalIssues.find({
          query: { CommentId: this.item.id },
        });
        if (this.CurrentIssue.total > 0) {
          this.CurrentIssue = this.CurrentIssue.data[0];
          this.newIssue = false;
        } else {
          this.CurrentIssue = new ExternalIssues();
          this.CurrentIssue.Title = 'Task from Comment';
          this.CurrentIssue.Content1 = this.item.Comment;
          this.newIssue = true;
        }
        break;
      case 'TestScenario':
        this.CurrentIssue = await ExternalIssues.find({
          query: { TestScenarioRunStepId: this.item.id },
        });
        if (this.CurrentIssue.total > 0) {
          this.CurrentIssue = this.CurrentIssue.data[0];
          this.newIssue = false;
        } else {
          this.CurrentIssue = new ExternalIssues();
          this.CurrentIssue.Title = 'Task from Test'; // `Probleem met '${item.Name}' bij '${item.TestScenarioStep.ProcessStep.variant.Name}'`
          this.CurrentIssue.Content1 = 'test';
          this.newIssue = true;
        }
        break;

      default:
        break;
    }
    this.loading = false;
    this.init = false;
  },
};
</script>

<style lang="scss" scoped></style>
