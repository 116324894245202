<template>
  <v-dialog :value="true" persistent max-width="1100px">
    <div v-if="item">
      <v-form ref="form" v-model="validInput">
        <v-card flat>
          <v-card-title>
            <span class="headline">New/Edit End 2 End Process</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="1">
                <v-text-field
                  v-model="item['Number']"
                  label="Nr"
                  outlined
                  dense
                  :rules="[rules.required, rules.number]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="item['Name']"
                  label="End 2 End Process Name*"
                  :rules="[rules.required]"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item['ExternalDocumentId']"
                  label="ExternalDocumentId"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="item['PageIndexes']"
                  label="Page Indexes"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            ><v-btn
              v-if="$can('delete', 'end-2-end')"
              outlined
              @click="showRemoveMessage = true"
              >Remove</v-btn
            >
            <div v-if="showRemoveMessage" class="ml-5">
              Are you sure? Linked to
              {{ linkedProcess }} processes.
              <v-btn class="ml-2" @click="$emit('remove')" color="error"
                >Yes</v-btn
              >
            </div>
            <v-spacer></v-spacer>
            <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
            <v-btn
              :disabled="!validInput"
              color="primary"
              :loading="saving"
              @click="$emit('save')"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'End2EndEditDialog',
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      linkedProcess: 0,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
  async mounted() {
    if (this.item && this.item.id) {
      const { end2EndProcess } = this.$FeathersVuex.api;
      let count = await end2EndProcess.count({
        query: { end2EndId: this.item.id },
      });
      this.linkedProcess = count ? count : 0;
    }
  },
};
</script>
