<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <ProfileEditDialog
        :item="clone"
        @save="
          showDialog = false;
          save()
            .then(handleSaveResponse)
            .catch(errorHandler);
        "
        @reset="reset"
        @remove="remove"
        @cancel="$router.go(-1)"
      ></ProfileEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import ProfileEditDialog from '@/components/Profile/ProfileEditDialog';

export default {
  name: 'ProfileEdit',
  components: {
    FeathersVuexFormWrapper,
    ProfileEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    item() {
      return this.currentItem;
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse(savedStep) {
      this.$toasted.global.success(
        `Profile '${savedStep.fullname}' saved successfully`
      );

      this.$router.push('/Process');
    },
    errorHandler(error) {
      //console.log(error);
      let errorMessage = '';
      if (
        Object.keys(error.errors).length === 0 &&
        error.errors.constructor === Object
      ) {
        errorMessage = error.message;
      } else {
        error.errors.map(item => {
          errorMessage += item.message + '\n';
        });
        this.errorMessage = errorMessage;
      }
      this.$toasted.global.error(errorMessage);
    },
  },
};
</script>
