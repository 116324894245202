<template>
  <div>
    <h1>Process Import</h1>
    <CopyProcess />
  </div>
</template>

<script>
import CopyProcess from '@/components/DocumentPicker/CopyProcess';
export default {
  components: { CopyProcess },
  data() {
    return {
      selectedTab: 0,
    };
  },
};
</script>

<style lang="scss" scoped></style>
