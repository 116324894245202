<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <FieldEditDialog
        :item="clone"
        :saving="saving"
        @save="
          saving = true;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Name,
                'field',
                currentItem ? 'updated' : 'created'
              );

              const { FieldStep } = $FeathersVuex.api;
              let exists = await FieldStep.find({
                query: {
                  FieldId: saved.id,
                  ProcessStepId: saved.ProcessStepId,
                },
              });

              if (exists.data.length == 0 && saved.ProcessStepId != null) {
                let newRecord = new FieldStep();
                newRecord.ProcessStepId = saved.ProcessStepId;
                newRecord.FieldId = saved.id;
                newRecord.save().then(async (saved2) => {
                  handleSaveResponse(saved2.id, 'field step', 'saved');
                  await refresh();
                  saving = false;
                  showDialog = false;
                });
              } else {
                await refresh();
                saving = false;
                showDialog = false;
              }
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
              saving = false;
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              saving = true;
              handleSaveResponse(saved.Name, 'field', 'removed');
              await refresh();
              showDialog = false;
              saving = false;
            })
            .catch((error) => handleErrorResponse(error))
        "
        @cancel="showDialog = false"
      ></FieldEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import FieldEditDialog from '@/components/Fields/FieldEditDialog';

export default {
  name: 'FieldEdit',
  components: {
    FeathersVuexFormWrapper,
    FieldEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    processStep: {
      type: Object,
      required: false,
    },
  },
  watch: {
    currentItem() {
      const { Field } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = this.currentItem;
      } else {
        let newField = new Field();
        newField.ProcessStepId = this.processStep ? this.processStep.id : null;
        this.item = newField;
      }
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Field, ProcessStep, FieldStep } = this.$FeathersVuex.api;
      await Field.find({
        query: {},
      });
      await FieldStep.find({
        query: {},
      });
      await ProcessStep.find({
        query: {},
      });
    },
  },
  mounted() {
    const { Field } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = this.currentItem;
    } else {
      let newField = new Field();
      newField.ProcessStepId = this.processStep ? this.processStep.id : null;
      this.item = newField;
    }
  },
};
</script>
