<template>
  <div>
    <v-icon class="mr-10 mt-7" @click="exportToExcel()" color="primary">
      mdi-microsoft-excel
    </v-icon>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { generateExcel } from '@/utils/ExportExcel';
export default {
  mixins: [makeFindMixin({ service: 'export-queries' })],
  methods: {
    exportToExcel() {
      this.exportQueriesLatestQuery.response.forEach((i) => {
        // i.RoleUpdate = new Date(i.RoleUpdate).toLocaleDateString();
        // i.ApplicationUpdate = new Date(
        //   i.ApplicationUpdate
        // ).toLocaleDateString();
        // i.VariantApplicationUpdate = new Date(
        //   i.VariantApplicationUpdate
        // ).toLocaleDateString();
        i.LatestUpdate = new Date(i.LatestUpdate).toLocaleDateString();
      });
      generateExcel(
        [
          {
            Roles: this.exportQueriesLatestQuery.response,
          },
        ],
        `Loxi Roles.xlsx`
      );
    },
  },
  computed: {
    exportQueriesParams() {
      return {
        query: {
          type: 'roles',
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
