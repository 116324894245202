<template>
  <v-container>
    <h1>Dashboard</h1>
    <v-tabs v-model="tab" active-class="activeTab">
      <v-tab>Progress</v-tab>
      <v-tab>Budget</v-tab>
      <v-tab>RFP Response</v-tab>
    </v-tabs>
    <v-tabs-items class="mb-5" v-model="tab">
      <v-tab-item> <ProgressOverview /> </v-tab-item>
      <v-tab-item> Budget </v-tab-item>
      <v-tab-item> <RFPOverview /></v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import RFPOverview from '@/components/Dashboard/RFPStatus/RFPOverview.vue';
import ProgressOverview from '@/components/Dashboard/Progress/ProgressOverview.vue';

export default {
  components: { RFPOverview, ProgressOverview },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped></style>
