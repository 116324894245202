import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { abilitiesPlugin } from '@casl/vue';

import Toasted from 'vue-toasted';

import configPlugin from '@/config';
import i18n from './i18n';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(
  'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bdkBjX3xbdXddQmVU'
);

Vue.use(configPlugin);
Vue.use(abilitiesPlugin, store.state.casl.ability);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(Toasted, {
  duration: 5000,
  position: 'bottom-center',
  keepOnHover: true,
  theme: 'bubble',
  iconPack: 'mdi',
});

Vue.toasted.register(
  'error',
  function (message) {
    return message;
  },
  {
    type: 'error',
    position: 'bottom-center',
    keepOnHover: true,
    theme: 'bubble',
    iconPack: 'mdi',
    icon: 'mdi-alert-circle',
    duration: 5000,
  }
);

Vue.toasted.register(
  'success',
  function (message) {
    return message;
  },
  {
    type: 'success',
    icon: 'mdi-check',
    duration: 3000,
  }
);

Vue.toasted.register(
  'info',
  function (message) {
    return message;
  },
  {
    type: 'info',
    icon: 'mdi-alert',
    duration: 3000,
  }
);
