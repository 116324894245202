<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title>
            <v-spacer> </v-spacer>
            <v-btn
              v-if="$can('create', 'fieldvaluelist')"
              elevation="0"
              class="mx-2"
              x-small
              fab
              color="primary"
              @click="clickAdd()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="filteredHeaders"
            :items="fieldvaluelist"
            sort-by="Number"
            disable-pagination
            hide-default-footer
            :hide-default-header="
              $can('delete', 'fieldvalielist') ? true : false
            "
            dense
            class="text-pre-wrap"
            :loading="isFindFieldvaluelistPending"
            loading-text="Loading... Please wait"
          >
            <template
              v-if="$can('update', 'fieldvaluelist')"
              v-slot:[`item.edit`]="{ item }"
            >
              <v-icon
                small
                class="mr-2"
                @click="clickEdit(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <FieldValueEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :fieldId="currentFieldId"
    />
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import FieldValueEdit from '@/components/FieldValues/FieldValueEdit';

export default {
  props: ['fieldId'],
  data() {
    return {
      search: '',
      currentFieldId: null,
      headers: [
        {
          text: 'Edit',
          value: 'edit',
          sortable: false,
          groupable: false,
        },
        { text: 'Nr', value: 'Number', groupable: false },
        { text: 'Code', value: 'Code', groupable: false },
        { text: 'Value', value: 'Value', groupable: false },
        { text: 'Description', value: 'Description', groupable: false },
      ],
      detailDialog: false,
      currentItem: [],
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('update', 'fieldvaluelist')
          ? true
          : item.text != 'Edit';
      });
    },
    fieldvaluelistParams() {
      return { query: { FieldId: this.fieldId } };
    },
  },
  mixins: [makeFindMixin({ service: 'fieldvaluelist' })],
  components: {
    FieldValueEdit,
  },
  methods: {
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
      this.currentFieldId = this.fieldId;
    },
    clickDetail(clickedProcess) {
      this.setCurrentProcess(clickedProcess);
      this.$router.push({
        name: 'Process',
        params: { id: clickedProcess.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
