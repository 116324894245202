<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="item in requirementType" :key="item">
        <v-icon class="mr-2"> {{ item.Icon }}</v-icon> {{ item.Type }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in requirementType" :key="item">
        <v-card flat>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <VariantReqs :requirementTypeId="item.id" />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import VariantReqs from '@/components/Requirement/VariantReqs';
import { makeFindMixin } from 'feathers-vuex';
export default {
  components: { VariantReqs },
  mixins: [
    makeFindMixin({
      service: 'requirement-type',
    }),
  ],

  computed: {
    requirementTypeParams() {
      return {};
    },
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
