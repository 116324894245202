<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog && this.item">
    <template v-slot="{ clone, save, reset, remove }">
      <GroupEditDialog
        :item="clone"
        :saving="saving"
        :variant="variant"
        @save="
          saving = true;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Name,
                'group',
                currentItem ? 'updated' : 'created'
              );
              await refresh();
              $emit('reload');
              item = null;
              showDialog = false;
              saving = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
              saving = false;
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Name, 'group', 'removed');
              await refresh();
              $emit('reload');
              showDialog = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            })
        "
        @cancel="showDialog = false"
      ></GroupEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import GroupEditDialog from '@/components/ProcessStep/Group/GroupEditDialog';

export default {
  name: 'GroupEdit',
  components: {
    FeathersVuexFormWrapper,
    GroupEditDialog,
  },
  data() {
    return { item: null, saving: false };
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    variant: {},
  },
  watch: {
    async currentItem() {
      const { Group } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = await Group.get(this.currentItem.id);
      } else {
        let newGroup = new Group();
        newGroup.VariantId = this.variant;
        newGroup.Color = '#FFFFFF';
        this.item = newGroup;
      }
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Group, ProcessStep } = this.$FeathersVuex.api;
      await Group.find({
        query: { VariantId: this.variant },
      });
      await ProcessStep.find({
        query: { VariantId: this.variant },
      });
    },
  },
  async mounted() {
    const { Group } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = await Group.get(this.currentItem.id);
    } else {
      let newGroup = new Group();
      newGroup.VariantId = this.variant;
      newGroup.Color = '#FFFFFF';
      this.item = newGroup;
    }
  },
};
</script>
