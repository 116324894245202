<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog && this.item">
    <template v-slot="{ clone, save, reset, remove }">
      <TestScenarioEditDialog
        v-if="showDialog"
        :item="clone"
        :stepsToAdd="stepsToAdd"
        :saving="saving"
        @save="
          saving = true;
          saveItem(save);
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Name, 'test scenario', 'removed');
              showDialog = false;
            })
            .catch(async (error) => {
              handleErrorResponse(error);
            })
        "
        @cancel="showDialog = false"
        @updateSteps="updateSteps"
        :addModus="currentItem ? false : true"
        @close="close"
      ></TestScenarioEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import TestScenarioEditDialog from '@/components/TestScenarios/TestScenario/TestScenarioEditDialog';

export default {
  name: 'TestScenarioEdit',
  components: {
    FeathersVuexFormWrapper,
    TestScenarioEditDialog,
  },
  data() {
    return { item: null, saving: false, stepsToAdd: [] };
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    testScenarioHeader: {
      type: Object,
      required: false,
    },
    maxOrder: {
      type: Number,
      required: false,
    },

    variant: {},
    // openAdd: { type: Function },
  },
  watch: {
    currentItem() {
      const { TestScenarioLine } = this.$FeathersVuex.api;
      if (this.currentItem) {
        this.item = this.currentItem;
      } else {
        let newTestScenarioLine = new TestScenarioLine();
        newTestScenarioLine.Order = this.maxOrder + 1;
        newTestScenarioLine.TestScenarioHeaderId = this.testScenarioHeader.id;
        newTestScenarioLine.variant = {};
        newTestScenarioLine.VariantId = this.variant?.id;
        newTestScenarioLine.variant.id = this.variant?.id;
        newTestScenarioLine.variant.ProcessId = this.variant?.ProcessId;
        newTestScenarioLine.variant.Name = this.variant?.Name;
        this.item = newTestScenarioLine;
      }
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    updateSteps(steps) {
      this.stepsToAdd = steps;
    },
    async saveItem(save) {
      let saved = null;

      try {
        saved = await save();
        handleSaveResponse(
          saved.Name,
          'test scenario',
          this.currentItem ? 'updated' : 'created'
        );

        this.saving = false;
      } catch (error) {
        handleErrorResponse(error);
        this.saving = false;
      }

      if (this.stepsToAdd?.length > 0) {
        for (const [index, step] of this.stepsToAdd
          .sort((a, b) => a.Number - b.Number)
          .entries()) {
          try {
            await feathersClient.service('test-scenario-line-step').create({
              TestScenarioLineId: saved.id,
              ProcessStepId: step.id,
              GroupId: step.GroupId, // TO DO what if multiple groups
              Number: index + 1,
            });
            handleSaveResponse(step.Name, 'Step', 'Added');
          } catch (error) {
            handleErrorResponse(error);
          }
        }
      }
      // if (this.testScenarioHeader) {
      //   this.openAdd(saved);
      // }
    },
    close() {
      this.showDialog = false;
    },
  },
  mounted() {
    const { TestScenarioLine } = this.$FeathersVuex.api;
    if (this.currentItem) {
      this.item = this.currentItem;
    } else {
      let newTestScenarioLine = new TestScenarioLine();
      newTestScenarioLine.Order = this.maxOrder + 1;
      newTestScenarioLine.TestScenarioHeaderId = this.testScenarioHeader.id;
      newTestScenarioLine.variant = {};
      newTestScenarioLine.VariantId = this.variant?.id;
      newTestScenarioLine.variant.id = this.variant?.id;
      newTestScenarioLine.variant.ProcessId = this.variant?.ProcessId;
      newTestScenarioLine.variant.Name = this.variant?.Name;
      this.item = newTestScenarioLine;
    }
  },
  destroyed() {
    this.$emit('reload');
  },
};
</script>
