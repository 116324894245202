import feathersClient from '@/feathers-client';

async function getSeverity(issueTypeId, value) {
  let fields = await feathersClient.service('issue-type-severity').find({
    query: {
      Blocking: value,
      IssueTypeId: issueTypeId,
    },
  });

  if (fields.total > 0) {
    fields = fields.data.sort((a, b) => {
      return Number(b.Initial) - Number(a.Initial) || a.Order - b.Order;
    });
    return fields[0].ExternalSeverity;
  } else return null;
}

async function getType(issueTypeId, value) {
  let fields = await feathersClient.service('issue-type').find({
    query: {
      Type: value,
    },
  });

  if (fields.total > 0) {
    return fields.data[0].ExternalType;
  } else return null;
}
async function getStatus(issueTypeId, value) {
  let fields = await feathersClient.service('issue-type-status').find({
    query: {
      StatusId: value,
      IssueTypeId: issueTypeId,
    },
  });

  if (fields.total > 0) {
    return fields.data[0].ExternalStatus;
  } else return null;
}

export { getSeverity, getType, getStatus };
