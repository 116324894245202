<template>
  <v-container>
    <h1>Process Import</h1>
    <v-tabs
      class="mt-5"
      v-model="selectedTab"
      fixed-tabs
      active-class="activeTab"
    >
      <v-tab> Copy Process </v-tab>
      <v-tab> Import Process </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item><CopyProcess /> </v-tab-item>
      <v-tab-item><DocumentPicker /> </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import DocumentPicker from '@/components/DocumentPicker/DocumentPicker';
import CopyProcess from '@/components/DocumentPicker/CopyProcess';
export default {
  components: { DocumentPicker, CopyProcess },
  data() {
    return {
      selectedTab: 0,
    };
  },
};
</script>

<style scoped></style>
