<template>
  <div>
    <DrawIO
      :fullscreen="true"
      :variantId="idVariant"
      :processId="idProcess"
      :end2EndId="idEnd2End"
      :testScenarioHeaderId="idTestScenarioHeader"
    />
  </div>
</template>

<script>
import DrawIO from '@/components/Drawing/drawio.vue';

export default {
  components: { DrawIO },
  computed: {
    idVariant() {
      return parseInt(this.$route.query.variant);
    },
    idProcess() {
      return parseInt(this.$route.query.process);
    },
    idEnd2End() {
      return parseInt(this.$route.query.end2end);
    },
    idTestScenarioHeader() {
      return parseInt(this.$route.query.testScenarioHeader);
    },
  },

  mounted() {
    // console.log(this.$route.query);
  },
};
</script>

<style lang="scss" scoped></style>
