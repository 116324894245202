<template>
  <section class="my-5">
    <v-row
      ><v-col>
        <v-text-field
          class="mb-5"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          @click:clear="search = null"
        ></v-text-field></v-col
      ><v-col>
        <v-btn
          v-if="$can('create', 'specification')"
          elevation="0"
          class="mx-2 mt-2"
          fab
          small
          color="primary"
          @click="clickAdd()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-btn
          v-if="variant && $can('create', 'specification-variant')"
          elevation="0"
          class="mx-2 ml-5 mt-2"
          fab
          small
          color="primary"
          @click="clickAddSpecification()"
        >
          <v-icon dark> mdi-playlist-plus </v-icon>
        </v-btn></v-col
      ></v-row
    >

    <div class="mt-5">
      <v-row
        ><v-col
          cols="12"
          md="6"
          v-for="(spec, i) in filteredSpecifications"
          :key="spec.id"
        >
          <v-card :loading="isFindSpecificationPending" height="100%">
            <template slot="progress">
              <v-progress-linear
                v-if="isFindSpecificationPending"
                indeterminate
                color="secondary"
              ></v-progress-linear>
            </template>

            <v-card-title
              class="subtitle-2"
              :class="{ obsolete: spec.Obsolete }"
              v-if="!isFindSpecificationPending"
              >{{ spec.Description1 }}
              <v-icon
                v-if="$can('update', spec)"
                small
                class="ml-5"
                color="primary"
                @click="clickEdit(spec)"
              >
                mdi-pencil </v-icon
              ><v-spacer></v-spacer>
              <v-menu>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="{ ...menu }"
                    class="ma-2"
                    :color="getStatus(spec.StatusId).Color"
                    :text-color="
                      applyDark(getStatus(spec.StatusId).Color)
                        ? 'white'
                        : 'black'
                    "
                    v-if="!spec.Obsolete && getStatus(spec.StatusId)"
                    x-small
                  >
                    {{ getStatus(spec.StatusId).Status }}
                  </v-chip>
                </template>
                <v-list dense style="max-height: 500px" class="overflow-y-auto">
                  <v-list-item
                    v-for="(stat, index) in status"
                    :key="stat.id"
                    :value="index"
                    @click="assignStatus(spec, stat.id)"
                  >
                    <v-list-item-title>
                      <v-icon small class="mr-2" :color="stat.Color">
                        mdi-circle </v-icon
                      >{{ stat.Status }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-subtitle v-if="spec.specification_type && spec.priority"
              >{{ spec.specification_type.Code
              }}{{ spec.Number.toString().padStart(4, '0') }}
              <span class="caption">({{ spec.priority.Name }})</span>
              <v-icon
                v-if="
                  spec &&
                  spec.responses &&
                  spec.responses.length > 0 &&
                  $can('read', 'response')
                "
                small
                class="ml-2"
                @click="clickResponses(spec)"
                color="primary"
              >
                mdi-format-list-checks
              </v-icon></v-card-subtitle
            >

            <v-card-text
              class="ma-0 pa-0"
              v-if="spec.Description2 || spec.Description3 || spec.Description4"
            >
              <v-list dense>
                <v-list-item v-if="spec.Description2">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      spec.specification_type.Description2
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      ><span
                        v-html="spec.Description2"
                        class="text-body-2 ma-0 pa-0 ql-editor"
                      ></span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="spec.Description3">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      spec.specification_type.Description3
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      ><span
                        v-html="spec.Description3"
                        class="text-body-2 ma-0 pa-0 ql-editor"
                      ></span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="spec.Description4">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      spec.specification_type.Description4
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      ><span
                        v-html="spec.Description4"
                        class="text-body-2 ma-0 pa-0 ql-editor"
                      ></span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-subtitle class="mb-6 py-0"
              >Variants
              <span v-if="spec.variants">({{ spec.variants.length }})</span
              ><v-btn icon @click="toggle(i)">
                <v-icon>{{
                  show[i] ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn></v-card-subtitle
            >
            <v-expand-transition>
              <div v-show="show[i] && spec.variants">
                <v-divider class="mx-4"></v-divider>

                <v-card-text class="pa-0 ma-0">
                  <v-list dense
                    ><v-list-item
                      v-for="variant in spec.variants"
                      :key="variant.id"
                      ><v-list-item-content
                        ><v-list-item-title v-if="variant.process">
                          <v-icon
                            v-if="$can('delete', 'specification-variant')"
                            color="primary"
                            small
                            class="mr-2"
                            @click="removeVariant(variant, spec)"
                          >
                            mdi-link-off
                          </v-icon>

                          {{ $appConfig.customer.prefix
                          }}{{
                            variant.process.Number.toString().padStart(4, '0')
                          }}.{{ variant.Number.toString().padStart(3, '0') }}
                          <router-link
                            :to="{
                              name: 'processDetail',
                              params: {
                                id: variant.process.id,
                                number: variant.process.Number,
                              },
                            }"
                            >{{ variant.process.Name }}</router-link
                          >
                          >
                          <router-link
                            :to="{
                              name: 'processStep',
                              params: { id: variant.id },
                            }"
                            ><b>{{ variant.Name }}</b></router-link
                          ></v-list-item-title
                        >
                        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                        <v-list-item-subtitle
                          v-html="variant.Input"
                          class="ql-editor ma-0 pa-0"
                        >
                        </v-list-item-subtitle></v-list-item-content
                    ></v-list-item>
                  </v-list>
                  <v-btn
                    v-if="$can('create', 'specification-variant')"
                    class="mx-2 mb-4"
                    fab
                    elevation="0"
                    x-small
                    color="primary"
                    @click="clickAddVariant(spec)"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card></v-col
        ></v-row
      >
    </div>
    <SpecificationEdit
      v-if="detailDialog && specificationType"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variant"
      :type="specificationType"
    />
    <SpecificationAddVariant
      v-if="currentSpec"
      v-model="addVariantDialog"
      :specificationId="currentSpec.id"
    ></SpecificationAddVariant>
    <VariantAddSpecification
      v-if="variant"
      v-model="addSpecificationDialog"
      :variantId="variant.id"
    >
    </VariantAddSpecification>
    <Response v-model="showResponse" :item="currentItem" />
  </section>
</template>

<script>
import SpecificationEdit from '@/components/Specification/SpecificationEdit';
import SpecificationAddVariant from '@/components/Specification/SpecificationAddVariant';
import VariantAddSpecification from '@/components/Specification/VariantAddSpecification';
import Response from '@/components/Specification/SpecificationResponses';
import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
import {
  handleErrorResponse,
  handleSaveResponse,
} from '@/utils/MessageHandler';
import feathersClient from '@/feathers-client';

export default {
  name: 'SpecificationList',
  props: ['specificationType', 'variant'],
  components: {
    SpecificationEdit,
    SpecificationAddVariant,
    VariantAddSpecification,
    Response,
  },
  mixins: [
    makeFindMixin({
      service: 'specification',
    }),
    makeFindMixin({
      service: 'status',
    }),
  ],
  data: () => ({
    show: [],
    detailDialog: false,
    addVariantDialog: false,
    addSpecificationDialog: false,
    currentItem: null,
    showResponse: false,
    currentSpec: null,
    selectedTab: null,
    search: null,
  }),
  methods: {
    applyDark,
    handleErrorResponse,
    handleSaveResponse,
    async assignStatus(item, id) {
      const { Specification } = this.$FeathersVuex.api;
      // console.log(item, id);

      try {
        await feathersClient.service('specification').patch(item.id, {
          StatusId: id,
        });
      } catch (error) {
        handleErrorResponse(error);
      }
      // handleSaveResponse('', 'Test Scenario', 'assigned');
      await Specification.find({ query: { id: item.id } });
    },
    getStatus(id) {
      let [s] = this.status.filter((f) => f.id == id);
      s;
      return s;
    },
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    clickAddVariant(spec) {
      this.currentSpec = spec; //{};
      this.addVariantDialog = true;
    },
    clickAddSpecification() {
      this.addSpecificationDialog = true;
    },
    clickResponses(item) {
      this.currentItem = item;
      this.showResponse = true;
    },
    async removeVariant(variant, spec) {
      const { SpecificationVariant, Specification } = this.$FeathersVuex.api;
      let result = await SpecificationVariant.find({
        query: {
          VariantId: variant.id,
          SpecificationId: spec.id,
        },
      });
      if (result.data) {
        await this.$store.dispatch(
          'specification-variant/remove',
          result.data[0].id
        );
        SpecificationVariant.find({ query: {} });
        Specification.find({ query: {} });
      }
    },
  },
  computed: {
    specificationParams() {
      return {
        query: {
          SpecificationTypeId: this.specificationType.id
            ? this.specificationType.id
            : this.specificationType,
        },
      };
    },
    statusParams() {
      return {
        query: {
          Active: true,
          $sort: {
            Number: 1,
          },
        },
      };
    },
    filteredSpecifications() {
      let filteredSpecs = this.specification;
      if (this.variant) {
        filteredSpecs = this.specification.filter((item) =>
          item.variants
            ? item.variants.some((variant) => variant.id == this.variant.id)
            : null
        );
      }
      if (this.search)
        return this.specification.filter(
          (item) =>
            item.Description1.toLowerCase().includes(
              this.search.toLowerCase()
            ) ||
            (item.Description2 != null
              ? item.Description2.toLowerCase().includes(
                  this.search.toLowerCase()
                )
              : false) ||
            (item.Description3 != null
              ? item.Description3.toLowerCase().includes(
                  this.search.toLowerCase()
                )
              : false) ||
            (item.Description4 != null
              ? item.Description4.toLowerCase().includes(
                  this.search.toLowerCase()
                )
              : false)
        );
      else return filteredSpecs;
    },
  },
};
</script>

<style lang="scss" scoped>
.obsolete {
  text-decoration: line-through;
}
.pre {
  white-space: pre-line;
  word-wrap: break-word;
  font-family: inherit;
}
</style>
