<template>
  <v-container
    ><FeathersVuexFind
      v-slot="{ items: priority }"
      service="priority"
      :params="{}"
      watch="params"
    >
      <section>
        <v-data-table
          :headers="filteredHeaders"
          :items="priority"
          :items-per-page="100"
          :sort-by="['Weight']"
          :sort-desc="[true]"
          item-key="id"
          class="elevation-1"
          hide-default-footer
        >
          <template v-if="$can('create', 'priority')" v-slot:footer>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    class="mx-2"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <v-form ref="form" v-model="validInput">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add Priority</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.Name"
                              label="Name"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Weight"
                              label="Weight"
                              :rules="[rules.number]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.Divisor"
                              label="Divisor"
                              :rules="[rules.number]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="Close()">Cancel</v-btn>
                      <v-btn
                        :disabled="!validInput"
                        color="primary"
                        @click="Add()"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card></v-form
                >
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-if="$can('update', 'priority')"
            v-slot:[`item.Name`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Name"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Name }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Name"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'priority')"
            v-slot:[`item.Weight`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Weight"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Weight }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Weight"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template
            v-if="$can('update', 'priority')"
            v-slot:[`item.Divisor`]="props"
          >
            <v-edit-dialog
              :return-value.sync="props.item.Divisor"
              @save="save(props.item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
            >
              {{ props.item.Divisor }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Divisor"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template
            v-if="$can('delete', 'priority')"
            v-slot:[`item.actions`]="{ item }"
          >
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </section></FeathersVuexFind
    ></v-container
  >
</template>

<script>
import feathersClient from '../../feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      validInput: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'Name',
        },
        {
          text: 'Weight',
          align: 'start',
          sortable: true,
          value: 'Weight',
        },
        {
          text: 'Divisor',
          align: 'start',
          sortable: true,
          value: 'Divisor',
        },
        { text: 'Delete', value: 'actions', sortable: false },
      ],
      dialog: false,
      editedItem: {
        Name: '',
        Weight: 0,
        Divisor: 0,
      },
      defaultItem: {
        Name: '',
        Weight: 0,
        Divisor: 0,
      },
    };
  },
  computed: {
    filteredHeaders() {
      return this.headers.filter((item) => {
        return this.$can('delete', 'priority') ? true : item.text != 'Delete';
      });
    },
  },
  methods: {
    save(item) {
      item
        .save()
        .then((saved) => handleSaveResponse(saved.Name, 'Priority', 'updated'))
        .catch((error) => handleErrorResponse(error));
    },
    open() {},
    cancel() {},
    close() {},
    Add() {
      feathersClient
        .service('priority')
        .create(this.editedItem)
        .then((saved) => handleSaveResponse(saved.Name, 'Priority', 'added'))
        .catch((error) => handleErrorResponse(error));

      this.Close();
    },
    Close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    deleteItem(item) {
      item
        .remove()
        .then((saved) => handleSaveResponse(saved.Name, 'Priority', 'removed'))
        .catch((error) => handleErrorResponse(error));
    },
  },
};
</script>

<style></style>
