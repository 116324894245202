<template>
  <div>
    <div v-if="$feature('planner')">
      <ejs-splitter id="default-splitter">
        <e-panes>
          <e-pane content="#planList" size="400px"></e-pane>
          <e-pane content="#schedule"></e-pane>
        </e-panes>
      </ejs-splitter>
    </div>
    <div v-else>No acces to planner.</div>

    <!-- pane contents -->
    <div id="planList">
      <ToPlanList :key="refreshKey" />
    </div>
    <div class="sticky" id="schedule">
      <Calendar @eventCreated="refresh" />
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/Planner/Calendar.vue';
import ToPlanList from '@/components/Planner/ToPlanList.vue';
import {
  SplitterComponent,
  PanesDirective,
  PaneDirective,
} from '@syncfusion/ej2-vue-layouts';

export default {
  components: {
    Calendar,
    ToPlanList,
    'ejs-splitter': SplitterComponent,
    'e-pane': PaneDirective,
    'e-panes': PanesDirective,
  },
  data() {
    return {
      show: true,
      refreshKey: 0,
      content1: function () {
        return { template: `<ToPlanList :key="refreshKey" />` };
      },
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async refresh(payload) {
      this.refreshKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: fixed;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */

  border-left: 1px solid #ccc; /* Optional styling for a border */
}
</style>
