<template>
  <v-container>
    <RequirementList
      v-model="show"
      :variant="variant"
      :requirementTypeId="type"
    />
  </v-container>
</template>

<script>
import RequirementList from '@/components/Requirement/RequirementList';
import { makeGetMixin } from 'feathers-vuex';

export default {
  components: { RequirementList },
  data() {
    return {
      show: true,
    };
  },
  watch: {
    '$route.params.id': {
      handler: function() {},
      deep: true,
      immediate: true,
    },
    '$route.params.type': {
      handler: function() {},
      deep: true,
      immediate: true,
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    type() {
      return this.$route.params.type;
    },
  },
  mixins: [
    makeGetMixin({
      service: 'variant',
      id: 'id',
      watch: 'id',
    }),
  ],
};
</script>

<style lang="scss" scoped></style>
