<template>
  <span>
    <span v-if="countMinor > 0">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="ma-2" x-small v-bind="attrs" v-on="on" outlined>
            {{ countMinor }}
          </v-chip>
        </template>
        Minor/Major
      </v-tooltip>
    </span>
    <span v-if="countMajorBlocking > 0">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="ma-2"
            color="red"
            x-small
            v-bind="attrs"
            v-on="on"
            outlined
          >
            {{ countMajorBlocking }}
          </v-chip>
        </template>
        Blocking
      </v-tooltip>
    </span>
  </span>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ['list'],

  computed: {
    countMinor() {
      let result = 0;
      if (this.list) {
        result = this.list.filter((item) => {
          // console.log('i', item);
          if (item.fields.customfield_10033 === null) {
            item.fields.customfield_10033 = {};
            item.fields.customfield_10033.value = 'Minor';
          }
          return (
            item.fields.customfield_10033.value == 'Major' ||
            item.fields.customfield_10033.value == 'Minor'
          );
        }).length;
      }
      return result;
    },
    countMajorBlocking() {
      let result = 0;
      if (this.list) {
        result = this.list.filter((item) => {
          if (item.fields.customfield_10033 === null) {
            item.fields.customfield_10033 = {};
            item.fields.customfield_10033.value = 'Minor';
          }
          return item.fields.customfield_10033.value == 'Blocking';
        }).length;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
