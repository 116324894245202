<template>
  <v-card width="100vw" flat>
    <IssueList :statusActive="status" :phase="phase.toString()" />
  </v-card>
</template>

<script>
import IssueList from '@/components/Issues/IssueList.vue';
export default {
  props: ['status', 'phase'],
  components: { IssueList },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
